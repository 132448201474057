// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toaster-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 20px;
  display: flex;
  align-items: center;
  z-index: 20;
}
.toaster-wrapper .toast-item {
  border-radius: 4px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  animation: fade-in 200ms linear 1;
  color: #FFFFFF;
}
.toaster-wrapper .toast-item.info {
  background-color: #0099A8;
}
.toaster-wrapper .toast-item.fade {
  animation: fade-out 200ms linear 1;
}
.toaster-wrapper .toast-item a {
  color: #FFFFFF;
  opacity: 0.9;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
::ng-deep .dashboard-toast {
  font-size: 14px;
}
::ng-deep .dashboard-toast li {
  font-size: 12px;
  list-style: disc;
  margin-left: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
