import { Component, OnInit  } from '@angular/core';
import { Router             } from '@angular/router';
import { switchMap, tap     } from 'rxjs';

import { SessionStorageService, AppAccessService, UserService, SidebarStorageService } from '@shared/services';

import { environment } from 'environments/environment';

interface WorkPlaceModel {
  identifier: string;
  title:      string;
  subtitle?:  string;

  redirect?:  string;
  home?:      boolean;
  disabled?:  boolean;
  show?:      boolean;
  skip?:      boolean;
  new?:       boolean;
}

@Component({
  selector:      'application-switcher',
  templateUrl: './application-switcher.component.html',
  host: { class: 'am-flex-align gap-xxl bb-grey' }
})
export class ApplicationSwitcherComponent implements OnInit {
  temptonConnect: boolean;
  apps:           WorkPlaceModel[];
  constructor(
    private router:                Router,
    private sessionStorageService: SessionStorageService,
    private sidebarStorageService: SidebarStorageService,
    private userService:           UserService,
    private appAccessService:      AppAccessService
  ) { }

  ngOnInit(): void {
    this.userService.currentUser.pipe(
      switchMap(user => this.userService.isAuthorizedForTemptonConnect().pipe(
        tap((isAuthorized: boolean) => {
          this.temptonConnect = isAuthorized;
          this.showTemptonConnectHint(isAuthorized);
        })
      ))
    ).subscribe(() => {
      if (!this.apps)  this.loadApps();
      else this.apps = this.allowedApps();
    });
  }

  private loadApps(): void {
    this.sessionStorageService.pills.subscribe(pills => this.apps = this.allowedApps());
  }

  allowedApps(): WorkPlaceModel[] {
    if (this.userService.isCustomer) return this.allowedAppsForCustomer();
    if (this.userService.isInternal) return this.allowedAppsForInternal();
  }

  private allowedAppsForCustomer(): WorkPlaceModel[] {
    return this.customerApps.filter(a => !a.skip).filter(a => a.show !== false).map(a => {
      if (!a.redirect) a.disabled = !this.appAccessService.authorizedForRead(a.identifier);
      return a;
    }).filter(a => !a.disabled);
  }

  private allowedAppsForInternal(): WorkPlaceModel[] {
    return this.internalApps.filter(a => !a.skip);
    // .filter(a => this.appAccessService.authorizedForInternal(a.identifier));
  }

  appIsActive(app: WorkPlaceModel): boolean {
    if (this.userService.isCustomer) return this.activeAppByCustomer(app);
    if (this.userService.isInternal) return this.activeAppByInternal(app);
  }

  private activeAppByCustomer(app: WorkPlaceModel): boolean {
    return this.router.url.includes(`/${app.identifier}/`);
  }

  private activeAppByInternal(app: WorkPlaceModel): boolean {
    return app.title === 'Einsatz';
  }

  openApplication(app: WorkPlaceModel): void {
    if (app.home) return;
    else if (app.redirect) window.open(app.redirect,'_blank');
    else if (!this.router.url.includes(`/${app.identifier}/`)) {
      this.router.navigateByUrl(`/${app.identifier}/list`);
      this.sessionStorageService.changeActiveApp(app.identifier);
      this.sessionStorageService.changeAndResetActiveTab('dashboard');
    }
  }

  openHomePage(): void {
    this.sessionStorageService.changeAndResetActiveTab('dashboard');
    let navItem = this.sidebarStorageService.sidebarItems.find(item => item.identifier === 'dashboard');
    this.router.navigateByUrl(`${this.sessionStorageService.activeAppValue}/${navItem.path}`);
  }

  toggleSidebar(): void {
    if ($('#portal-sidebar').hasClass('mini-sidebar-xs')) $('#portal-sidebar').toggleClass('mini-sidebar-xs');
    else $('#portal-sidebar').toggleClass('mini-sidebar');
    this.sidebarStorageService.toggleSidebarState();
  }

  private showTemptonConnectHint(isAuthorized: boolean): void {
    if (this.userService.isCustomer && isAuthorized) {
      this.sessionStorageService.showTutorial('tempton-connect');
    }
  }

  get customerApps(): WorkPlaceModel[] {
    return [
      {
        identifier: 'time-tracking',
        title: 'Zeiterfassung',
        subtitle: '',
      },
      {
        identifier: 'invoices',
        title: 'Rechnungen',
        subtitle: '',
      },
      {
        identifier: 'tempton-connect',
        show: this.temptonConnect,
        title: 'Personal',
        subtitle: '',
        redirect: environment.temptonConnectUrl,
        new: true
      }
    ];
  }

  get internalApps(): WorkPlaceModel[] {
    return [
      {
        identifier: 'clerk',
        title: 'Bewerber',
        subtitle: 'Tempton Jet',
        redirect: 'https://jet.tempton-connect.de/'
      },
      {
        identifier: 'dispatcher',
        title: 'Vertrieb',
        subtitle: 'Tempton Connect',
        skip: !this.temptonConnect,
        redirect: environment.temptonConnectUrl
      },
      {
        identifier: 'clerk',
        title: 'Einsatz',
        subtitle: 'SB-Verwaltung',
        home: true
      },
      {
        identifier: 'clerk',
        title: 'Kontrolle',
        subtitle: 'Dashboard',
        redirect: 'https://dashboard.tempton-connect.de/'
      }
    ];
  }

}
