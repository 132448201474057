import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastModel } from '@shared/models';
import { ToasterService } from 'app/services/toaster.service';
import { Toast } from 'app/factories/toast.factory';

@Component({
  selector: 'toaster',
  templateUrl: 'toast.component.html',
  styleUrls: ['./toast.component.sass'],
})
export class ToastComponent implements OnInit, OnDestroy {
  toasts: Toast[] = [];
  toastSubscription: Subscription;
  routeSubscription: Subscription;
  timeouts: any = {};
  constructor(
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.toastSubscription = this.toasterService.onNotify().subscribe((toast: Toast) => {
      if (this.toasts.length || this.toasts.find(t => t.name === toast.name)) {
        if (this.toasts[this.toasts.length - 1].name !== toast.name) {
          this.toasts = this.toasts.filter(t => t.name === toast.name);
          this.toasts.push(toast)
        }
      } else this.toasts.push(toast);
      for (const toast in this.timeouts) {
        clearTimeout(this.timeouts[toast]);
        delete this.timeouts[toast];
      }
      this.deleteLastToast();
    });
  }

  ngOnDestroy() {
    this.toastSubscription.unsubscribe();
  }

  deleteLastToast(): void {
    if (this.toasts.length) {
      let lastToast = this.toasts[this.toasts.length - 1];
      if (lastToast.autoClose) {
        this.timeouts[lastToast.name] = setTimeout(() => {
          this.removeToast(this.toasts.find(t => t.name === lastToast.name))
          delete this.timeouts[lastToast.name];
        }, 5000);
      }
    }
  }

  removeToast(toast: Toast) {
    if (!this.toasts.includes(toast)) return;
    toast.fade = true;
    setTimeout(() => {
      this.toasts = this.toasts.filter(x => x !== toast);
      this.toasts = [];
    }, 200);
  }

}
