import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject     } from 'rxjs';
import { map, repeat, switchMap, tap } from 'rxjs/operators';

import { EBSCounterResponseModel } from '@shared/models';
import { SidebarStorageService   } from '../sidebar-storage.service';
import { QueryCollectorService   } from '../query-collector.service';
import { CountersService         } from './counters.service';

import { environment } from 'environments/environment';

@Injectable()
export class AssignmentsCountersService {
  private get TN_ASSIGNMENTS_COUNTERS_API(): string { return `${environment.apiUrl}api/portal/v3/assignments_counters` };

  private reloadAssignmentsCounter$        = new Subject<void>();
  private reloadAssignmentsExpiredCounter$ = new Subject<void>();
  constructor (
    private http:                  HttpClient,
    private sidebarStorageService: SidebarStorageService,
    private countersService:       CountersService,
    private queryCollectorService: QueryCollectorService
  ) { }

  reloadEBSCounter(): void {
    this.reloadAssignmentsCounter$.next();
  }

  get EBSCounter(): Observable<any> {
    return this.requestEBSCouter().pipe(
      repeat({ delay: () => this.reloadAssignmentsCounter$ })
    );
  }

  private requestEBSCouter(): Observable<EBSCounterResponseModel> {
    return of(this.TN_ASSIGNMENTS_COUNTERS_API).pipe(
      map(url => url + this.queryCollectorService.getEBSCountersQuery()),
      switchMap(url => this.http.get<any>(url)),
      tap(({ unconfirmed, confirmed }) => {
        this.sidebarStorageService.changeUnconfirmedEBSCount(unconfirmed.length);
        this.countersService.checkForUpdates({ unconfirmed, confirmed }, 'EBS');
      })
    );
  }

  reloadAssignmentsExpiredCounter(): void {
    this.reloadAssignmentsExpiredCounter$.next();
  }

  get assignmentsCounters(): Observable<any> {
    return this.requestAssignmentsExpiredCounter().pipe(
      repeat({ delay: () => this.reloadAssignmentsExpiredCounter$ })
    );
  }

  private requestAssignmentsExpiredCounter(): Observable<any> {
    return of(`${this.TN_ASSIGNMENTS_COUNTERS_API}/expire_soon`).pipe(
      map(url => url + this.queryCollectorService.getCountersQuery()),
      switchMap(url => this.http.get<any>(url)),
      tap(({ expire_soon }) => {
        this.sidebarStorageService.changeExpiringSoonAssignmentsCount(expire_soon.length);
        this.countersService.checkForUpdates({ expire_soon }, 'assignments');
      })
    );
  }

}
