// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex: 1 0 auto;
  min-width: 450px;
}
@media (max-width: 450px) {
  :host {
    min-width: unset;
  }
}

.header-avatar {
  flex: 0 0 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url("/assets/images/default_avatar.png");
  background-size: cover;
  background-position: center center;
  filter: grayscale(1);
}
@media (max-width: 768px) {
  .header-avatar {
    flex: 0 0 60px;
    height: 60px;
  }
}

h2 {
  line-height: 1;
}
@media (max-width: 768px) {
  h2 {
    font-size: 14px;
  }
}

.status-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.status-dot.green {
  background-color: #95C11F;
}
.status-dot.red {
  background-color: #DE440B;
}
.status-dot.grey {
  background-color: #7D8B92;
}
.status-dot.blue {
  background-color: #0099A8;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
