import { Component, Input, OnChanges, SimpleChanges      } from '@angular/core';
import { ActivityReportExtended, VacationRequestExtended } from '@shared/factories';
import { InfoStateModel             } from '@shared/models';
import { InfoStatesCollectorService } from '@shared/services';

@Component({
  selector:      'info-states',
  templateUrl: './info-states.component.html',
  host: { class: 'am-flex-column text-m', '[class.display-none]': '!infoStates.length' }
})
export class InfoStatesComponent implements OnChanges {
  @Input() entry:      ActivityReportExtended | VacationRequestExtended;
  @Input() isInternal: boolean;

  infoStates: InfoStateModel[] = [];
  constructor( private infoStatesCollectorService: InfoStatesCollectorService ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entry?.firstChange || changes?.entry?.currentValue?.id !== changes?.entry?.previousValue?.id) this.collectStates();
  }

  private collectStates(): void {
    this.infoStates = [];
    switch (this.entry.constructor) {
      case ActivityReportExtended:
        this.infoStates = this.infoStatesCollectorService.collectActivityReportStates(this.entry as ActivityReportExtended, this.isInternal);
        break;
      case VacationRequestExtended:
        this.infoStates = this.infoStatesCollectorService.collectVacationRequestStates(this.entry as VacationRequestExtended, this.isInternal);
        break;
    }
  }

}
