import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector:     'comment-box',
  templateUrl:  'comment-box.component.html',
  styleUrls: ['./comment-box.component.sass'],
  host: {'class': 'am-flex-column gap15'}
})
export class CommentBoxComponent {
  @Input() value:       string;
  @Input() buttonClass: string;
  @Input() readOnly:    boolean;
  @Input() editMode:    boolean;
  @Input() hideButtons: boolean;

  @Output() saveCallback    = new EventEmitter<any>();
  @Output() updateTempValue = new EventEmitter<any>();

  boxValue: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value && (changes.value.firstChange || changes.value.currentValue !== changes.value.previousValue)) {
      this.boxValue = changes.value.currentValue;
      this.editMode = null;
    }
  }

  saveComment(): void {
    if (this.boxValue !== this.value) this.saveCallback.emit(this.boxValue);
    else this.closeEditMode();
  }

  updateValue(e): void {
    this.updateTempValue.emit(e.target.value);
  }

  closeEditMode(): void {
    this.editMode = false;
    this.boxValue = this.value;
  }

  enableEditMode(): void {
    if (!this.hideButtons) this.editMode = true;
    let textarea  = document.getElementById('internal-comment');
    if (textarea) setTimeout(() => textarea.focus());
  }

}
