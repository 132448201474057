import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'history-box-item',
  templateUrl: 'history-box-item.component.html',
  styleUrls: ['./history-box-item.component.sass'],
})
export class HistoryBoxItemComponent {
  @Input() entry:       any;
  @Input() simplified:  boolean;
  @Input() photoTn:     boolean;
  @Input() boxOpen:     boolean;
  @Input() showToggler: boolean;
  @Input() classList:   string;
  @Output() toggleBox = new EventEmitter<any>();
  @Output() callback  = new EventEmitter<any>();
  constructor() { }

  toggleBoxHandler(e): void {
    this.toggleBox.emit(e);
  }

  callbackHandler(): void {
    this.callback.emit();
  }
}
