import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'; 
import { LoggingService } from './logging.service';

@Injectable() export class NotificationService {
  private subject = new Subject<any>();
  constructor(
    private loggingService: LoggingService
  ) { }

  alert(message, noFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-warning",
      title: 'Warnung',
      desc: message,
      buttons: {
        no: 'OK'
      },
      noFn:() => {
        this.subject.next(null);
        noFn();
      }
    });
    this.loggingService.logAlert(message);
  }

  optionalAlert(message: string, checkboxText, checkboxFn:()=>void, noFn:()=>void=()=>{}) {
    this.subject.next({
      icon: "icon-warning",
      title: 'Warnung',
      desc: message,
      checkboxDesc: checkboxText,
      buttons: {
        no: 'Zurück'
      },
      checkboxFn: () => {
        checkboxFn();
      },
      noFn:() => {
        this.subject.next(null);
        noFn();
      }
    });
  }

  alertCustomWording({ title = '', message, buttonNo = '' }, noFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-warning",
      title: title || 'Warnung',
      desc: message,
      buttons: {
        no: buttonNo || 'OK'
      },
      noFn:() => {
        this.subject.next(null);
        noFn();
      }
    });
  }

  alertBlocker({ title, message }): void {
    this.subject.next({
      icon: "icon-warning",
      title: title || 'Warnung',
      desc: message
    });
  }

  success(message: string, siFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-circle-tick",
      title: 'Erfolg',
      desc: message,
      buttons: {
        yes: 'Weiter'
      },
      siFn:() => {
        this.subject.next(null);
        siFn();
      }
    });
  }

  rejection(message: string, noFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-circle-reject",
      title: 'Erfolg',
      desc: message,
      buttons: {
        no: 'Zurück'
      },
      noFn:() => {
        this.subject.next(null);
        noFn();
      }
    });
  }

  info(title: string, message: string, siFn:()=>void = ()=>{}, noFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-circle-question",
      title: title,
      desc: message,
      buttons: {
        no: 'Zurück'
      },
      siFn: () => {
        this.subject.next(null);
        siFn();
      },
      noFn:() => {
        this.subject.next(null);
        noFn();
      }
    });
  }

  note(message: string, siFn:()=>void = ()=>{}, noFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-comment",
      title: 'Notiz',
      desc: message,
      buttons: {
        yes: 'Ja',
        no: 'Abbrechen'
      },
      siFn: () => {
        this.subject.next(null);
        siFn();
      },
      noFn:() => {
        this.subject.next(null);
        noFn();
      }
    });
  }

  optionalNote(message: string, checkboxText, checkboxFn:()=>void, siFn:()=>void, noFn:()=>void = ()=>{}) {
    this.subject.next({
      icon: "icon-comment",
      title: 'Notiz',
      desc: message,
      checkboxDesc: checkboxText,
      buttons: {
        yes: 'Ja',
        no: 'Abbrechen'
      },
      checkboxFn: () => {
        checkboxFn();
      },
      siFn: () => {
        this.subject.next(null);
        siFn();
      },
      noFn:() => {
        this.subject.next(null);
        noFn();
      }
    });
  }

  confirm(title: string, message: string, siFn:()=>void, noFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-circle-question",
      title: title,
      desc: message,
      buttons: {
        yes: 'Ja',
        no: 'Abbrechen'
      },
      siFn: () => {
        this.subject.next(null);
        siFn();
      },
      noFn:() => {
        this.subject.next(null);
        noFn();
      }
    });
  }

  prompt(title: string, desc: string, buttons: any, leftFn:()=>void=()=>{}, rightFn:()=>void=()=>{}, backFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-circle-question",
      title,
      desc,
      buttons: {
        left: buttons.left,
        right: buttons.right,
        back: buttons.back || (buttons.left === 'Abbrechen' || buttons.right === 'Abbrechen') ? '' : 'Abbrechen'
      },
      overflowClose: true,
      leftFn: () => {
        this.subject.next(null);
        leftFn();
      },
      rightFn:() => {
        this.subject.next(null);
        rightFn();
      },
      backFn:() => {
        this.subject.next(null);
        backFn();
      }
    });
  }

  optionalPrompt(title: string, desc: string, checkboxText: string, checkboxFn:()=>void=()=>{}, buttons: any, leftFn:()=>void=()=>{}, rightFn:()=>void=()=>{}, backFn:()=>void = ()=>{}): void {
    this.subject.next({
      icon: "icon-circle-question",
      title,
      desc,
      buttons: {
        left: buttons.left,
        right: buttons.right,
        back: buttons.back || (buttons.left === 'Abbrechen' || buttons.right === 'Abbrechen') ? '' : 'Abbrechen'
      },
      overflowClose: true,
      checkboxDesc: checkboxText,
      checkboxFn: () => {
        checkboxFn();
      },
      leftFn: () => {
        this.subject.next(null);
        leftFn();
      },
      rightFn:() => {
        this.subject.next(null);
        rightFn();
      },
      backFn:() => {
        this.subject.next(null);
        backFn();
      }
    });
  }

  wait(): void {
    this.subject.next({
      icon: "popup-wait-animation",
      title: 'Wird geladen . . .',
      desc: 'Bitte warten Sie einen Augenblick .'
    });
  }

  close(): void {
    this.subject.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}