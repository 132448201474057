import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService, ISignInParameters, ISignOutParameters } from '@tempton/ngx-msal';

export const ensureSignedIn =
  (signInParameters: ISignInParameters): CanActivateFn =>
  (next: ActivatedRouteSnapshot): Observable<boolean> => {
    const auth: AuthService = inject(AuthService);
    const login_hint: string = next.queryParams.login_hint as string;
    const domain_hint: string = next.queryParams.domain_hint as string;

    return auth.ensureSignedIn$({...signInParameters, loginHint: login_hint, domainHint: domain_hint});
  };

export const ensureSignedOut =
  (signOutParameters: ISignOutParameters): CanActivateFn =>
  (): Observable<boolean> => {
    return inject(AuthService).ensureSignedOut$(signOutParameters);
  };

export const isNotSignedIn =
  (signInParameters: ISignInParameters): CanActivateFn =>
  (): Observable<boolean> => {
    let router = inject(Router);
    return inject(AuthService).isSignedIn$({}).pipe(
      map(x => !x),
      tap(x => x ? true : router.navigateByUrl('/'))
    );
  };
