// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .table-body-wrapper .list-row {
  display: flex;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid #CED4DA;
  padding: 0 10px;
  font-size: 14px;
}
@media (max-width: 1280px) {
  ::ng-deep .table-body-wrapper .list-row {
    padding: 0;
  }
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell],
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] {
  margin: 0 5px;
  position: relative;
}
@media (min-width: 769px) {
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell]:not(.action-cell):not(.icons-cell):hover .web-tooltip,
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "]:not(.action-cell):not(.icons-cell):hover .web-tooltip {
    display: flex;
  }
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .xs-label,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .xs-label {
  display: none;
  align-items: center;
  min-width: 120px;
  width: 120px;
  padding-left: 10px;
  margin-right: 10px;
  background-color: #F0F0F0;
  border-right: 1px solid #CED4DA;
  font-size: 12px;
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .xs-icon,
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .xs-button,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .xs-icon,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .xs-button {
  display: none !important;
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .xs-button,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .xs-button {
  width: calc(100% - 140px);
  margin: 10px 0;
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .status-icon,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .status-icon {
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .status-icon.grey,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .status-icon.grey {
  background-color: #7D8B92;
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .status-icon.red,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .status-icon.red {
  background-color: #DE440B;
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .status-icon.orange,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .status-icon.orange {
  background-color: #FF9228;
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .status-icon.green,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .status-icon.green {
  background-color: #95C11F;
}
::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .status-icon.blue,
::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .status-icon.blue {
  background-color: #0099A8;
}
::ng-deep .table-body-wrapper .list-row .action-cell {
  display: flex;
}
::ng-deep .table-body-wrapper .list-row .action-cell .xs-label:not(.xs-label-icons) {
  justify-content: center;
}
::ng-deep .table-body-wrapper .list-row .action-cell .xs-label-icons {
  justify-content: space-evenly;
}
::ng-deep .table-body-wrapper .list-row .action-cell .xs-label-icons span[class^=icon-],
::ng-deep .table-body-wrapper .list-row .action-cell .xs-label-icons span[class*=" icon-"] {
  display: flex;
  font-size: 24px;
}
::ng-deep .table-body-wrapper .list-row .action-cell .buttons-group {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
::ng-deep .table-body-wrapper .list-row .action-cell .buttons-group .action-cell-button,
::ng-deep .table-body-wrapper .list-row .action-cell .buttons-group .custom-action-cell-button {
  flex: 1 0 75px;
}
::ng-deep .table-body-wrapper .list-row .action-cell .action-cell-button {
  width: 100%;
  background-color: #0099A8;
  color: #FFFFFF;
}
::ng-deep .table-body-wrapper .list-row .action-cell .action-cell-button.grey {
  background-color: #F0F0F0;
  color: #FFFFFF;
}
::ng-deep .table-body-wrapper .list-row .action-cell .icon-phone {
  display: flex;
  font-size: 20px;
  margin-right: 5px;
}
::ng-deep .table-body-wrapper .list-row:after {
  content: "";
  min-height: inherit;
  font-size: 0;
}
::ng-deep .table-body-wrapper .list-row.read-only {
  color: rgb(185, 185, 185);
}
::ng-deep .table-body-wrapper .icon-holiday {
  position: relative;
  color: #FF9228;
}
@media (max-width: 768px) {
  ::ng-deep .table-body-wrapper .xs-hide {
    display: none !important;
  }
  ::ng-deep .table-body-wrapper .list-row {
    min-height: 0;
    padding: 0;
    margin: 10px 0;
    flex-direction: column;
    border: 1px solid #CED4DA;
    border-radius: 4px;
  }
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell],
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "] {
    display: flex;
    flex-direction: row !important;
    flex: 0;
    width: 100%;
    margin: 0;
  }
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell]:not(.icons-cell):not(:last-child), ::ng-deep .table-body-wrapper .list-row div[class\$=-cell]:not(:last-child),
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "]:not(.icons-cell):not(:last-child),
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "]:not(:last-child) {
    border-bottom: 1px solid #CED4DA;
  }
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .xs-label,
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .xs-label {
    display: flex;
    min-height: 40px;
  }
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .xs-label + span,
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .xs-label + span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 130px);
    line-height: 20px;
    padding: 10px 0;
    word-break: break-all;
  }
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .xs-icon,
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell] .xs-button,
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .xs-icon,
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "] .xs-button {
    display: flex !important;
  }
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell].checkbox-cell,
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "].checkbox-cell {
    display: none;
  }
  ::ng-deep .table-body-wrapper .list-row div[class\$=-cell].action-cell.failed-erp,
  ::ng-deep .table-body-wrapper .list-row div[class*="-cell "].action-cell.failed-erp {
    flex: auto;
  }
  ::ng-deep .table-body-wrapper .list-row .buttons-group {
    margin: 10px 10px 10px 0;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
