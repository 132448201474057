import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject     } from 'rxjs';
import { map, repeat, switchMap, tap } from 'rxjs/operators';

import { SidebarStorageService } from '../sidebar-storage.service';
import { QueryCollectorService } from '../query-collector.service';
import { CountersService       } from './counters.service';

import { environment } from 'environments/environment';

@Injectable()
export class InvoicesCountersService {
  private get INVOICES_COUNTERS_API(): string { return `${environment.apiUrl}api/portal/v3/customer_invoices_counters`; };

  private reloadCounter$ = new Subject<void>();
  constructor (
    private http:                  HttpClient,
    private sidebarStorageService: SidebarStorageService,
    private countersService:       CountersService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  reloadCounters(): void {
    this.reloadCounter$.next();
  }

  get invoicesCounters(): Observable<any> {
    return this.requestInvoicesCounters().pipe(
      repeat({ delay: () => this.reloadCounter$ })
    );
  }

  private requestInvoicesCounters(): Observable<any> {
    return of(this.INVOICES_COUNTERS_API).pipe(
      map(url => url + this.queryCollectorService.getCountersQuery()),
      switchMap(url => this.http.get<any>(url)),
      tap(({ archived, active }) => {
        this.sidebarStorageService.changeArchivedInvoicesCount(archived.length);
        this.sidebarStorageService.changeActiveInvoicesCount(active.length);

        this.countersService.checkForUpdates({ archived, active }, 'invoices');
      })
    );
  }

}
