// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggler-box.small {
  height: 20px;
  width: 50px;
  border-radius: 20px;
}
.toggler-box.small .toggler {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin: 2px;
  transition: transform linear 200ms;
}
.toggler-box.small .toggler.right {
  transform: translate(28px, 0px);
}
@media (max-width: 450px) {
  .toggler-box.small {
    height: 15px;
    width: 37.5px;
    border-radius: 15px;
  }
  .toggler-box.small .toggler {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    margin: 2px;
    transition: transform linear 200ms;
  }
  .toggler-box.small .toggler.right {
    transform: translate(20.5px, 0px);
  }
}
.toggler-box.big {
  height: 36px;
  width: 90px;
  border-radius: 36px;
}
.toggler-box.big .toggler {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 3px;
  transition: transform linear 200ms;
}
.toggler-box.big .toggler.right {
  transform: translate(51px, 0px);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
