import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject     } from 'rxjs';
import { map, repeat, switchMap, tap } from 'rxjs/operators';

import { SidebarStorageService, QueryCollectorService, CountersService } from '@shared/services';
import { MileageMoneyCountersModel } from '@shared/models';

import { environment } from 'environments/environment';

@Injectable()
export class MileageMoneyCountersService {
  private get MM_COUNTERS_API(): string { return `${environment.apiUrl}api/portal/v3/standalone_mileage_report_counters` };

  private reloadCounter$ = new Subject<void>();
  constructor (
    private http:                  HttpClient,
    private sidebarStorageService: SidebarStorageService,
    private countersService:       CountersService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  reloadCounters(): void {
    this.reloadCounter$.next();
  }

  get MileageMoneyCounters(): Observable<MileageMoneyCountersModel> {
    return this.requestMileageMoneyCounters().pipe(
      repeat({ delay: () => this.reloadCounter$ })
    );
  }

  private requestMileageMoneyCounters(): Observable<MileageMoneyCountersModel> {
    return of(this.MM_COUNTERS_API).pipe(
      map(url => url + this.queryCollectorService.getCountersQuery()),
      switchMap(url => this.http.get<MileageMoneyCountersModel>(url)),
      tap(({ open }) => {
        this.sidebarStorageService.changeAwaitingMileageMoneyReportsCount(open.length);
        this.countersService.checkForUpdates({ open }, 'mileageMoneyReports');
      })
    );
  }

}
