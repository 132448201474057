import { Component,ViewContainerRef, OnInit, NgZone, ComponentRef, ViewChildren, QueryList } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { ExportCustomerPopup, TutorialPopupComponent, SelectListPopup, ExternalsSelectListComponent, BasicSelectListComponent } from '@shared/components/dynamic';
import { SessionStorageService } from '@shared/services';

interface LoaderData {
  component: string;
  type:      string;
  props:     any;
}

@Component({
  selector:  'app-component-loader',
  templateUrl: './component-loader.component.html'
})
export class ComponentLoaderComponent implements OnInit {
  @ViewChildren('dynamic', { read: ViewContainerRef }) dynamic: QueryList<ViewContainerRef>;
  subscriptions:   SubscriptionLike[]  = [];
  componentsArray: string[]            = [];
  compRefArray:    ComponentRef<any>[] = [];
  constructor(
    private zone:                  NgZone,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.sessionStorageService.dynamicComponent.subscribe(data => {
      if (data) {
        if (data.type === 'create') this.addComponent(data);
        if (data.type === 'remove') this.removeComponent(data);
        if (data.type === 'update') this.updateComponent(data);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private addComponent(data: LoaderData): void {
    this.componentsArray.push(data.component);
    setTimeout(() => {
      let compRef = this.dynamic.get(this.dynamic.length-1).createComponent(this.getComponent(data.component));
      compRef.location.nativeElement.style.zIndex = this.componentsArray.length+1;
      this.setProps(compRef, data.props);
      this.compRefArray.push(compRef);
    });
  }

  private removeComponent(data: LoaderData): void {
    let el = this.componentsArray.findIndex(c => c === data.component);
    if (el !== -1) {
      this.dynamic.get(el).clear();
      this.componentsArray.splice(el, 1);
      this.compRefArray.splice(el, 1);
    }
  }

  private updateComponent(data: LoaderData): void {
    let el = this.componentsArray.findIndex(c => c === data.component);
    if (el !== -1) {
      let compRef = this.compRefArray[el];
      this.setProps(compRef, data.props);
    }
  }

  private setProps(compRef: ComponentRef<any>, props: any): void {
    if (props) {
      this.zone.run(() => {
        Object.keys(props).forEach(key => {
          if (props[key] instanceof Function) compRef.instance[key].subscribe(props[key]);
          else compRef.setInput(key, props[key]);
        });
      });
    }
  }

  private getComponent(comp): any {
    switch (comp) {
      case 'ExportCustomerPopup':
        return ExportCustomerPopup;
      case 'Tutorial':
        return TutorialPopupComponent;
      case 'SelectListPopup':
        return SelectListPopup;
      case 'BasicSelectList':
        return BasicSelectListComponent;        
      case 'ExternalsSelectList':
        return ExternalsSelectListComponent;
      default:
        break;
    };
  }

}
