import { ReleaseModel } from "@shared/models";

export class Release {
  id:          number;
  title:       string;
  version:     string;
  application: string;
  notes:       string[];

  created_at:  Date;
  released_at: Date;
  constructor(data: ReleaseModel) {
    this.id          = data.id;
    this.title       = data.title;
    this.version     = data.version;
    this.application = data.application;
    this.notes       = this.parceNotes(data.notes);
    this.created_at  = this.parceDate(data.created_at);
    this.released_at = this.parceDate(data.released_at);
  }

  toJSON(): ReleaseModel {
    return ({
      id:          this.id,
      title:       this.title,
      version:     this.version,
      application: this.application,
      notes:       this.notes.join('- '),
      created_at:  this.created_at  ? this.parceDateISO(this.created_at)  : null,
      released_at: this.released_at ? this.parceDateISO(this.released_at) : null
    });
  }

  private parceNotes(notes: string): string[] {
    return notes.split('- ').map(n => n.trim()).filter(n => n);
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  private parceDateISO(date): string {
    return date && date instanceof Date ? date.toISOString() : null;
  }

}
