import { PhotoDocumentModel, PhotoDocumentSimplifiedModel, PhotoDocumentStates } from "@shared/models";
import { Employee } from "@shared/factories";

export class PhotoDocumentSimplified {
  id:                     number;
  name:                   string;
  nameReverse:            string;
  personalNumber:         number;

  createdAt:              Date;
  readAt:                 Date;
  state:                  PhotoDocumentStates;

  releasedToArchiveAt:    Date;
  releasedToZvooveAt:     Date;
  releasedToZvooveStatus: string;

  archivedBySystemAt:     Date;

  subject:                string;
  subjectCorrected:       string;

  externalEmployee:       Employee;
  constructor(data: PhotoDocumentSimplifiedModel) {
    this.id                      = data.id;
    this.name                    = data.external_employee.first_name + ' '  + data.external_employee.last_name;
    this.nameReverse             = data.external_employee.last_name  + ', ' + data.external_employee.first_name;
    this.personalNumber          = data.external_employee.personal_number;

    this.createdAt               = this.parceDate(data.created_at);
    this.readAt                  = this.parceDate(data.read_at);
    this.state                   = data.state;

    this.releasedToArchiveAt     = this.parceDate(data.released_to_archive_at);
    this.releasedToZvooveAt      = this.parceDate(data.released_to_zvoove_at);
    this.releasedToZvooveStatus  = this.getZvooveStatus(data.released_to_zvoove_status);

    this.archivedBySystemAt      = this.parceDate(data.archived_by_system_at);

    this.subject                 = data.subject;
    this.subjectCorrected        = data.subject_corrected || '';

    this.externalEmployee        = data.external_employee ? data.external_employee instanceof Employee ? data.external_employee : new Employee(data.external_employee) : null;
  }

  get activeSubject(): string {
    return this.subjectCorrected || this.subject;
  }

  private getZvooveStatus(zvooveStatus: string): string {
    switch (zvooveStatus) {
      case 'submit_as_contact_zvoove':
        return 'als Kontakt übertragen';
      case 'submit_as_doc_zvoove':
        return 'als Dokument übertragen';
      default:
        return 'nicht übertragen';
    }
  }

  get archivedBySystem(): boolean {
    return this.archivedBySystemAt && this.state === 'archived_by_system';
  }

  private parceDate(date): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): PhotoDocumentSimplifiedModel {
    return {
      id:                        this.id                     ? this.id                                : null,
      created_at:                this.createdAt              ? this.createdAt.toISOString()           : null,
      read_at:                   this.readAt                 ? this.readAt.toISOString()              : null,
      state:                     this.state                  ? this.state                             : null,
      subject:                   this.subject                ? this.subject                           : null,
      subject_corrected:         this.subjectCorrected       ? this.subjectCorrected                  : null,

      released_to_archive_at:    this.releasedToArchiveAt    ? this.releasedToArchiveAt.toISOString() : null,
      released_to_zvoove_at:     this.releasedToZvooveAt     ? this.releasedToZvooveAt.toISOString()  : null,
      released_to_zvoove_status: this.releasedToZvooveStatus ? this.releasedToZvooveStatus            : null,

      archived_by_system_at:     this.archivedBySystemAt     ? this.archivedBySystemAt.toISOString()  : null,

      external_employee:         this.externalEmployee       ? this.externalEmployee.toJSON()         : null
    };
  }

}

export class PhotoDocument extends PhotoDocumentSimplified {
  releasedToArchiveBy:     Employee;
  releasedToZvooveBy:      Employee;
  submitToZvoove:          boolean;

  attachmentUrl:           string;
  attachmentRotationAngle: number;

  messageExternalEmployee: string;
  commentInternalEmployee: string;

  daysBeforeAutoArchive:   number;
  autoArchiveAt:           Date;
  constructor(data: PhotoDocumentModel) {
    super(data);

    this.releasedToArchiveBy     = data.released_to_archive_by ? data.released_to_archive_by instanceof Employee ? data.released_to_archive_by : new Employee(data.released_to_archive_by) : null;
    this.releasedToZvooveBy      = data.released_to_zvoove_by  ? data.released_to_zvoove_by  instanceof Employee ? data.released_to_zvoove_by  : new Employee(data.released_to_zvoove_by)  : null;
    this.submitToZvoove          = data.submit_to_zvoove;

    this.messageExternalEmployee = data.message_external_employee;
    this.commentInternalEmployee = data.comment_internal_employee;

    this.attachmentUrl           = data.attachment_url;
    this.attachmentRotationAngle = data.attachment_rotation_angle;

    this.daysBeforeAutoArchive   = data.days_before_auto_archive;
    this.autoArchiveAt           = this.parceAutoArchiveAt();
  }

  private parceAutoArchiveAt(): Date {
    if (this.daysBeforeAutoArchive === null) return null;
    if (isNaN(this.daysBeforeAutoArchive))   return null;
    let date = new Date();
    date.setDate(date.getDate() + this.daysBeforeAutoArchive);
    date.setHours(0,0,0);
    return date;
  }

  toJSON(): PhotoDocumentModel {
    return Object.assign(super.toJSON(), {
      personal_number:           this.personalNumber          ? this.personalNumber               : null,

      released_to_archive_by:    this.releasedToArchiveBy     ? this.releasedToArchiveBy.toJSON() : null,
      released_to_zvoove_by:     this.releasedToZvooveBy      ? this.releasedToZvooveBy.toJSON()  : null,

      submit_to_zvoove:          this.submitToZvoove          ? this.submitToZvoove               : null,

      message_external_employee: this.messageExternalEmployee ? this.messageExternalEmployee      : null,
      comment_internal_employee: this.commentInternalEmployee ? this.commentInternalEmployee      : null,

      attachment_url:            this.attachmentUrl           ? this.attachmentUrl                : null,
      attachment_rotation_angle: this.attachmentRotationAngle ? this.attachmentRotationAngle      : null,
      days_before_auto_archive:  this.daysBeforeAutoArchive   ? this.daysBeforeAutoArchive        : null
    });
  }

}
