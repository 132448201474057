// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .activity-reports-table .table-header-wrapper .status-cell {
  display: flex;
}
::ng-deep .activity-reports-table .table-header-wrapper .status-cell .table-header-cell-title {
  word-break: keep-all;
}
::ng-deep .activity-reports-table .table-body-wrapper .list-row div[class\$=-cell] .xs-label,
::ng-deep .activity-reports-table .table-body-wrapper .list-row div[class*="-cell "] .xs-label {
  min-width: 140px;
  width: 140px;
}
::ng-deep .activity-reports-table .table-body-wrapper .list-row div[class\$=-cell] .xs-label-icons span[class*=" icon-"],
::ng-deep .activity-reports-table .table-body-wrapper .list-row div[class*="-cell "] .xs-label-icons span[class*=" icon-"] {
  font-size: 20px;
}
::ng-deep .activity-reports-table .name-cell {
  order: 1;
  min-width: 85px;
  flex: 1 1 100px;
}
::ng-deep .activity-reports-table .pers-num-cell {
  order: 2;
  min-width: 85px;
  flex: 0 1 90px;
}
@media (max-width: 1280px) {
  ::ng-deep .activity-reports-table .pers-num-cell {
    display: none;
  }
}
::ng-deep .activity-reports-table .date-cell {
  order: 3;
  flex: 0 0 80px;
}
@media (max-width: 1280px) {
  ::ng-deep .activity-reports-table .date-cell {
    flex: 0 0 75px;
  }
}
::ng-deep .activity-reports-table .realease-date-cell {
  order: 3;
  flex: 0 0 100px;
}
@media (max-width: 1280px) {
  ::ng-deep .activity-reports-table .realease-date-cell {
    flex: 0 0 95px;
  }
}
::ng-deep .activity-reports-table .title-cell {
  order: 4;
  flex: 5 0 200px;
  min-width: 60px;
  word-break: break-all;
}
@media (max-width: 1280px) {
  ::ng-deep .activity-reports-table .title-cell {
    flex: 5 1 100px;
  }
}
::ng-deep .activity-reports-table .week-cell {
  order: 5;
  min-width: 85px;
  flex: 0 1 170px;
  white-space: pre-line;
}
@media (max-width: 1280px) {
  ::ng-deep .activity-reports-table .week-cell {
    flex: 0 1 85px;
  }
}
::ng-deep .activity-reports-table .icons-cell {
  order: 6;
  min-width: 130px;
  flex: 0 1 130px;
}
::ng-deep .activity-reports-table .icons-cell .icon-car:not(.hide) {
  cursor: pointer;
}
::ng-deep .activity-reports-table .icons-cell .icon-car:not(.hide):hover {
  opacity: 0.8;
}
::ng-deep .activity-reports-table .icons-cell .holidays-icon {
  position: relative;
}
::ng-deep .activity-reports-table .status-cell {
  order: 7;
  min-width: 30px;
  flex: 0 0 30px;
}
::ng-deep .activity-reports-table .action-cell {
  order: 8;
  min-width: 80px;
  flex: 0 1 100px;
}
@media (max-width: 1000px) {
  ::ng-deep .activity-reports-table.sidebar-open .table-header-wrapper {
    display: none;
  }
}
@media (max-width: 1000px) {
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .xs-hide {
    display: none !important;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row {
    min-height: 0;
    padding: 0;
    margin: 10px 0;
    flex-direction: column;
    border: 1px solid #CED4DA;
    border-radius: 4px;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell],
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] {
    display: flex;
    flex-direction: row !important;
    flex: 0;
    width: 100%;
    margin: 0;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell]:not(.icons-cell):not(:last-child), ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell]:not(:last-child),
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "]:not(.icons-cell):not(:last-child),
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "]:not(:last-child) {
    border-bottom: 1px solid #CED4DA;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-label,
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-label {
    display: flex;
    min-height: 40px;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-label + span,
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-label + span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 130px);
    line-height: 20px;
    padding: 10px 0;
    word-break: break-all;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-icon,
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-button,
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-icon,
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-button {
    display: flex !important;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell].checkbox-cell,
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "].checkbox-cell {
    display: none;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell].action-cell.failed-erp,
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "].action-cell.failed-erp {
    flex: auto;
  }
  ::ng-deep .activity-reports-table.sidebar-open .table-body-wrapper .list-row .buttons-group {
    margin: 10px 10px 10px 0;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
