import { UserModel, UserCustomerCompanyModel, UserLocationModel, UserPreferencesModel, UserReleaseModel, UserFeaturesModel } from "@shared/models";

export class User {
  id:                 number;
  email:              string;
  first_name:         string;
  name:               string;

  isInternal:         boolean;
  isCustomer:         boolean;

  locations:          UserLocationModel[];
  preferences:        UserPreferencesModel[];
  appLevelRoles:      string[];

  customer_companies: UserCustomerCompanyModel[];
  latest_releases:    UserReleaseModel[];
  features:           UserFeaturesModel;
  websocketJWT:       string;
  constructor(data: UserModel) {
    this.id                 = data.id;
    this.email              = data.email;
    this.first_name         = data.first_name;
    this.name               = data.name;

    this.isInternal         = data.user_role === 'Internal Employee';
    this.isCustomer         = data.user_role === 'Customer';

    this.locations          = data.locations;
    this.preferences        = data.preferences?.length     ? data.preferences     : [];
    this.appLevelRoles      = data.app_level_roles?.length ? data.app_level_roles : [];

    this.customer_companies = data.customer_companies;
    this.latest_releases    = data.latest_releases || [];
    this.features           = data.features;
    this.websocketJWT       = data.websocket_jwt;
  }

  get reportsNotification(): UserPreferencesModel {
    return this.preferences.find(p => p.category === 'reports_notification');
  }

  get invoicesNotification(): UserPreferencesModel {
    return this.preferences.find(p => p.category === 'invoices_notification');
  }

}
