// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .ebs-list-wrapper .list-row {
  font-weight: 500;
}
::ng-deep .ebs-list-wrapper .list-row:not(.list-header) {
  line-height: 40px;
}
::ng-deep .ebs-list-wrapper .name-cell {
  order: 1;
  min-width: 85px;
  flex: 0 1 200px;
}
::ng-deep .ebs-list-wrapper .date-cell {
  order: 2;
  min-width: 80px;
  flex: 0 0 80px;
}
::ng-deep .ebs-list-wrapper .pers-num-cell {
  order: 3;
  min-width: 80px !important;
  flex: 0 0 100px;
}
::ng-deep .ebs-list-wrapper .title-cell {
  order: 4;
  min-width: 200px;
  flex: 3 0 200px;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  ::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper {
    margin: 10px 0;
  }
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .icon-arrow-down {
  display: flex;
  font-size: 24px;
  margin-right: 10px;
  align-self: center;
  cursor: pointer;
  transition: transform 100ms linear;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .icon-arrow-down.collapsed {
  transform: rotate(180deg);
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .icon-arrow-down.hide {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 768px) {
  ::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .icon-arrow-down.hide {
    display: none;
  }
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-container {
  height: 0px;
  overflow: hidden;
  transition: height 200ms linear;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item {
  min-height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
  cursor: pointer;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item .history-item-name {
  line-height: 20px;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item .history-item-name:hover {
  text-decoration: underline;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item.old {
  margin-top: 5px;
  padding-top: 0;
  opacity: 0.3;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item .icon-tick,
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item .icon-cross {
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item .icon-tick {
  background-color: #95C11F;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item .icon-cross {
  background-color: #DE440B;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item .needs-confirmation {
  color: #DE440B;
}
::ng-deep .ebs-list-wrapper .title-cell .ebs-history-wrapper .history-item .needs-confirmation .history-item-name {
  text-decoration-color: #DE440B;
}
::ng-deep .ebs-list-wrapper .action-cell {
  order: 5;
  min-width: 130px;
  flex: 0 1 140px;
}
@media (max-width: 1000px) {
  ::ng-deep .ebs-list-wrapper.sidebar-open .title-cell .ebs-history-wrapper {
    flex-direction: row !important;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .title-cell .ebs-history-wrapper .icon-arrow-down.hide {
    display: none;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-header-wrapper {
    display: none;
  }
}
@media (max-width: 1000px) {
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .xs-hide {
    display: none !important;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row {
    min-height: 0;
    padding: 0;
    margin: 10px 0;
    flex-direction: column;
    border: 1px solid #CED4DA;
    border-radius: 4px;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell],
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] {
    display: flex;
    flex-direction: row !important;
    flex: 0;
    width: 100%;
    margin: 0;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell]:not(.icons-cell):not(:last-child), ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell]:not(:last-child),
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "]:not(.icons-cell):not(:last-child),
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "]:not(:last-child) {
    border-bottom: 1px solid #CED4DA;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-label,
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-label {
    display: flex;
    min-height: 40px;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-label + span,
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-label + span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 130px);
    line-height: 20px;
    padding: 10px 0;
    word-break: break-all;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-icon,
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-button,
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-icon,
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-button {
    display: flex !important;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell].checkbox-cell,
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "].checkbox-cell {
    display: none;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell].action-cell.failed-erp,
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "].action-cell.failed-erp {
    flex: auto;
  }
  ::ng-deep .ebs-list-wrapper.sidebar-open .table-body-wrapper .list-row .buttons-group {
    margin: 10px 10px 10px 0;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
