import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomerNotificationModel, CustomersNotificationPostModel } from '@shared/models';
import { environment } from 'environments/environment';
import { CustomerNotification } from '@shared/factories';

interface CustomersNotificationResponseModel {
  customers_notification: CustomerNotificationModel;
}

@Injectable()
export class CustomersNotificationsService {
  private get TN_CUSTOMERS_NOTIFICATION_API(): string { return `${environment.apiUrl}api/portal/v3/customers_notifications` };
  constructor (private http: HttpClient) { }

  sendCustomersNotification(params: CustomersNotificationPostModel): Observable<any> {
    return this.http.post<CustomersNotificationResponseModel>(this.TN_CUSTOMERS_NOTIFICATION_API, params).pipe(
      map(res => res.customers_notification),
      map(res => new CustomerNotification(res))
    );
  }

}
