import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:     'table-entries-body',
  templateUrl:  'table-entries-body.component.html',
  styleUrls: ['./table-entries-body.component.sass'],
})
export class TableEntriesBodyComponent implements OnInit, OnDestroy {
  @Input() rows:           any;
  @Input() paginationId:   string;
  @Input() tableBodyClass: any;
  @Output() callback       = new EventEmitter<any>();
  @Output() buttonCallback = new EventEmitter<any>();

  subscriptions: SubscriptionLike[] = [];

  tableRows:    any;

  page:         number;
  itemsPerPage: number;
  totalCount:   number;

  constructor(private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.sessionStorageService.itemsPerPage.subscribe(items => this.itemsPerPage = items));
    this.subscriptions.push(this.sessionStorageService.pageNumber.subscribe(page    => this.page = page));
    this.subscriptions.push(this.sessionStorageService.totalCount.subscribe(count   => this.totalCount = count));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rows.firstChange || JSON.stringify(changes.rows.currentValue) !== JSON.stringify(changes.rows.previousValue)) this.tableRows = changes.rows.currentValue;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  callbackHandler(event, ...indexes): void {
    let temp = Array.isArray(event) ? event : [event, indexes];
    this.callback.emit(temp);
  }

  buttonCallbackHandler(event, ...indexes): void {
    let temp = [event, indexes];
    this.buttonCallback.emit(temp);
  }

}