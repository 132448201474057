import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(items: any[], columns: string[], order: boolean): any[] | null {
    if (items) {
      if (!columns) {
        return items;
      } else {
        return items.sort((a, b) => {
          for (let i=0; i<columns.length; i++) {
            if (a[columns[i]] < b[columns[i]] || (a[columns[i]] === null && b[columns[i]] !== null)) {
              return order ? 1 : -1;
            } else if (a[columns[i]] > b[columns[i]] || (a[columns[i]] !== null && b[columns[i]] === null)) {
              return order ? -1 : 1;
            }
          }
          return 0;
        });
      }
    }
    return null;
  }
}
