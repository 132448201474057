import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(items: any[], value: string, prop: string): any[] {
    if (!items) return [];
    if (!value) return items;
    return items.filter(item => {
      let pass = false;
      for (var prop in item) {
        if (!pass) pass = this.searchByFields(item, prop, value);
      }
      return pass;
    });
  }

  private searchByFields(item, prop, value) {
    switch (prop) {
      case 'name':
      case 'external_name':
      case 'personal_number':
      case 'auftelefon1':
      case 'customer_released_at':
      case 'created_at':
      case 'title':
      case 'titleWithVersionNumber':
      case 'activeSubject':
        return this.format(item[prop], value);
      case 'date':
        return this.format(item[prop].replace(/ /g, ''), value.replace(/ /g, ''));
      case 'calendar_week':
        return this.format(item[prop].replace(/ /g, ''), value.replace(/ /g, '').substring(0, 4)) &&
               this.format(item['date'].replace(/ /g, ''), value.replace(/ /g, '').substr(4)); // ex. KW 20 11.05.2020 - 17.05.2020
      default:
        return false;
    }
  }

  private format(prop, value) { 
    if (!isNaN(parseInt(prop)) || typeof(prop) === 'string') return prop.toString().toLowerCase().includes(value.toLowerCase())
    else if (prop instanceof Date) return this.format(`${this.addFloatingZero(prop.getDate())}.${this.addFloatingZero(+prop.getMonth() + 1)}.${prop.getFullYear()}`, value);
    return false;
  }

  private addFloatingZero(n: number): string {
    return n >= 10 ? `${n}` : `0${n}`;
  }
}