// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .pd-list-wrapper .table-header-wrapper .status-cell {
  display: flex;
}
::ng-deep .pd-list-wrapper .table-header-wrapper .status-cell .table-header-cell-title {
  word-break: keep-all;
}
::ng-deep .pd-list-wrapper .table-header-wrapper .archive-cell,
::ng-deep .pd-list-wrapper .table-header-wrapper .zvoove-cell {
  display: flex;
  align-items: center;
}
::ng-deep .pd-list-wrapper .table-header-wrapper .archive-cell .table-header-cell-title,
::ng-deep .pd-list-wrapper .table-header-wrapper .zvoove-cell .table-header-cell-title {
  word-break: break-word;
}
::ng-deep .pd-list-wrapper .date-cell {
  order: 1;
  min-width: 120px;
  flex: 0 1 170px;
}
::ng-deep .pd-list-wrapper .name-cell {
  order: 2;
  min-width: 100px;
  flex: 0 1 200px;
}
::ng-deep .pd-list-wrapper .pers-num-cell {
  order: 3;
  min-width: 85px;
  flex: 0 1 150px;
}
@media (max-width: 1280px) {
  ::ng-deep .pd-list-wrapper .pers-num-cell {
    display: none;
  }
}
::ng-deep .pd-list-wrapper .subject-cell {
  order: 4;
  min-width: 50px;
  flex: 1 1 250px;
}
::ng-deep .pd-list-wrapper .archive-cell {
  order: 5;
  min-width: 85px;
  flex: 0 0 85px;
}
::ng-deep .pd-list-wrapper .zvoove-cell {
  order: 6;
  min-width: 85px;
  flex: 0 0 85px;
}
::ng-deep .pd-list-wrapper .status-cell {
  order: 7;
  min-width: 30px;
  flex: 0 0 30px;
  position: relative;
}
::ng-deep .pd-list-wrapper .action-cell {
  order: 8;
  min-width: 100px;
  flex: 0 0 100px;
}
@media (max-width: 1280px) {
  ::ng-deep .pd-list-wrapper .action-cell {
    min-width: 80px;
    flex: 0 0 80px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
