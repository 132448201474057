import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class StopBetaCallsInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let stopCall = request.url.includes('q[locations][]=0') || request.url.includes('q[customers_companies][company_numbers][]=0');
    if (stopCall) return of(new HttpResponse());
    return next.handle(request);
  }
}
