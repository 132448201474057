import { Component, Input } from '@angular/core';

@Component({
  selector: 'history-box',
  templateUrl: 'history-box.component.html',
  styleUrls: ['./history-box.component.sass'],
})
export class HistoryBoxComponent {
  @Input() title:      string;
  @Input() entries:    any[];
  @Input() readOnly:   boolean = true;
  @Input() simplified: boolean;
  @Input() photoTn:    boolean;

  boxOpen: boolean = false;
  constructor() { }

  toggleBox(e): void {
    e.stopPropagation();
    this.boxOpen = !this.boxOpen;
  }
}
