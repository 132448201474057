import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private accessTokenSubject: BehaviorSubject<string>;
  public accessToken: Observable<string>;

  private readonly ACCESS_TOKEN = 'access_token';
  private readonly REFRESH_TOKEN = 'refresh_token';

  constructor( private http: HttpClient ) {
    this.accessTokenSubject = new BehaviorSubject<string>(localStorage.getItem('access_token'));
    this.accessToken = this.accessTokenSubject.asObservable();
  }
  saveAccessToken(token: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, token);
    this.accessTokenSubject.next(token);
  }

  public get accessTokenValue(): string {
    return this.accessTokenSubject.value;
  }

  isLoggedIn(): boolean {
    return !!this.accessTokenValue;
  }

  login(user: { email: string, password: string }) {
    return this.http.post<any>(`${environment.apiUrl}api/v3/users/sign_in`, {user})
    .pipe(
      map(res => { 
        if (res && res.refresh_token) this.storeRefreshToken(res.refresh_token); 
      })
    );
  }

  logout() {
    return this.http.delete<any>(`${environment.apiUrl}api/v3/users/sign_out`).pipe(take(1)).subscribe(
      res => {},
      err => {
        this.removeTokens();
        location.reload();
      },
      () => {
        this.removeTokens();
        location.reload();
      }
    );
  }

  storeRefreshToken(token: string): void {
    localStorage.setItem(this.REFRESH_TOKEN, token);
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  removeTokens(): void {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    this.accessTokenSubject.next(null);
  }

}
