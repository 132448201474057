// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
}
#modal-wrapper .modal-overflow {
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
}
#modal-wrapper .modal-popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  background-color: #FFFFFF;
  padding: 40px 50px 25px 50px;
  width: 400px;
  min-height: 300px;
}
#modal-wrapper .modal-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#modal-wrapper .modal-desc .popup-wait-animation {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
#modal-wrapper .modal-desc .popup-wait-animation span {
  width: 10px;
  height: 10px;
  background-color: #0099A8;
  border-radius: 50%;
}
#modal-wrapper .modal-desc .popup-wait-animation span:not(:last-child) {
  margin-right: 10px;
}
#modal-wrapper .modal-desc .popup-wait-animation span:nth-child(1) {
  animation: 2s ease-in-out 0s infinite wait-animation;
}
#modal-wrapper .modal-desc .popup-wait-animation span:nth-child(2) {
  animation: 2s ease-in-out 500ms infinite wait-animation;
}
#modal-wrapper .modal-desc .popup-wait-animation span:nth-child(3) {
  animation: 2s ease-in-out 1000ms infinite wait-animation;
}
#modal-wrapper .modal-desc .popup-wait-animation span:nth-child(4) {
  animation: 2s ease-in-out 1500ms infinite wait-animation;
}
#modal-wrapper .modal-desc .icon-warning,
#modal-wrapper .modal-desc .icon-comment,
#modal-wrapper .modal-desc .icon-circle-info,
#modal-wrapper .modal-desc .icon-circle-cross,
#modal-wrapper .modal-desc .icon-circle-tick,
#modal-wrapper .modal-desc .icon-circle-question {
  font-size: 60px;
  height: 60px;
  margin-bottom: 20px;
}
#modal-wrapper .modal-desc .icon-comment,
#modal-wrapper .modal-desc .icon-circle-question,
#modal-wrapper .modal-desc .icon-circle-info {
  color: #0099A8;
}
#modal-wrapper .modal-desc .icon-warning {
  color: #FF9228;
}
#modal-wrapper .modal-desc .icon-circle-cross {
  color: #DE440B;
}
#modal-wrapper .modal-desc .icon-circle-tick {
  color: #95C11F;
}
#modal-wrapper .modal-desc .modal-title {
  color: #575756;
  font-size: 18px;
  height: 40px;
}
#modal-wrapper .modal-desc .modal-text {
  font-size: 16px;
  color: #7D8B92;
  text-align: center;
  width: 100%;
}
#modal-wrapper .modal-desc .modal-optional-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  width: 17px;
  height: 17px;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-top: 20px;
  margin-left: 15px;
  align-self: flex-start;
}
#modal-wrapper .modal-desc .modal-optional-checkbox label {
  display: inline-block;
  margin-bottom: 0.5rem;
  position: absolute;
  top: -1px;
  left: 25px;
  margin: 0;
  font-size: 12px;
}
#modal-wrapper .modal-desc .modal-optional-checkbox input {
  opacity: 0;
  position: absolute;
}
#modal-wrapper .modal-desc .modal-optional-checkbox span {
  white-space: nowrap;
  padding-left: 25px;
  margin-left: -25px;
}
#modal-wrapper .modal-desc .modal-optional-checkbox input:checked ~ span:after {
  content: "P";
  position: absolute;
  left: -23px;
  font-family: icon-font;
  font-size: 12px;
}
#modal-wrapper .modal-btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 25px;
}
#modal-wrapper .modal-btn-group button {
  width: 120px;
  height: 36px;
  border-radius: 4px;
  border: none;
}
#modal-wrapper .modal-btn-group button:nth-child(2) {
  margin-left: 30px;
}
#modal-wrapper .modal-btn-group button.popup-btn-yes {
  background-color: #95C11F;
  color: #FFFFFF;
}
#modal-wrapper .modal-btn-group button.popup-btn-no {
  background-color: #7D8B92;
  color: #FFFFFF;
}
#modal-wrapper .modal-btn-group button.popup-btn-back {
  border: 1px solid #7D8B92;
  color: #7D8B92;
}
#modal-wrapper .modal-btn-group button.popup-btn-custom {
  flex: 0 0 40%;
  margin-bottom: 15px;
  border: 1px solid #0099A8;
  color: #0099A8;
}

@keyframes wait-animation {
  0% {
    background-color: #0099A8;
  }
  25% {
    background-color: #95C11F;
  }
  50% {
    background-color: #0099A8;
  }
  100% {
    background-color: #0099A8;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
