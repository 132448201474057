import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NotificationService, SessionStorageService } from "@shared/services";
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['./pagination.component.sass'],
})
export class PaginationComponent {
  @Input() id: string;
  @Output() callback = new EventEmitter<any>();
  subscriptions: SubscriptionLike[] = [];

  paginationOptions: number[] = [10,25,50];

  itemsPerPage: number;
  totalCount: number;
  constructor(
    private notificationService: NotificationService,
    private sessionStorageService: SessionStorageService
  ) { }
  
  ngOnInit() {
    this.subscriptions.push(this.sessionStorageService.itemsPerPage.subscribe(items => this.itemsPerPage = items));
    this.subscriptions.push(this.sessionStorageService.totalCount.subscribe(count => this.totalCount = count));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  changePage(p: number): void {
    if (this.callback.observers.length) this.notificationService.wait();
    this.sessionStorageService.changePageNumber(p);
    if (this.callback.observers.length) this.callback.emit();
  }

  changeItemsPerPage(p: number): void {
    if (this.callback.observers.length) this.notificationService.wait();
    this.sessionStorageService.changePageNumber(1);
    this.sessionStorageService.changeItemsPerPage(p);
    if (this.callback.observers.length) this.callback.emit();
  }
}
