import { ElasticLogModel, RequestLogModel } from '@shared/models';
import { environment } from 'environments/environment';

export class Log {
  app_name: string;
  app_subsys: string;
  app_env: string;
  app_ver: string;
  token: string;
  user_id: number;
  timestamp: string;
  app_target: string;
  source_ip: string;
  severity: string;
  message: string;
  additional_data?: any;
  constructor(data: ElasticLogModel) {
    this.app_name = 'Tempton Portals';
    this.app_subsys = 'Portals';
    this.app_env = environment.apiUrl === 'https://mt-api-staging.tc.g8s.de/' || environment.apiUrl === 'https://app-staging-api.tempton.de/' ? 'staging' :
                   environment.apiUrl === 'https://mt-api-preprod.tc.g8s.de/' || environment.apiUrl === 'https://app-test-api.tempton.de/' ? 'preprod' :
                   environment.apiUrl === 'https://app-api.tempton.de/' ? 'live' : '' ;
    this.app_ver = environment.VERSION;
    this.token = data.token;
    this.user_id = data.user_id;
    this.timestamp = new Date().toISOString();
    this.app_target = 'Tempton Portals';
    this.severity = data.severity;
    this.message = data.message;
    this.additional_data = data.additional_data || null;
  }

}

export class ApiErrorLog extends Log {
  path: string;
  response_code: number;
  constructor(data: RequestLogModel) {
    super(data);
    this.path = data.path;
    this.response_code = data.response_code;
  }
}

export class ValidationErrorLog extends Log {
  constructor(data: ElasticLogModel) {
    super(data);
  }
}
