// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  font-size: 18px;
  font-weight: 500;
  color: #575756;
}

.history-box-overflow {
  height: 0;
  overflow: hidden;
}
.history-box-overflow.collapsed {
  height: 100%;
  overflow: inherit;
  border-top: solid 1px #CED4DA;
}

::ng-deep history-box-item {
  display: flex;
}
::ng-deep history-box-item .icon-arrow-down,
::ng-deep history-box-item .history-box-entry-with-tooltip {
  pointer-events: auto !important;
}
::ng-deep history-box-item:not(:first-of-type) {
  border-top: solid 1px #CED4DA;
}
::ng-deep history-box-item:not(.operatable) {
  pointer-events: none;
}
::ng-deep history-box-item.operatable {
  cursor: pointer;
}
::ng-deep history-box-item.operatable:hover {
  opacity: 0.7;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
