// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#portal-sidebar {
  display: inline-flex;
}
#portal-sidebar .sidebar-overflow {
  display: none;
  background-color: rgba(0, 0, 0, 0.3019607843);
  z-index: 1;
}
#portal-sidebar.mini-sidebar-xs .sidebar-overflow {
  display: block;
}

#portal-sidebar .sidebar-content-wrapper {
  width: 300px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  z-index: 2;
}
@media (max-width: 1280px) {
  #portal-sidebar .sidebar-content-wrapper {
    width: 240px;
  }
}
@media (max-width: 768px) {
  #portal-sidebar .sidebar-content-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  #portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content-wrapper {
    left: -300px;
  }
}
@media (max-width: 450px) {
  #portal-sidebar .sidebar-content-wrapper {
    width: 215px;
  }
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content-wrapper {
  width: 64px;
}

#portal-sidebar .sidebar-content .sidebar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
@media (max-height: 850px) {
  #portal-sidebar .sidebar-content .sidebar-header {
    margin-bottom: 5px;
  }
}
@media (max-width: 768px) {
  #portal-sidebar .sidebar-content .sidebar-header {
    margin-bottom: 10px;
  }
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-header {
  flex-direction: column-reverse;
  align-items: center;
}

#portal-sidebar .sidebar-content .sidebar-header .logo {
  width: 145px;
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-header .logo {
  width: 40px;
}

#portal-sidebar .sidebar-content .sidebar-header .logo img {
  width: 100%;
  flex-shrink: 0;
}
#portal-sidebar .sidebar-content .sidebar-header .logo span {
  font-size: 10px;
  margin-top: 5px;
}
@media (max-height: 850px) {
  #portal-sidebar .sidebar-content .sidebar-header .logo span {
    margin-top: 0;
  }
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-header .logo span {
  display: none;
}

#portal-sidebar .sidebar-content .sidebar-portal-nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#portal-sidebar .sidebar-content .sidebar-portal-nav::-webkit-scrollbar {
  display: none;
}
#portal-sidebar .sidebar-content .sidebar-portal-nav ul li {
  height: 50px;
  cursor: pointer;
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-portal-nav ul li {
  position: relative;
}

@media (max-width: 768px) {
  #portal-sidebar .sidebar-content .sidebar-portal-nav ul li {
    height: 45px;
  }
}
@media (max-height: 780px) {
  #portal-sidebar .sidebar-content .sidebar-portal-nav ul li {
    height: 40px;
  }
}
@media (max-height: 700px) {
  #portal-sidebar .sidebar-content .sidebar-portal-nav ul li {
    height: 35px;
  }
}
@media (max-height: 660px) {
  #portal-sidebar .sidebar-content .sidebar-portal-nav ul li {
    height: 30px;
  }
}
#portal-sidebar .sidebar-content .sidebar-portal-nav ul li.orange {
  color: #FF9228;
}
#portal-sidebar .sidebar-content .sidebar-portal-nav ul li.banner {
  height: auto;
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-portal-nav ul li.banner {
  display: none;
}

@media (max-width: 768px) {
  #portal-sidebar .sidebar-content .sidebar-portal-nav ul li.banner {
    display: none;
  }
}
#portal-sidebar .sidebar-content .sidebar-portal-nav ul li.label {
  cursor: default;
  height: 30px;
}
@media (max-height: 1000px) {
  #portal-sidebar .sidebar-content .sidebar-portal-nav ul li.label {
    height: 25px;
  }
}
@media (max-height: 850px) {
  #portal-sidebar .sidebar-content .sidebar-portal-nav ul li.label {
    height: 17px;
  }
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-portal-nav ul li.ml-5 .counter {
  right: 5px;
}

#portal-sidebar .sidebar-content .sidebar-portal-nav ul li:not(.label-item):hover {
  color: #0099A8;
}
#portal-sidebar .sidebar-content .sidebar-portal-nav ul li .sidebar-icon {
  margin-right: 10px;
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-portal-nav ul li .sidebar-icon {
  margin-left: 8px;
}

#portal-sidebar .sidebar-content .sidebar-portal-nav ul li .sidebar-title {
  font-size: 12px;
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-portal-nav ul li .sidebar-title {
  flex: 0;
}

#portal-sidebar .sidebar-content .sidebar-portal-nav ul li .sidebar-banner {
  max-width: 100%;
}
#portal-sidebar .sidebar-content .sidebar-portal-nav ul li .label-item {
  font-size: 10px;
  position: relative;
  display: block;
  color: #575756;
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-portal-nav ul li .label-item {
  left: -150px;
}

#portal-sidebar .sidebar-content .sidebar-portal-nav ul li .label-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(100% + 20px);
  transform: translate(0, -50%);
  background-color: #EFEFEF;
  width: 300px;
  height: 5px;
}
@media (max-height: 850px) {
  #portal-sidebar .sidebar-content .sidebar-portal-nav ul li .label-item::before {
    height: 3px;
  }
}
#portal-sidebar .sidebar-content .sidebar-portal-nav ul li .optional-highlight {
  flex: 0 0 5px;
}
#portal-sidebar.mini-sidebar:not(.mini-sidebar-xs) .sidebar-content .sidebar-portal-nav ul li .optional-highlight {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
