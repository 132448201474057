import { CustomerCompanyExtendedModel, CustomerCompanyModel } from "@shared/models";

export class CustomerCompany {
  id:             number;
  created_at:     Date;
  name:           string;
  address:        string;
  company_number: number;
  phone_number:   string;
  constructor(data: CustomerCompanyModel) {
    this.id             = data.id;
    this.created_at     = this.parceDate(data.created_at);
    this.name           = data.name;
    this.address        = data.address;
    this.company_number = data.company_number;
    this.phone_number   = data.phone_number;
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }
}

export class CustomerCompanyExtended extends CustomerCompany {
  locations:      number[]
  pdfTimeFormat:  string;
  prefillProject: boolean;
  constructor(data: CustomerCompanyExtendedModel) {
    super(data);
    this.locations      = data.locations;
    this.pdfTimeFormat  = data.pdf_time_format;
    this.prefillProject = data.prefill_activity_with_project;
  }
}
