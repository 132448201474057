import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService, SidebarStorageService } from '@shared/services';

@Component({
  selector:     'mobile-header-menu',
  templateUrl:  'mobile-header-menu.component.html',
  styleUrls: ['./mobile-header-menu.component.sass'],
  host: {'class': 'am-flex-justify am-flex-align mt-s mb-s relative'}

})
export class MobileHeaderMenuComponent {
  @Output() backCallback = new EventEmitter<any>();
  constructor(
    private router:                Router,
    private sessionStorageService: SessionStorageService,
    private sidebarStorageService: SidebarStorageService,
  ) { }

  backCallbackHandler(): void {
    this.backCallback.emit();
  }

  openHomePage(): void {
    this.sessionStorageService.changeAndResetActiveTab('dashboard');
    let navItem = this.sidebarStorageService.sidebarItems.find(item => item.identifier === 'dashboard');
    this.router.navigateByUrl(navItem.path);
  }

}