import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SessionStorageService } from '@shared/services';

interface TotorialPageModel {
  buttonName?:  string;

  buttonClass?: string;
  class?:       string;

  title?:       string;
  titleHtml?:   string;

  desc?:        string;
  descHtml?:    string;

  image?:       string;
}

@Component({
  selector:      'tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls:  ['./tutorial.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialPopupComponent implements OnInit {
  @Input() tutorial: string;

  pages:        TotorialPageModel[];
  currentPage:  number;
  passTutorial: boolean;
  private readonly COMPONENT_NAME: string = 'Tutorial';
  constructor(
    private cdr:                   ChangeDetectorRef,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.pages = this.prepareTutorial();
    if (this.pages) this.currentPage = 0;
  }

  prepareTutorial(): TotorialPageModel[] {
    switch (this.tutorial) {
      case 'photo-tn':
        return this.preparePhotoTNTutorial();
      case 'assignments-export':
        return this.prepareReportingTutorial();
      case 'mitarbeiter':
        return this.prepareMitarbeiterTutorial();
      case 'tempton-connect':
        return this.prepareTemptonConnectNote();
    };
  }

  changePage(p: number = null): void {
    let el: HTMLUListElement = document.getElementsByClassName('buttons-carousel')[0] as HTMLUListElement;
    if (p !== null) {
      this.currentPage = p;
      el.style.left = `-${el.offsetWidth * this.currentPage}px`;
    } else {
      let transition = el.style.transition;
      el.style.transition = 'none';
      el.style.left = `-${el.offsetWidth * this.currentPage}px`;
      setTimeout(() => {el.style.transition = transition;});
    }
  }

  closeTutorial(e = null): void {
    if (this.passTutorial && !this.sessionStorageService.checkTutorialPassed(this.tutorial)) this.sessionStorageService.setPassedTutorial(this.tutorial);
    if (!e || !e.target.closest('.dynamic-modal-content')) this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

  private preparePhotoTNTutorial(): TotorialPageModel[] {
    return [
      {
        buttonName: 'TAB',
        buttonClass: 'icon-tab',
        title: 'TAB Taste',
        desc: `Springen zwischen Eingabefeldern innerhalb
               von Pausen oder Arbeitszeiten`
      },
      {
        class: 'enter-button',
        buttonName: 'ENTER',
        buttonClass: 'icon-enter',
        title: 'ENTER Taste',
        desc: 'Eingaben bestätigen und zur nächsten PAUSE oder Arbeitszeit springen'
      },
      {
        buttonName: 'ESC',
        title: 'ESC Taste',
        desc: 'Eingabefelder verlassen, ohne Eingaben zu speichern'
      }
    ];
  }

  private prepareReportingTutorial(): TotorialPageModel[] {
    return [
      {
        title: 'Reporting',
        image: 'assets/images/tutorials/reporting.png',
        desc: `Das Reporting ermöglicht Ihnen eine Gesamtübersicht aller freigegebenen Arbeitszeiten Ihrer Tempton Mitarbeiter in einem vom Ihnen definierten Zeitraum.

               1. Wählen Sie einen Zeitraum aus
               2. Klicken Sie „Suche”
               3. Klicken Sie „Export” um eine Excel Liste zu erstellen`
      }
    ];
  }

  private prepareMitarbeiterTutorial(): TotorialPageModel[] {
    return [
      {
        title: 'Mitarbeiter',
        image: 'assets/images/tutorials/mitarbeiter.png',
        desc: `Hier finden Sie eine Übersicht Ihrer Tempton Mitarbeiter inkl. Einsatzende, Equal Pay und der Höchstüberlassungsdauer.

        Auslaufende Mitarbeiter Einsätze können Sie einfach nach Eingabe eines gewünschten Verlängerungsdatums online anfragen.`
      }
    ];
  }

  private prepareTemptonConnectNote(): TotorialPageModel[] {
    return [
      {
        titleHtml: 'Neuer Bereich <span class="bold">Personal</span>',
        image: 'assets/images/tutorials/tempton-connect.png',
        desc: `In diesem neuen Bereich können Sie selbst Kandidaten in unseren Datenbanken suchen und anfragen, Profile bewerten sowie Ihre Anfragehistorie verwalten.
        Einfach per Klick und überall dort, wo es Internet gibt !`
      }
    ];
  }

}

