import { Title                  } from '@angular/platform-browser';
import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginService, NotificationService, SessionStorageService } from '@shared/services';

import { messages    } from '@messages';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './login.component.html',
  host: { class: 'height-full am-flex-center am-flex-column gap30 bg-white'}
})
export class LoginComponent implements OnInit {
  title:     string;
  returnUrl: string;

  serverIsOnMaintenance:    boolean;
  serverMaintenanceMessage: string;

  readonly CUSTOMER: string = 'customer';
  readonly CLERK:    string = 'clerk';
  constructor(
    private router:                Router,
    private titleService:          Title,
    private route:                 ActivatedRoute,
    private loginService:          LoginService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.notificationService.close();
      if (this.loginService.accessTokenValue) return this.router.navigate([`/${this.sessionStorageService.activeAppValue}/list`]);

      let userRole = (paramMap.get('userRole')).toLowerCase();
      if ((!userRole || (userRole !== this.CLERK && userRole !== this.CUSTOMER)) && environment?.temptonNgxMsal?.msal?.client?.auth?.postLogoutRedirectUri?.includes('/#/auth/')) {
        let temp = environment.temptonNgxMsal.msal.client.auth.postLogoutRedirectUri.split('/#/auth/');
        if (temp.length === 2 && (temp[1] === this.CLERK || temp[1] === this.CUSTOMER)) userRole = temp[1];
      }

      if (userRole === this.CLERK) {
        this.title = 'Willkommen zur myTempton SB Verwaltung';
        this.titleService.setTitle('myTempton SB Verwaltun');
      } else if (userRole === this.CUSTOMER) {
        this.title = 'Willkommen zum myTempton Kundenportal';
        this.titleService.setTitle('myTempton Kundenportal');
      } else {
        this.title = 'Willkommen';
        this.titleService.setTitle('myTempton');
      }
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || `/${this.sessionStorageService.activeAppValue}/list`;

    if (!('Notification' in window) && !+localStorage.getItem('noNotificationsError')) {
      this.notificationService.optionalAlert(
        messages.note.browserNotSupportPushNotifications,
        messages.note.doNotShowAnymore,
        () => localStorage.setItem('noNotificationsError', `${+!+localStorage.getItem('noNotificationsError')}`)
      );
    }

    if (environment?.systemHealth?.healthState && environment?.systemHealth?.healthState !== 'healthy') {
      this.serverIsOnMaintenance    = true;
      this.serverMaintenanceMessage = environment.systemHealth.issue;
    }
  }

}
