// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wp-attachment-item {
  position: relative;
  border-radius: 6px;
}
.wp-attachment-item .image-wrapper {
  overflow: hidden;
}
.wp-attachment-item .image-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.wp-attachment-item .image-controls .legend-controls {
  position: relative;
  cursor: help;
}
.wp-attachment-item .image-controls .legend-controls:hover .legend-wrapper {
  display: flex;
}
.wp-attachment-item .image-controls .legend-controls .legend-wrapper {
  display: none;
  background-color: #FFFFFF;
  position: absolute;
  top: 100%;
  width: 250px;
  z-index: 1;
}
@media all and (-ms-high-contrast: none) {
  .wp-attachment-item .image-controls .legend-controls .legend-wrapper {
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.wp-attachment-item .image-controls .legend-controls .legend-wrapper .desc {
  line-height: 12px;
}
.wp-attachment-item .image-controls button {
  width: 36px;
}

img {
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}

#ocr-table {
  cursor: move;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
