import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { SidebarRouteExtended } from '@shared/models';
import { collapse } from 'app/animations';

@Component({
  selector: 'sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.sass'],
  animations: [ collapse ],
  host: { class: 'am-flex-justify am-flex-align text-s overflow-hidden relative', '[class.color-blue]': 'routeIsActive()' }
})
export class SidebarItemComponent {
  @Input() item:            SidebarRouteExtended;
  @Input() activeTab:       SidebarRouteExtended;
  @Input() sidebarMinified: boolean;
  @Input() getReportsCount: Function;
  @Input() changeDashboard: Function;
  @HostBinding('class') get classes() { return `${this.prepareStyles()} ${this.item.children?.length ? '' : this.getExtraClasses()}`; }
  @HostListener('click', ['$event']) clickout(event) { !this.item.children && this.changeDashboard(this.item); }
  routeIsActive(): boolean {
    return this.activeTab && this.activeTab.identifier === this.item.identifier;
  }

  private prepareStyles() {
    if (this.sidebarMinified) return 'am-flex-center';
    else return 'am-flex-justify';
  }

  private getExtraClasses(): string {
    return ` ${this.item.color ? `color-${this.item.color}` : ''} ${this.item.bottom ? this.item.border ? 'height-50' : `height-35` : 'height-50'} pl-m pr-m hover-active-blue`;
  }

}