import { ContractExtensionModel } from "@shared/models";
import { Customer               } from "@shared/factories";
import { FormatDatesService     } from "@shared/services";

export class ContractExtension {
  id:                      number;
  assignment_id:           number;
  contract_extension_till: Date;
  created_at:              Date;
  customer:                Customer;
  constructor(data: ContractExtensionModel) {
    this.id                      = data.id;
    this.assignment_id           = data.assignment_id;
    this.contract_extension_till = FormatDatesService.parseDate(data.contract_extension_till);
    this.created_at              = FormatDatesService.parseDate(data.created_at);
    this.customer                = new Customer(data.customer);
  }

}
