import { EmployeeBasicModel, EmployeeModel, ExternalEmployeeExtendedModel } from "@shared/models";
import { AssignmentBasic } from "@shared/factories";

export class EmployeeBasic {
  id:              number;
  first_name:      string;
  last_name:       string;
  personal_number: number;
  constructor(data: EmployeeBasicModel) {
    this.id              = data.id;
    this.first_name      = data.first_name;
    this.last_name       = data.last_name;
    this.personal_number = data.personal_number;
  }

  get name(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get nameReverse(): string {
    return `${this.last_name}, ${this.first_name}`;
  }

  toJSON(): EmployeeBasicModel {
    return {
      id:              this.id,
      first_name:      this.first_name,
      last_name:       this.last_name,
      personal_number: this.personal_number,
    };
  }
}

export class Employee extends EmployeeBasic {
  email:           string;
  phone_number:    string;
  userGroup:       string;
  constructor(data: EmployeeModel) {
    super(data);
    this.email        = data.email;
    this.phone_number = data.phone_number;
    this.userGroup    = data.user_group;
  }
  
  toJSON(): EmployeeModel {
    return Object.assign(super.toJSON(), {
      email:           this.email,
      phone_number:    this.phone_number,
      user_group:      this.userGroup
    });
  }
}

export class ExternalEmployee extends Employee { 
  constructor(data) {
    super(data);
  }
}

export class InternalEmployee extends Employee { 
  constructor(data) {
    super(data);
  }
}

export class Customer extends Employee { 
  constructor(data) {
    super(data);
  }
}

export class ExternalEmployeeExtended extends EmployeeBasic {
  external_location: number;
  assignments:       AssignmentBasic[];
  constructor(data: ExternalEmployeeExtendedModel) {
    super(data);
    this.external_location = data.external_location;
    this.assignments       = data.assignments.map(a => new AssignmentBasic(a));
  }
  
  toJSON(): EmployeeModel {
    return Object.assign(super.toJSON(), {
      email:             null,
      external_location: this.external_location,
      phone_number:      null
    });
  }
}

export class CustomerEmailListItem extends Customer {
  active: boolean;
  constructor(data: EmployeeModel | CustomerEmailListItem) {
    super(data);
    this.active = (data as CustomerEmailListItem).active || false;
  }
}
