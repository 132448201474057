import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, timer             } from 'rxjs';
import { map, switchMap, takeUntil, tap, repeat } from 'rxjs/operators';

import { SessionStorageService } from './session-storage.service';
import { QueryCollectorService } from './query-collector.service';

import { Release } from '@shared/factories';

import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})

export class ReleasesService {
  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  private get RELEASES_API(): string { return `${environment.apiUrl}api/portal/v3/release_notes` };

  constructor (
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get getReleases(): Observable<Release[]> {
    return timer(0).pipe(
      switchMap(() => this.requestReleases()),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  private requestReleases() {
    return this.http.get<any>(this.RELEASES_API + this.queryCollectorService.getReleasesQuery()).pipe(
      tap(res => {
        this.sessionStorageService.changeTotalCount(res.meta.total_count || res.meta.paging.total_count);
        this.sessionStorageService.changeTotalPages(res.meta.total_pages || res.meta.paging.total_pages);
      }),
      map(res => res.releases.map(item => new Release(item)))
    );
  }

}
