import { EnvironmentInjector, inject, Injectable, runInInjectionContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject, timer, Subject         } from 'rxjs';
import { map, switchMap, takeUntil, shareReplay, tap, repeat } from 'rxjs/operators';

import { PillModel, UserCustomerCompanyModel, UserLocationModel, UserModel, UserReleaseModel } from '@shared/models';
import { SessionStorageService       } from '@shared/services';
import { User                        } from '@shared/factories';

import { environment } from 'environments/environment';

const REFRESH_INTERVAL = 60 * 60 * 1000; //60 min
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private userRole: string;
  private currentUserSubject = new BehaviorSubject(null);
  currentUser = this.currentUserSubject.asObservable();

  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  constructor(
    private http:     HttpClient,
    private injector: EnvironmentInjector
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get getMe(): Observable<User> {
    const timer$ = timer(0, REFRESH_INTERVAL);
    return timer$.pipe(
      switchMap(() => this.requestUserData()),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ }),
      shareReplay(CACHE_SIZE)
    );
  }

  requestUserData(): Observable<User> {
    return this.http.get<UserModel>(`${environment.apiUrl}api/portal/v3/users/me`).pipe(
      map(res => new User(res)),
      tap(user => {
        this.setUser(user);
        this.setUserRole(user.isCustomer ? 'customer' : (user.isInternal ? 'internal' : undefined));
      })
    );
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  private setUser(user): void {
    this.currentUserSubject.next(user);
  }

  setUserRole(userRole: string): void {
    localStorage.setItem('userRole', userRole);
    this.userRole = userRole;
  }

  setPills(user: User): void {
    let pills;
    if (user.isInternal) pills = this.mapInternalPills(user.locations);
    if (user.isCustomer) pills = this.mapCustomerPills(user.customer_companies);

    runInInjectionContext(this.injector, () => { 
      inject(SessionStorageService).preparePills(pills); 
    });
  }

  private mapCustomerPills(customerCompanies: UserCustomerCompanyModel[]): PillModel[] {
    return customerCompanies.map(c => ({
      company_number: +c.company_number,
      location:        c.locations?.length ? c.locations[0].identifier : null,
      label:        `${c.name} (${c.company_number})`,
      invoice:       !!c.access_rights.find(r => r.resource_name === 'CustomerInvoice')
    }));
  }

  private mapInternalPills(locations: UserLocationModel[]): PillModel[] {
    return locations.map(l => ({
      location: l.identifier,
      label:   `${l.city} (${l.identifier})`
    }));
  }

  get isCustomer(): boolean {
    return this.userRole === 'customer';
  }

  get isInternal(): boolean {
    return this.userRole === 'internal';
  }

  get latestRelease(): UserReleaseModel {
    return this.currentUserSubject.value && this.currentUserSubject.value.latest_releases[this.currentUserSubject.value.latest_releases.length - 1];
  }

  updatePreferencesHandler(value: string, isTimeTracking): Observable<any> {
    if (isTimeTracking) return this.updateTimeTrackingPreferences(value);
    else return this.updateInvoicePreferences(value);
  }

  private updateTimeTrackingPreferences(value: string): Observable<any> {
    let data = { name: "ready_to_review", value };
    return this.updatePreferences(data, 'reports_notification');
  }

  private updateInvoicePreferences(value: string): Observable<any> {
    let data = { name: "new_invoices", value };
    return this.updatePreferences(data, 'invoices_notification');
  }

  private updatePreferences(data, categoryName): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/portal/v3/user_preferences/${categoryName}`, data).pipe(
      map(res => res)
    );
  }

  isAuthorizedForTemptonConnect(): Observable<boolean> {
    return this.http.get<{ is_authorized: boolean }>(`${environment.apiUrl}api/tc/v1/user/is_authorized`).pipe(
      map(res => res.is_authorized)
    );
  }

}
