import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NotificationService, ImageService } from '@shared/services';

@Component({
  selector:      'signature-box',
  templateUrl: './signature-box.component.html',
  host: { class: 'height-full width-360-max height-360-max b-grey p10 relative', '[class.hide]': '!loaded' }
})
export class SignatureBoxComponent implements OnChanges {
  @Input() itemUrl: string;
  @Input() base64:  string;
  @Input() signer:  string;
  @Output() base64Callback: EventEmitter<string> = new EventEmitter();

  @HostBinding('class') get classes() { return this.imageError ? 'am-flex-center width-150' : 'width-full' }

  image:      string;
  imageError: boolean;
  loaded:     boolean;
  constructor(
    private imageService:        ImageService,
    private notificationService: NotificationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.itemUrl?.firstChange || changes?.base64?.firstChange || changes?.itemUrl?.currentValue !== changes?.itemUrl?.previousValue) this.loadImage();
  }

  private loadImage(): void {
    this.loaded     = null;
    this.image      = null;
    this.imageError = null;

    this.notificationService.wait();
    if (this.base64) this.image = this.base64;
    else this.imageService.loadImage(this.itemUrl).subscribe(
      res => {
        this.image = res;
        this.base64Callback.emit(res);
      },
      err => this.notificationService.alert(err)
    );
  }

  imageLoaded(): void {
    this.loaded = true;
    this.notificationService.close();
  }

  loadImageError(): void {
    this.imageError = true;
    this.base64Callback.emit('');
    this.imageLoaded();
  }

}
