import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class RestorePasswordService {
  private TN_PASSWORDS_API = `${environment.apiUrl}api/v3/users/passwords`;
  constructor(private http: HttpClient) { }

  restorePassword (email) {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Locale': 'de'
      })
    };
    return this.http.post<any>(`${this.TN_PASSWORDS_API}/send_reset_instructions`, {user:{email: email.trim()}}, httpOptions);
  }

  restorePasswordByToken (email, password, password_confirmation) {
    return this.http.post<any>(`${this.TN_PASSWORDS_API}/reset_by_token`, { user: {
      reset_password_token: email.trim(),
      password: password.trim(),
      password_confirmation: password_confirmation.trim()
      }
    });
  }
}
