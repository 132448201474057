import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ActivationService, NotificationService, ValidationService } from '@shared/services';
import { messages } from '@messages';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['../../../styles/modules/auth-screen.sass']
})

export class ActivateUserComponent implements OnInit {
  userRole: string;

  stage: string = 'enter';
  stay = false;

  email: string;
  code: string;
  password: string;
  password_confirm: string;
  constructor (
    private activationService: ActivationService,
    private notificationService: NotificationService,
    private validationService: ValidationService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.userRole = localStorage.getItem('userRole');
  }

  getActivationCode(): void {
    if (this.email && this.validationService.checkIfEmail(this.email)) {
      this.notificationService.wait();
      this.activationService.getActivationCode(this.email).subscribe(
        res => {
          this.notificationService.success(messages.auth.success.codeWasSent);
          this.stage = 'code';
          this.notificationService.close();
        },
        error => this.notificationService.alert(error)
      );
    } else this.notificationService.alert(messages.auth.error.enterEmailToActivate);
  }

  goBack() {
    this.location.back();
  }

  activateAccount(): void {
    this.stay = false;
    if (this.code) {
      this.notificationService.wait();
      this.activationService.activateAccount(this.email, this.code).subscribe(
        () => {},
        error => {
          this.notificationService.alert(error);
          this.stay = true;
        },
        () => {
          if (!this.stay) {
            this.notificationService.success(messages.auth.success.activatedSuccessfully);
            this.stage = 'password';
          }
        }
      );
    }
  }

  setPassword(): void {
    if (this.code && this.email && this.password && this.password_confirm) {
      this.stay = false;
      if (this.password !== this.password_confirm) return this.notificationService.alert(messages.auth.error.passwordsNotMatch);
      this.notificationService.wait();
      this.activationService.setPassword(this.code, this.email, this.password, this.password_confirm).subscribe(
        () => { },
        error => {
          this.notificationService.alert(error);
          this.stay = true;
        },
        () => {
          if (!this.stay) {
            this.notificationService.success(
              messages.auth.success.canLogInNewPassword,
              () => this.router.navigate([`/authentication/${this.userRole ? this.userRole : 'login'}`])
            )
            this.stage = 'success';
          }
        }
      );
    } else this.notificationService.alert(messages.errors.basic.pleaseEnterValue);
  }

  trimWhiteSpace(e): void {
    this[e.target.name] = e.target.value.replace(/ /g, '');
  }
}
