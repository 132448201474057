import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, timer             } from 'rxjs';
import { map, switchMap, takeUntil, tap, repeat } from 'rxjs/operators';

import { WorkingPeriodsResponce   } from '@shared/models';
import { ActivityReportSimplified } from '@shared/factories';

import { SessionStorageService } from '../session-storage.service';
import { QueryCollectorService } from '../query-collector.service';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ActivityReportsService {
  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  private get WP_DASHBOARD_API(): string { return `${environment.apiUrl}api/portal/v3/working_periods/dashboard`         };
  private get WP_ARCHIVE_API():   string { return `${environment.apiUrl}api/portal/v3/working_periods/archive`           };
  private get WP_IMPORTS_API():   string { return `${environment.apiUrl}api/portal/v3/working_periods_exports/dashboard` };

  constructor (
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get workingPeriodsDashboard(): Observable<ActivityReportSimplified[]> {
    return timer(0).pipe(
      switchMap(() => this.requestWorkingPeriods('dashboard')),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  getWorkingPeriods(wpType: string): Observable<ActivityReportSimplified[]> {
    return this.requestWorkingPeriods(wpType);
  }

  getWorkingPeriodsArchive(): Observable<ActivityReportSimplified[]> {
    return this.requestWorkingPeriods('archive');
  }

  private requestWorkingPeriods(wpType: string) {
    return this.http.get<WorkingPeriodsResponce>(this.getWorkingPeriodsUrl(wpType)).pipe(
      tap(res => {
        this.sessionStorageService.changeTotalCount(res.meta.paging.total_count);
        this.sessionStorageService.changeTotalPages(res.meta.paging.total_pages);
      }),
      map(res => res.working_periods.map(item => new ActivityReportSimplified(item)))
    );
  }

  private getWorkingPeriodsUrl(wpType: string): string {
    if      (wpType === 'dashboard')  return this.WP_DASHBOARD_API + this.queryCollectorService.getActivityReportsQuery();
    else if (wpType === 'failed-erp') return this.WP_IMPORTS_API   + this.queryCollectorService.getFailedERPsQuery();
    else if (wpType === 'archive')    return this.WP_ARCHIVE_API   + this.queryCollectorService.getActivityReportsArchiveQuery();
  }

}
