import { Routes         } from '@angular/router';
import { LoginComponent } from '@shared/components/auth';

export const AuthenticationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':userRole',
        pathMatch: 'full',
        component: LoginComponent
      }
    ]
  }
];
