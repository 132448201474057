import { Injectable } from '@angular/core';
import { TableBodyCellEntryModel, TableListIconModel } from '@shared/models';

@Injectable()
export class HelperService {
  constructor () { }

  parceTableListIconsWeb(iconsList: TableListIconModel[]): TableBodyCellEntryModel[] {
    return iconsList.map(el => ({
      internalOnly:    el.internalOnly,
      customerOnly:    el.customerOnly,
      routerLink:      el.linkTree,
      routerLinkState: el.linkState,
      class: el.show ? 'tooltip-hover' : 'hide',
      children: [
        { class: `am-flex-center ${el.icon}` },
        { class: `web-tooltip text-s left short ${el.color}`, skip: !el.tooltipTexts?.length, children: el.tooltipTexts?.map(text => ({ class: 'tooltip-regular', value: text })) }
      ]
    }));
  }

  parceTableListIconsMobile(iconsList: TableListIconModel[]): TableBodyCellEntryModel[] {
    return iconsList.map(el => ({
      internalOnly: el.internalOnly,
      customerOnly: el.customerOnly,
      class: `${el.icon} ${el.show ? '' : 'hide'}`
    }));
  }

}
