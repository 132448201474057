// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .releases-table .title-cell {
  order: 1;
  min-width: 100px;
  flex: 0 1 250px;
}
::ng-deep .releases-table .application-cell {
  order: 2;
  min-width: 115px;
  flex: 0 0 115px;
}
::ng-deep .releases-table .version-cell {
  order: 3;
  min-width: 65px;
  flex: 0 0 65px;
}
::ng-deep .releases-table .released-cell {
  order: 4;
  min-width: 90px;
  flex: 0 0 90px;
}
::ng-deep .releases-table .notes-cell {
  order: 5;
  min-width: 100px;
  flex: 1 1 150px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
