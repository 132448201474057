import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilterModel, SidebarRouteExtended, TogglerModel } from '@shared/models';
import { NotificationService, SessionStorageService, UserService } from '@shared/services';

@Component({
  selector: 'content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.sass'],
  host: {'class': 'am-flex-column gap-auto'}
})
export class ContentHeaderComponent {
  @Input() headerTitle: string;
  @Input() activeTab:   SidebarRouteExtended;

  @Input() togglers: TogglerModel[];
  @Input() filters:  FilterModel[];
  @Input() button:   any;
  @Output() togglersChanged = new EventEmitter<any>();
  @Output() filtersChanged  = new EventEmitter<any>();
  @Output() buttonCallback  = new EventEmitter<any>();

  @Input() isInternal: boolean;
  @Input() isCustomer: boolean;

  @Input() entriesLength:          number;
  @Input() enablePrintExtra:       boolean;
  @Input() togglersAllowToDisable: boolean;

  @Input() disablePrint: boolean;
  @Input() disableExcel: boolean;
  @Output() print      = new EventEmitter<any>();
  @Output() reload     = new EventEmitter<any>();
  @Output() excel      = new EventEmitter<any>();
  @Output() printExtra = new EventEmitter<any>();
  constructor(
    private userService:           UserService,
    private notificationService:   NotificationService,
    public  sessionStorageService: SessionStorageService
  ) { }

  get togglersByUserRole(): TogglerModel[] {
    return this.filterByUserRole(this.togglers) as TogglerModel[];
  }

  get filtersByUserRole(): FilterModel[] {
    return this.filterByUserRole(this.filters) as FilterModel[];
  }

  private filterByUserRole(items: any): TogglerModel[] | FilterModel[] {
    return items?.filter(tog => !tog.isInternal && !tog.isCustomer || tog.isInternal && this.isInternal || tog.isCustomer && this.isCustomer)
                 .filter(tog => tog.beta ? this.checkBeta(tog.beta) : true);
  }

  private checkBeta(beta: string): boolean {
    let features = this.userService.currentUserValue.features;
    return features && features[beta] && features[beta].length;
  }

  get printAvailable(): boolean {
    return !!this.print.observed;
  }

  get excelAvailable(): boolean {
    return !!this.excel.observed;
  }

  get reloadAvailable(): boolean {
    return !!this.reload.observed;
  }

  reloadHandler(): void {
    this.notificationService.wait();
    this.reload.emit();
  }

}
