// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#auth-screen {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
#auth-screen.internal {
  background-image: url(/assets/images/tempton_sb_login_bg.jpg);
}
#auth-screen.customer {
  background-image: url(/assets/images/tempton_login.jpg);
}
#auth-screen .auth-box-wrapper,
#auth-screen .maintenance-block {
  max-width: 440px;
}
#auth-screen .auth-box-wrapper {
  border-radius: 6px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.0509803922);
  padding: 60px 40px;
  margin: 0 20px 15% 20px;
}
@media (max-width: 400px) {
  #auth-screen .auth-box-wrapper {
    margin-bottom: 20px;
  }
}
#auth-screen .auth-box-wrapper input:not([type=checkbox]) {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
