import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'toggler',
  templateUrl: 'custom-toggler.component.html',
  styleUrls: ['./custom-toggler.component.sass']
})
export class CustomTogglerComponent {
  @Input() currentValue: string;
  @Input() leftValue:    string;
  @Input() rightValue:   string;
  @Output() togglerCallback = new EventEmitter<any>();
  constructor() { }

  toggle() {
    let val;
    if      (this.currentValue === this.leftValue)  val = this.rightValue;
    else if (this.currentValue === this.rightValue) val = this.leftValue;
    this.togglerCallback.emit(val);
  }

}
