// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  ::ng-deep .time-frames-table label::before {
    display: none;
  }
}
::ng-deep .time-frames-table .table-header-wrapper div[class\$=-cell],
::ng-deep .time-frames-table .table-header-wrapper div[class*="-cell "] {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1280px) {
  ::ng-deep .time-frames-table .table-header-wrapper div[class\$=-cell].week-cell, ::ng-deep .time-frames-table .table-header-wrapper div[class\$=-cell].hours-cell, ::ng-deep .time-frames-table .table-header-wrapper div[class\$=-cell].pauses-cell,
  ::ng-deep .time-frames-table .table-header-wrapper div[class*="-cell "].week-cell,
  ::ng-deep .time-frames-table .table-header-wrapper div[class*="-cell "].hours-cell,
  ::ng-deep .time-frames-table .table-header-wrapper div[class*="-cell "].pauses-cell {
    display: none;
  }
}
::ng-deep .time-frames-table .checkbox-cell {
  order: 1;
  min-width: 20px;
  flex: 0 0 20px;
}
::ng-deep .time-frames-table .name-cell {
  order: 2;
  min-width: 100px;
  flex: 0 1 150px;
}
@media (max-width: 1280px) {
  ::ng-deep .time-frames-table .name-cell {
    min-width: 100px;
    flex: 1 0 100px;
  }
}
::ng-deep .time-frames-table .title-cell {
  order: 3;
  min-width: 100px;
  flex: 3 1 150px;
}
@media (max-width: 1280px) {
  ::ng-deep .time-frames-table .title-cell {
    flex: 3 1 100px;
  }
}
::ng-deep .time-frames-table .week-cell {
  order: 4;
  min-width: 90px;
  flex: 1 0 90px;
}
@media (min-width: 1280px) {
  ::ng-deep .time-frames-table .week-cell {
    max-width: 170px;
  }
}
@media (max-width: 1280px) {
  ::ng-deep .time-frames-table .week-cell {
    display: none;
  }
}
::ng-deep .time-frames-table .status-cell {
  order: 5;
  min-width: 70px;
  flex: 0 0 70px;
}
::ng-deep .time-frames-table .status-cell > * {
  margin: auto !important;
}
::ng-deep .time-frames-table .day-cell {
  order: 6;
  min-width: 75px;
  flex: 0 1 85px;
}
::ng-deep .time-frames-table .hours-cell {
  order: 7;
  min-width: 95px;
  flex: 0 1 120px;
}
@media (max-width: 1280px) {
  ::ng-deep .time-frames-table .hours-cell {
    display: none;
  }
}
::ng-deep .time-frames-table .pauses-cell {
  order: 8;
  min-width: 75px;
  flex: 0 1 115px;
}
@media (max-width: 1280px) {
  ::ng-deep .time-frames-table .pauses-cell {
    display: none;
  }
}
::ng-deep .time-frames-table .total-time-cell {
  order: 9;
  min-width: 80px;
  flex: 0 0 80px;
}
::ng-deep .time-frames-table .total-time-no-pause-cell {
  order: 10;
  min-width: 100px;
  flex: 0 1 100px;
}
::ng-deep .time-frames-table .table-body-wrapper .list-row .action-cell {
  order: 11;
  display: none;
}
@media (max-width: 768px) {
  ::ng-deep .time-frames-table .table-body-wrapper .list-row .action-cell {
    display: flex;
  }
}
::ng-deep .time-frames-table .table-body-wrapper .list-row .action-cell .icon-tick {
  color: #0099A8;
}
@media (max-width: 1000px) {
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row .action-cell {
    min-height: 40px;
  }
  ::ng-deep .time-frames-table.sidebar-open .icon-tick {
    display: flex;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-header-wrapper {
    display: none;
  }
}
@media (max-width: 1000px) {
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .xs-hide {
    display: none !important;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row {
    min-height: 0;
    padding: 0;
    margin: 10px 0;
    flex-direction: column;
    border: 1px solid #CED4DA;
    border-radius: 4px;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell],
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] {
    display: flex;
    flex-direction: row !important;
    flex: 0;
    width: 100%;
    margin: 0;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell]:not(.icons-cell):not(:last-child), ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell]:not(:last-child),
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "]:not(.icons-cell):not(:last-child),
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "]:not(:last-child) {
    border-bottom: 1px solid #CED4DA;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-label,
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-label {
    display: flex;
    min-height: 40px;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-label + span,
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-label + span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 130px);
    line-height: 20px;
    padding: 10px 0;
    word-break: break-all;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-icon,
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-button,
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-icon,
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-button {
    display: flex !important;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell].checkbox-cell,
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "].checkbox-cell {
    display: none;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class\$=-cell].action-cell.failed-erp,
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row div[class*="-cell "].action-cell.failed-erp {
    flex: auto;
  }
  ::ng-deep .time-frames-table.sidebar-open .table-body-wrapper .list-row .buttons-group {
    margin: 10px 10px 10px 0;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
