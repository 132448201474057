import { Pipe, PipeTransform } from '@angular/core';
import { FormatDatesService } from '@shared/services';

@Pipe({ name: 'payFormat' })
export class PayFormat implements PipeTransform {
  transform(value: number): string {
    return FormatDatesService.parseToPayFormat(value);
  }
}

@Pipe({ name: 'distanceFormat' })
export class DistanceFormat implements PipeTransform {
  transform(value: number): string {
    return FormatDatesService.parseToPayFormat(value, 1);
  }
}