import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { forkJoin, Observable } from 'rxjs';
import { map        } from 'rxjs/operators';

import { VacationRequestExtended } from '@shared/factories';
import { CustomerNotificationModel, CustomerNotificationsResponse, VacationRequestExtendedModel, VacationRequestModel } from '@shared/models';

import { NotificationService, UserService } from '@shared/services';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VacationRequestService {
  private get VR_IMPORTS_API(): string { return `${environment.apiUrl}api/portal/v3/vacation_requests_exports` };
  private get VR_API():         string { return `${environment.apiUrl}api/portal/v3/vacation_requests`         };

  constructor (
    private http:                HttpClient,
    private userService:         UserService,
    private notificationService: NotificationService,
  ) { }

  getVacationRequestById(id: number): Observable<VacationRequestExtended> {
    let calls = [ this.http.get<any>(`${this.VR_API}/${id}`).pipe(map(res => res.vacation_request)) ];
    if (this.userService.isInternal) calls.push(this.http.get<CustomerNotificationsResponse>(`${this.VR_API}/${id}/customers_notifications`).pipe(map(res => res.customers_notifications)));

    return forkJoin(calls).pipe(
      map((res: [VacationRequestExtendedModel, CustomerNotificationModel[]]) => new VacationRequestExtended(res[0] ,res[1]))
    );
  }

  approveVacationRequestByCustomer(id: number): Observable<any> {
    return this.adjustVacationRequest(id, 'customer', 'approved');
  }

  rejectVacationRequestByCustomer(id: number, reason: string, comment: string): Observable<any> {
    return this.adjustVacationRequest(id, 'customer', 'rejected', reason, comment);
  }

  approveVacationRequestByInternal(id: number, commentBox: string = null): Observable<any> {
    return this.adjustVacationRequest(id, 'internal', 'approved', null, null, commentBox);
  }

  rejectVacationRequestByInternal(id: number, reason: string, comment: string, commentBox: string = null): Observable<any> {
    return this.adjustVacationRequest(id, 'internal', 'rejected', reason, comment, commentBox);
  }

  deleteVacationRequestByInternal(id: number, commentBox: string = null): Observable<any> {
    return this.adjustVacationRequest(id, 'internal', 'rejected', '', 'Gelöscht', commentBox);
  }

  private adjustVacationRequest(id: number, role: string, action: string, reason: string = null, comment: string = null, commentBox: string = null): Observable<any> {
    this.notificationService.wait();
    let body = { vacation_request: { [`${role}_review`]: action }};
    if (reason)  body.vacation_request[`${role}_rejection_reason`] = reason;
    if (comment) body.vacation_request[`${role}_review_comment`]   = comment;
    if (commentBox) body.vacation_request.internal_comment         = commentBox;
    return this.http.put<any>(`${this.VR_API}/${id}/${role}_review`, body);
  }

  deleteVacationRequest(id: number): Observable<any> {
    return this.http.delete<any>(`${this.VR_API}/${id}`);
  }

  manuallyResolveWorkingPeriod(vacationId: number, comment: string = null): Observable<any> {
    let data = this.prepareInternalNote(comment);
    return this.http.put<{ vacation_request: VacationRequestModel }>(`${this.VR_IMPORTS_API}/${vacationId}/resolve_manually`, data);
  }

  internalCheck(vacationId: number, commentBox: string): Observable<any> {
    let body = this.prepareInternalNote(commentBox);
    return this.http.put<any>(`${this.VR_API}/${vacationId}/internal_precheck`, body);
  }

  submitVacationRequest(params): Observable<any> {
    return this.http.post<any>(this.VR_API, params);
  }

  updateComment(vacationId: number, commentBox: string): Observable<any> {
    let body = this.prepareInternalNote(commentBox);
    return this.http.put<any>(`${this.VR_API}/${vacationId}/internal_comment`, body);
  }

  private prepareInternalNote(internalNote: string): any {
    return { vacation_request: { internal_comment: internalNote }};
  }

}
