import { ToastModel } from "@shared/models";

export class Toast {
  name: string;
  type: string;
  message: string;
  autoClose: boolean;
  fade: boolean;
  constructor(data: ToastModel) {
    this.name = data.name;
    this.type = data.type || 'info';
    this.message = data.message;
    this.autoClose = data.autoClose || true;
    this.fade = false;
  }

}
