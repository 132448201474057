import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivityReportExtended, VacationRequestExtended } from '@shared/factories';
import { WorkingPeriodNote } from '@shared/models';

@Component({
  selector:      'notes-box',
  templateUrl: './notes-box.component.html',
  host: { class: 'am-flex-column gap15', '[class.display-none]': '!notesList.length' }
})
export class NotesBoxComponent implements OnChanges {
  @Input() entry:      ActivityReportExtended | VacationRequestExtended;
  @Input() isInternal: boolean;

  notesList:  WorkingPeriodNote[] = [];
  activeNote: number;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entry?.firstChange || changes?.entry?.currentValue?.id !== changes?.entry?.previousValue?.id) this.collectNotes();
  }

  private collectNotes() {
    this.notesList = [];
    switch (this.entry.constructor) {
      case ActivityReportExtended:
        return this.collectActivityReportNotes(this.entry as ActivityReportExtended);
      case VacationRequestExtended:
        return this.collectVacationRequestNotes(this.entry as VacationRequestExtended);
    }
  }

  private collectActivityReportNotes(entry: ActivityReportExtended): void {
    if (entry.customer_notes) {
      this.notesList.push({
        title:   entry.customer_notes.indexOf(':') > -1 ? entry.customer_notes.split(':')[0] : entry.customer_notes,
        comment: entry.customer_notes.indexOf(':') > -1 ? entry.customer_notes.split(':')[1] : 'Kein Kommentar angegeben.',
        type: 'reject'
      });
    }
    if (entry.external_employee_notes) {
      this.notesList.push({
        title: this.isInternal ? 'Mitteilung an die SB-Prüfung' : 'Mitteilung des Mitarbeiters',
        comment: entry.external_employee_notes,
        type: 'note'
      });
    }
    if (this.notesList.length) this.activeNote = 0;
  }

  private collectVacationRequestNotes(entry: VacationRequestExtended): void {
    // if (entry.reason) {
    //   this.notesList.push({
    //     title: 'Grund',
    //     comment: entry.reasonComment || entry.reason,
    //     type: 'note'
    //   });
    // }
    if (entry.customerRejectionReason) {
      this.notesList.push({
        title:   `KD: ${entry.customerRejectionReasonMapped}`,
        comment: entry.customerReviewComment || 'Kein Kommentar angegeben.',
        type: 'reject'
      });
    }
    if (entry.internalRejectionReason) {
      this.notesList.push({
        title:   `SB: ${entry.internalRejectionReasonMapped}`,
        comment: entry.internalReviewComment || 'Kein Kommentar angegeben.',
        type: 'reject'
      });
    }
    if (this.notesList.length) this.activeNote = 0;
  }

}
