import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppAccessService, UserService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard  {
  constructor(
    private router:           Router,
    private appAccessService: AppAccessService,
    private userService:      UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let app;
    if (state.url.includes('/invoices/')) app = 'invoices';
    else app = 'time-tracking';
    if (!this.userService.currentUserValue) this.router.navigate([`/init/${decodeURIComponent(state.url)}`]);
    else if (this.userService.isCustomer && !this.appAccessService.authorizedForRead(app)) this.router.navigate([`/`]);
    else return true;

    return false;
  }
}
