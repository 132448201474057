import { ActivityReportMileageMoneyDailyModel, StandaloneMileageMoneyDailyModel, ValidationError } from "@shared/models";
import { ActivityReportMileageMoneyDaily, StandaloneMileageMoneyDaily } from "@shared/factories";

export class MileageMoneyWorkGroup {
  workDays: (ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily)[];
  errors:    ValidationError[];

  perDayWorkGroup: boolean;
  perKmWorkGroup:  boolean;
  constructor(data: ActivityReportMileageMoneyDailyModel[] | StandaloneMileageMoneyDailyModel[]) {
    let totalSum    = data?.length && parseFloat(data[0].total_sum);
    let amountPerKm = data?.length && parseFloat(data[0].amount_per_km);

    this.perDayWorkGroup = data?.length &&  (!!totalSum || !!(!amountPerKm && !totalSum));
    this.perKmWorkGroup  = data?.length && !!(!totalSum &&     amountPerKm);
  }

  get activeWorkDays(): (ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily)[] {
    return this.workDays?.filter(m => !m.deleted) || [];
  }

  get totalKm():  number { return this.activeWorkDays?.reduce((sum: number, val: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily) => { sum = sum + val.totalAmountOfKm; return sum }, 0) || 0; }
  get totalSum(): number { return this.activeWorkDays?.reduce((sum: number, val: ActivityReportMileageMoneyDaily | StandaloneMileageMoneyDaily) => {
    let temp = this.perDayWorkGroup ? val.calculatedSum : val.amountPerKm * val.totalAmountOfKm;
    sum = sum + temp;
    return sum;
  }, 0) || 0; }

}

export class ActivityReportMileageMoneyWorkGroup extends MileageMoneyWorkGroup {
  workDays: ActivityReportMileageMoneyDaily[];
  constructor(work_days: ActivityReportMileageMoneyDailyModel[]) {
    super(work_days);
    this.workDays = work_days.length ? work_days.map(wd => new ActivityReportMileageMoneyDaily(wd)) : null;
  }
}

export class StandaloneMileageMoneyWorkGroup extends MileageMoneyWorkGroup {
  workDays: StandaloneMileageMoneyDaily[];
  constructor(work_days: StandaloneMileageMoneyDailyModel[]) {
    super(work_days);
    this.workDays = work_days.length ? work_days.map(wd => new StandaloneMileageMoneyDaily(wd)) : null;
  }
}
