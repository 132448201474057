import { EBSModel } from "@shared/models";

export class EBS {
  id: number;
  assignment_id: number;
  created_at: Date;
  confirmed_at?: Date;
  version: number;
  external_name: string;
  title: string;

  assignmentLocationStreet: string;
  assignmentLocationZipCode: string;
  assignmentLocationCity: string;

  aufadresse1: string;
  aufansprech1: string;
  anspr_telefon: string;
  aufarbs: string[];
  aufarbzeitwoche: string;
  aufberuf: string;
  aufbetrstaette: string;
  aufdeckelbetragakt: number;
  auffahrbetrag: string;
  aufgefahrarbeit: string;
  aufmuhrzeit: string;
  aufpostaet1: string;
  auftarifbranche: string;
  auftarifgruppe: string;
  auftarifmagruppe: string;
  auftarifvertrag: string;
  auftelefon1: string;
  auftextmarken4: string;
  aufueberlbeginn: string;
  aufuebernbetrag: string;
  aufueentfernung: string;
  aufuntersuchidx: string;
  aufvmabetrag: string;
  filtelefon1: string;
  internal_contact_persnr: number;
  kundname1: string;
  kundort: string;
  kundplz: string;
  kundstrasse: string;
  pel705preis: string;
  persname: string;
  persvorname: string;
  txt_sonstigeZulage: string;

  constructor(data: EBSModel | EBS) {
    this.id = data.id;
    this.assignment_id = data.assignment_id;
    this.created_at = this.parceDate(data.created_at);
    this.confirmed_at = this.parceDate(data.confirmed_at);
    this.version = data.version;
    this.external_name = `${data.persvorname} ${data.persname}`;
    this.title = data.title;

    // this.assignmentLocationStreet = data.assignmentLocationStreet || data.aufadresse1 && data.aufadresse1.split(', ')[0];
    // this.assignmentLocationZipCode = data.assignmentLocationZipCode || data.aufadresse1 && data.aufadresse1.split(', ')[1].split(' ')[0];
    // this.assignmentLocationCity = data.assignmentLocationCity || data.aufadresse1 && data.aufadresse1.split(', ')[1].split(' ')[1];

    this.aufadresse1 = data.aufadresse1;
    this.aufansprech1 = data.aufansprech1;
    this.anspr_telefon = data.anspr_telefon;
    this.aufarbs = this.parseStringToArray(data.aufarbs);
    this.aufarbzeitwoche = this.parseToPayFormat(data.aufarbzeitwoche, 1);
    this.aufberuf = data.aufberuf;
    this.aufbetrstaette = data.aufbetrstaette;
    this.aufdeckelbetragakt = data.aufdeckelbetragakt;
    this.auffahrbetrag = this.parseToPayFormat(data.auffahrbetrag);
    this.aufgefahrarbeit = data.aufgefahrarbeit;
    this.aufmuhrzeit = data.aufmuhrzeit;
    this.aufpostaet1 = data.aufpostaet1;
    this.auftarifbranche = data.auftarifbranche;
    this.auftarifgruppe = data.auftarifgruppe;
    this.auftarifmagruppe = data.auftarifmagruppe;
    this.auftarifvertrag = data.auftarifvertrag;
    this.auftelefon1 = data.auftelefon1;
    this.auftextmarken4 = data.auftextmarken4;
    this.aufueberlbeginn = data.aufueberlbeginn;
    this.aufuebernbetrag = this.parseToPayFormat(data.aufuebernbetrag);
    this.aufueentfernung = this.parseToPayFormat(data.aufueentfernung);
    this.aufuntersuchidx = data.aufuntersuchidx;
    this.aufvmabetrag = this.parseToPayFormat(data.aufvmabetrag);
    this.filtelefon1 = data.filtelefon1;
    this.internal_contact_persnr = data.internal_contact_persnr;
    this.kundname1 = data.kundname1;
    this.kundort = data.kundort;
    this.kundplz = data.kundplz;
    this.kundstrasse = data.kundstrasse;
    this.pel705preis = this.parseToPayFormat(data.pel705preis);
    this.persname = data.persname;
    this.persvorname = data.persvorname;
    this.txt_sonstigeZulage = this.parseToPayFormat(data.txt_sonstigeZulage);
  }

  get titleWithVersionNumber(): string {
    return this.title + ' v'+(this.version > 10 ? this.version : '0' + this.version);
  }

  private parseStringToArray(data): string[] {
    if (!data) return []; 
    else if (Array.isArray(data)) return data;
    else if (typeof data === 'string') return data.split(';');
  }

  private parseToPayFormat(val, to?): string {
    if (!val || !isNaN(val)) return this.parseNumberToString(+val, to);
    else if (typeof val === 'string') return this.parseNumberToString(parseFloat(val.split(",").join(".")), to);
  }

  private parseNumberToString(val, to: number = 2): string {
    return val.toFixed(to).replace('.', ',');
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

}
