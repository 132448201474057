// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entry-pill {
  height: 35px;
  min-width: 55px;
  border-radius: 18px;
  cursor: pointer;
}
.entry-pill.active {
  background-color: #0099A8;
  color: #FFFFFF;
}
.entry-pill.active:hover {
  opacity: 0.75;
}
.entry-pill.disabled {
  background-color: #F0F0F0;
  color: #BEBEBE;
}
.entry-pill.disabled.active {
  background-color: #7D8B92;
}
.entry-pill:not(.active):hover {
  background-color: #F0F0F0;
}

.entries-pills-popup-wrapper {
  z-index: 20;
}
.entries-pills-popup-wrapper .popup-overflow {
  background-color: rgba(0, 0, 0, 0.25);
}
.entries-pills-popup-wrapper .entries-pills-popup {
  max-width: calc(100dvw - 40px);
  max-height: calc(100dvh - 50px);
  border-radius: 8px;
  width: 600px;
  z-index: 21;
}
@media (max-width: 768px) {
  .entries-pills-popup-wrapper .entries-pills-popup {
    margin: 10px;
    max-height: calc(100dvh - 20px);
    max-width: calc(100dvw - 20px);
  }
}
.entries-pills-popup-wrapper .entries-pills-popup .popup-entries-list {
  overflow-y: auto;
  max-height: calc(100dvh - 220px);
}
.entries-pills-popup-wrapper .entries-pills-popup .popup-entries-list .disabled {
  color: #BEBEBE;
}
.entries-pills-popup-wrapper .entries-pills-popup .popup-entries-list .disabled input + label::before {
  border-color: #F0F0F0;
  background-color: #F0F0F0;
}
.entries-pills-popup-wrapper .entries-pills-popup .popup-entries-list .disabled input:checked + label::before {
  border-color: #7D8B92;
  background-color: #7D8B92;
}
.entries-pills-popup-wrapper .entries-pills-popup .entry-select input[type=checkbox] {
  width: 17px;
  height: 17px;
}
.entries-pills-popup-wrapper .entries-pills-popup input[type=text] {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
