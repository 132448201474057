import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, fromEvent } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ImageService {
  constructor (private http: HttpClient) { }

  loadImage(url): Observable<any> {
    return this.http.get<Blob>(url, {responseType: 'blob' as 'json'}).pipe(
      switchMap(response => {
        let reader = new FileReader();
        if (response) reader.readAsDataURL(response);
        return fromEvent(reader, 'load').pipe(map(() => reader.result));
      })
    )
  }
}
