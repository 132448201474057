import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent, ActivateUserComponent, RestorePasswordComponent } from '@shared/components/auth';
import { ActivationService, RestorePasswordService } from '@shared/services';
import { AuthenticationRoutes } from './authentication.routing';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AuthenticationRoutes),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LoginComponent
  ],
  declarations: [
    LoginComponent,
    ActivateUserComponent,
    RestorePasswordComponent
  ],
  providers: [
    ActivationService,
    RestorePasswordService
  ]
})
export class AuthenticationModule {}
