// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dynamic-modal-popup {
  position: fixed;
  z-index: 20;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
.dynamic-modal-popup .dynamic-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  max-width: 800px;
  max-height: calc(100% - 40px);
  padding: 15px;
}
.dynamic-modal-popup .dynamic-modal-content.small {
  width: 250px;
}
.dynamic-modal-popup .dynamic-modal-content label {
  display: flex;
}
.dynamic-modal-popup .dynamic-modal-content label span {
  flex: 1;
}
.dynamic-modal-popup .dynamic-modal-content h3 {
  font-size: 16px;
  margin-bottom: 15px;
}
.dynamic-modal-popup .dynamic-modal-content .custom-email-add {
  display: flex;
}
.dynamic-modal-popup .dynamic-modal-content .custom-email-add input {
  flex: 1;
  margin-right: 15px;
}
.dynamic-modal-popup .dynamic-modal-content .email-list {
  margin-top: 15px;
  overflow-y: auto;
}
@media (max-width: 450px) {
  .dynamic-modal-popup .dynamic-modal-content .email-list {
    font-size: 12px;
  }
}
.dynamic-modal-popup .dynamic-modal-content .email-list li {
  min-height: 20px;
  margin-bottom: 10px;
}
.dynamic-modal-popup .dynamic-modal-content .checkbox-wrapper {
  margin: 5px 0;
}
.dynamic-modal-popup .dynamic-modal-content .modal-btn-group {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.dynamic-modal-popup .dynamic-modal-content .modal-btn-group button:first-child {
  margin-right: 5px;
}
.dynamic-modal-popup .dynamic-modal-content .modal-btn-group button:last-child {
  margin-left: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
