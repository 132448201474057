import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { RestorePasswordService, NotificationService, ValidationService } from '@shared/services';
import { messages } from '@messages';

const UPDATE_PASSWORD_BY_TOKEN = 'go_to_update_password_by_token';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['../../../styles/modules/auth-screen.sass']
})

export class RestorePasswordComponent {
  userRole: string;

  stage: string = 'email';

  email: string;
  password: string;
  password_confirmation: string;
  constructor (
    private restorePasswordService: RestorePasswordService,
    private notificationService: NotificationService,
    private validationService: ValidationService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.userRole = localStorage.getItem('userRole');
  }

  goBack() {
    this.location.back();
  }

  restorePassword(): void {
    if (this.email && this.validationService.checkIfEmail(this.email)) {
      this.restorePasswordService.restorePassword(this.email).subscribe(
        res => {
          if (res.action === UPDATE_PASSWORD_BY_TOKEN) {
            this.notificationService.success(messages.auth.success.pinSentToSms);
            this.stage = 'token';
          } else {
            this.notificationService.success(
              messages.auth.success.sentValidationEmail,
              () => this.router.navigate([`/authentication/${this.userRole ? this.userRole : 'login'}`])  
            );
          }
        },
        error => this.notificationService.alert(error)
      );
    } else this.notificationService.alert(messages.auth.error.enterEmailToResetPassword);
  }

  restorePasswordByToken () {
    let stay = false;
    if (this.email && this.password && this.password_confirmation) {
      if (!this.validationService.checkIfEmail(this.email)) return this.notificationService.alert(messages.errors.validation.emailNotValid);
      if (this.password !== this.password_confirmation) return this.notificationService.alert(messages.auth.error.passwordsNotMatch);
      this.restorePasswordService.restorePasswordByToken(this.email, this.password, this.password_confirmation).subscribe(
        () => {},
        error => {
          this.notificationService.alert(error);
          stay = true;
        },
        () => {
          if (!stay) {
            this.notificationService.success(
              messages.auth.success.passwordСhanged,
              () => this.router.navigate([`/authentication/${this.userRole ? this.userRole : 'login'}`])
            );
          }
        }
      );
    } else this.notificationService.alert(messages.errors.basic.pleaseEnterValue);
  }
}
