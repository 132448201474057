import { SidebarRouteExtended } from '@shared/models';

export const InvoicesSidebarItems: SidebarRouteExtended[] = [
  {
    section: 'Übersicht',
    collapsed: true,
    children: [
      {
        identifier: 'dashboard',
        title: 'Rechnungen',
        dashboardTitle: 'Rechnungen',
        icon: 'icon-euro',
        path: 'list',
        counter: 'active',
        counterColor: 'blue',
        filter: 'dashboard',
        paginationNumber: 10,
        sortByDefault: ['invoice_date']
      }
    ]
  },
  {
    section: 'Archiv',
    collapsed: true,
    children: [
      {
        identifier: 'archive',
        title: 'Archivierte Rechnungen',
        dashboardTitle: 'Archivierte Rechnungen',
        icon: 'icon-archive',
        path: 'list',
        counter: 'archived',
        counterColor: 'green',
        filter: 'archive',
        paginationNumber: 10,
        sortByDefault: ['invoice_date']
      }
    ]
  },
  {
    section: 'Mein Konto',
    collapsed: true,
    children: [
      {
        identifier: 'logout',
        title: 'Logout',
        icon: 'icon-exit',
      }
    ]
  },
];
