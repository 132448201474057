// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-box-entry-with-tooltip {
  position: relative;
  cursor: pointer;
}
.history-box-entry-with-tooltip:hover .web-tooltip {
  display: flex;
}

.history-box-entry {
  width: 100%;
  font-size: 14px;
}
.history-box-entry.simplified {
  font-size: 12px;
  border: none;
}
.history-box-entry.simplified .icon-tick {
  color: #95C11F;
  font-size: 18px;
}
.history-box-entry.active {
  background-color: #F0F0F0;
}
.history-box-entry .icon-tick {
  font-weight: bold;
  font-size: 14px;
}
.history-box-entry.grey-tick .icon-tick {
  background-color: #7D8B92;
}
.history-box-entry:not(.simplified) {
  min-height: 40px;
}
.history-box-entry:not(.simplified) .icon-tick {
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  margin-right: 10px;
  margin-left: 20px;
  background-color: #95C11F;
}

.icon-arrow-down {
  height: 24px;
  width: 24px;
}
.icon-arrow-down.rotate::before {
  transform: rotate(180deg);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
