import { PauseModel, ValidationError, TechnicalError, PauseModelForSubmit } from '@shared/models';

export class DailyReportPauseBasic {
  id:    number;
  start: Date;
  end:   Date;
  constructor(pause: PauseModel) {
    this.id    = pause.id || null;
    this.start = this.parceDate(pause.start);
    this.end   = this.parceDate(pause.end);
  }

  parceDate(date): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  get duration(): number {
    return +this.end - +this.start;
  }
}

export class DailyReportPause extends DailyReportPauseBasic {
  corrected:     boolean;
  start_updated: Date;
  end_updated:   Date;

  is_new:        boolean;
  deleted:       boolean;

  errors:        ValidationError[];
  constructor( pause: PauseModel | DailyReportPause, update: PauseModel = null, create: boolean = false, remove: boolean = false ) {
    super(pause as PauseModel);
    this.corrected     = update && update.corrected || pause.corrected || false;
    this.start_updated = this.corrected && update ? this.parceDate(update.start) : (pause as DailyReportPause).start_updated ? this.parceDate((pause as DailyReportPause).start_updated) : null;
    this.end_updated   = this.corrected && update ? this.parceDate(update.end)   : (pause as DailyReportPause).end_updated   ? this.parceDate((pause as DailyReportPause).end_updated)   : null;

    this.is_new        = (pause as DailyReportPause).is_new  || create;
    this.deleted       = (pause as DailyReportPause).deleted || remove;

    this.errors        = [];
  }

  get pauseStart(): Date {
    return this.start_updated || this.start || new Date(1970, 0, 1, 0, 0);
  }

  get pauseEnd(): Date {
    return this.end_updated || this.end || new Date(1970, 0, 1, 0, 0);
  }

  get duration(): number {
    return +this.pauseEnd - +this.pauseStart;
  }

  get errorType(): string {
    return this.errors && this.errors.length ? this.errors.find(err => err instanceof TechnicalError) ? 'tech-error': 'legal-error' : '';
  }

  singleErrorType(err: ValidationError): string {
    return err instanceof TechnicalError ? 'tech': 'legal';
  }

  toJSON(): PauseModel {
    return {
      id:            this.id || null,
      start:         this.parceDateISO(this.start),
      end:           this.parceDateISO(this.end),

      corrected:     this.corrected,
      start_updated: this.parceDateISO(this.start_updated),
      end_updated:   this.parceDateISO(this.end_updated),

      is_new:        this.is_new,
      deleted:       this.deleted
    }
  }

  toSubmitJSON(): PauseModelForSubmit {
    return {
      id:        this.id || null,
      start:     this.start ? this.start.toISOString() : null,
      end:       this.end   ? this.end.toISOString()   : null,
      corrected: this.corrected
    }
  }

  private parceDateISO(date): string {
    return date && date instanceof Date ? date.toISOString() : null;
  }
}
