import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoggingService, SessionStorageService } from '@shared/services';
import { AuthService } from '@tempton/ngx-msal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy{
  constructor(
    private loggingService:        LoggingService,
    private sessionStorageService: SessionStorageService,
    /* NOTE AuthService must be constructed on redirects after logout to clear the session storage. */
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    window.addEventListener('unhandledrejection', this.loggingService.handlePromiseErrors);
    window.addEventListener('error', this.loggingService.handleWindowErrors);
    this.sessionStorageService.watchRoutes();
  }

  ngOnDestroy(): void {
    window.removeEventListener('unhandledrejection', this.loggingService.handlePromiseErrors);
    window.removeEventListener('error', this.loggingService.handleWindowErrors);
  }
}
