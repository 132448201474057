import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map        } from 'rxjs/operators';

import {
  ActivityReportMileageMoneyVersionModel,
  ActivityReportMileageMoneySubmitModel,
  StandaloneMileageMoneyModel,
  StandaloneMileageMoneySubmitModel,
  StandaloneMileageMoneyVersionModel,
  StandaloneMileageMoneyPrefilledModel
} from '@shared/models';

import {
  ActivityReportMileageMoneyExtended,
  ActivityReportMileageMoneyVersion,
  MileageMoneyPrefilledReport,
  StandaloneMileageMoney,
  StandaloneMileageMoneyVersion
} from '@shared/factories';

import { environment } from 'environments/environment';

class ActivityReportMileageMoneyVersionsResponseModel { mileage_money_report_versions: ActivityReportMileageMoneyVersionModel[]; }
class StandaloneMileageMoneyVersionsResponseModel     { versions:                      StandaloneMileageMoneyVersionModel[];     }
class StandaloneMileageMoneyResponseModel             { standalone_mileage_report:     StandaloneMileageMoneyModel;              }
class ActivityReportMileageMoneyReportSubmitModel     { mileage_money_report:          ActivityReportMileageMoneySubmitModel;    }
class StandaloneMileageMoneySubmitReportSubmitModel   { standalone_mileage_report:     StandaloneMileageMoneySubmitModel;        }
class StandaloneMileageMoneyPrefilledResponseModel    { standalone_mileage_report:     StandaloneMileageMoneyPrefilledModel;     }

@Injectable()
export class MileageMoneyService {
  private get TN_MM_API(): string { return `${environment.apiUrl}api/portal/v3/mileage_money_reports`      };
  private get SMM_API():   string { return `${environment.apiUrl}api/portal/v3/standalone_mileage_reports` };

  constructor (private http: HttpClient) { }

  getStandaloneMileageMoneyById(mmId: number): Observable<StandaloneMileageMoney> {
    return this.http.get<StandaloneMileageMoneyResponseModel>(`${this.SMM_API}/${mmId}`).pipe(
      map(res => new StandaloneMileageMoney(res.standalone_mileage_report))
    );
  }

  getWorkingPeriodMileageMoneyVersions(mileageMoneyId: number): Observable<ActivityReportMileageMoneyVersion[]> {
    return this.http.get<ActivityReportMileageMoneyVersionsResponseModel>(`${this.TN_MM_API}/${mileageMoneyId}/versions`).pipe(
      map(res => res.mileage_money_report_versions.map(mmr => new ActivityReportMileageMoneyVersion(mmr)).sort((a,b) => b.createdAt.getTime() - a.createdAt.getTime()))
    );
  }

  getStandaloneMileageMoneyVersions(mileageMoneyId: number): Observable<StandaloneMileageMoneyVersion[]> {
    return this.http.get<StandaloneMileageMoneyVersionsResponseModel>(`${this.SMM_API}/${mileageMoneyId}/versions`).pipe(
      map(res => res.versions.map(mmr => new StandaloneMileageMoneyVersion(mmr)).sort((a,b) => b.createdAt.getTime() - a.createdAt.getTime()))
    );
  }

  saveMileageMoneyVersion(mileageMoneyId: number, mileage: ActivityReportMileageMoneyExtended | StandaloneMileageMoney): Observable<any> {
    if (mileage instanceof ActivityReportMileageMoneyExtended) return this.saveActivityReportMileageMoneyVersion(mileageMoneyId, mileage);
    if (mileage instanceof StandaloneMileageMoney            ) return this.saveStandaloneMileageMoneyVersion(mileageMoneyId, mileage);
  }

  private saveActivityReportMileageMoneyVersion(mileageMoneyId: number, mileage: ActivityReportMileageMoneyExtended): Observable<any> {
    let body: ActivityReportMileageMoneyReportSubmitModel = {
      mileage_money_report: mileage.toSubmitJSON()
    };

    return this.http.put<ActivityReportMileageMoneyReportSubmitModel>(`${this.TN_MM_API}/${mileageMoneyId}`, body).pipe(
      map(res => res)
    );
  }

  private saveStandaloneMileageMoneyVersion(mileageMoneyId: number, mileage: StandaloneMileageMoney) {
    let body: StandaloneMileageMoneySubmitReportSubmitModel = {
      standalone_mileage_report: mileage.toSubmitJSON()
    };

    return this.http.put<ActivityReportMileageMoneyReportSubmitModel>(`${this.SMM_API}/${mileageMoneyId}`, body).pipe(
      map(res => res)
    );
  }

  approveMileageMoney(mileageMoneyId: number, data): Observable<any> {
    return this.updateMileageMoney(mileageMoneyId, 'approve', data);
  }

  rejectMileageMoney(mileageMoneyId: number, data): Observable<any> {
    return this.updateMileageMoney(mileageMoneyId, 'reject', data);
  }

  private updateMileageMoney(mileageMoneyId: number, action: string, data): Observable<any> {
    let body: StandaloneMileageMoneySubmitReportSubmitModel = {
      standalone_mileage_report: data
    };
   return this.http.put<StandaloneMileageMoneySubmitReportSubmitModel>(`${this.SMM_API}/${mileageMoneyId}/${action}`, body).pipe(
      map(res => res)
    );
  }

  loadPrefilledMileageData(mileageId: number): Observable<MileageMoneyPrefilledReport> {
    return this.http.get<StandaloneMileageMoneyPrefilledResponseModel>(`${this.SMM_API}/${mileageId}/fill_in`).pipe(
      map(res => res.standalone_mileage_report),
      map(mm => new MileageMoneyPrefilledReport(mm))
    );
  }

  updateComment(mileageId: number, comment: string): Observable<any> {
    let body = { standalone_mileage_report: {
      internal_note: comment
    }};
    return this.http.put<any>(`${this.SMM_API}/${mileageId}`, body);
  }

}
