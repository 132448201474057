import { SidebarRouteExtended, SidebarRoute } from '@shared/models';

export const TimeTrackingSidebarItems: SidebarRouteExtended[] = [
  {
    identifier: 'dashboard',
    title: 'Dashboard',
    dashboardTitle: 'Tätigkeitsnachweise',
    icon: 'icon-dashboard',
    path: 'list',
    counter: '',
    sortByDefault: ['portal_review_first', 'created_at'],
    dashboardCardsEnabled: true,
    paginationNumber: 10,
    isInternal: true
  },
  {
    identifier: 'dashboard',
    title: 'Zeiterfassung',
    dashboardTitle: 'Tätigkeitsnachweise',
    icon: 'icon-dashboard',
    sortByDefault: ['created_at'],
    path: 'list',
    counter: '',
    dashboardCardsEnabled: true,
    paginationNumber: 10,
    isCustomer: true
  },
  { 
    section: 'Übersicht',
    isInternal: true,
    children: [
      {
        identifier: 'approved',
        title: 'TN Kundenfreigabe vorhanden',
        dashboardTitle: 'Tätigkeitsnachweise Kundenfreigabe vorhanden',
        icon: 'icon-circle-tick',
        filter: '1',
        path: 'list',
        counter: 'approved',
        counterColor: 'green',
        sortByDefault: ['portal_review_first', 'created_at'],
        paginationNumber: 10,
        underline: true
      },
      {
        identifier: 'rejected',
        title: 'TN Klärungsbedarf',
        dashboardTitle: 'Tätigkeitsnachweise Klärungsbedarf',
        icon: 'icon-circle-cross',
        filter: '3',
        path: 'list',
        counter: 'rejected',
        counterColor: 'red',
        sortByDefault: ['portal_review_first', 'created_at'],
        paginationNumber: 10,
        underline: true
      },
      {
        identifier: 'open',
        title: 'Warten auf TN Kundenfreigabe',
        dashboardTitle: 'Warten auf TN Kundenfreigabe',
        icon: 'icon-circle-time',
        filter: '2',
        path: 'list',
        counter: 'open',
        counterColor: 'orange',
        sortByDefault: ['portal_review_first', 'created_at'],
        paginationNumber: 10,
        underline: true
      },
      {
        identifier: 'new-ar',
        title: 'Tätigkeitsnachweis erstellen',
        icon: 'icon-time-tracking-add',
        path: 'new-activity-report',
        underline: true
      },
      {
        identifier: 'failed-erp',
        title: 'Fehlerhafte Übertragungen',
        dashboardTitle: 'Fehlerhafte Übertragungen',
        icon: 'icon-warning',
        color: 'orange',
        path: 'failed-erp',
        counter: 'failed_export',
        counterColor: 'orange',
        sortByDefault: ['created_at'],
        paginationNumber: 10
      },
      {
        identifier: 'vr-list',
        title: 'Urlaubsanträge',
        dashboardTitle: 'Urlaubsanträge',
        icon: 'icon-holiday',
        path: 'vr-list',
        counter: 'vr-approved',
        counterColor: 'green',
        sortByDefault: ['internal_state_priority', 'created_at'],
        sortOrderByDefault: [true],
        paginationNumber: 10,
        betaFlag: 'vacation_requests'
      },
      {
        identifier: 'new-vr',
        title: 'Urlaubsantrag erstellen',
        dashboardTitle: 'Urlaubsantrag erstellen',
        icon: 'icon-holiday-add',
        path: 'vr-new',
        betaFlag: 'vacation_requests'
      },
      {
        identifier: 'companies-list',
        title: 'Kunden',
        dashboardTitle: 'Kunden',
        icon: 'icon-users',
        path: 'companies-list',
        sortByDefault: ['created_at'],
        sortOrderByDefault: [true],
        paginationNumber: 10
      },
      {
        identifier: 'mm-list',
        title: 'Kilometergeldberichte',
        dashboardTitle: 'Kilometergeldberichte',
        icon: 'icon-car',
        path: 'mm-list',
        counter: 'mm-open',
        counterColor: 'orange',
        sortByDefault: ['created_at'],
        paginationNumber: 10,
        betaFlag: 'standalone_mileage_reports'
      }
    ]
  },
  { 
    section: 'Übersicht',
    isCustomer: true,
    children: [
      {
        identifier: 'open',
        title: 'Offene TN',
        dashboardTitle: 'Offene Tätigkeitsnachweise',
        icon: 'icon-circle-time',
        filter: '2',
        sortByDefault: ['created_at'],
        path: 'list',
        counter: 'open',
        counterColor: 'orange',
        paginationNumber: 10,
        underline: true
      },
      {
        identifier: 'approved',
        title: 'Freigegebene TN',
        dashboardTitle: 'Freigegebene Tätigkeitsnachweise',
        icon: 'icon-circle-tick',
        filter: '1',
        path: 'list',
        sortByDefault: ['created_at'],
        counter: 'approved',
        counterColor: 'green',
        paginationNumber: 10,
        underline: true
      },
      {
        identifier: 'rejected',
        title: 'Abgelehnte TN',
        dashboardTitle: 'Abgelehnte Tätigkeitsnachweise',
        icon: 'icon-circle-cross',
        sortByDefault: ['created_at'],
        filter: '3',
        path: 'list',
        counter: 'rejected',
        counterColor: 'red',
        paginationNumber: 10,
        underline: true
      },
      {
        identifier: 'assignments-export',
        title: 'Reporting',
        dashboardTitle: 'Reporting',
        icon: 'icon-excel',
        path: 'assignments-export',
        sortByDefault: ['created_at'],
        paginationNumber: 10
      },
      {
        identifier: 'vr-list',
        title: 'Urlaubsanträge',
        dashboardTitle: 'Urlaubsanträge',
        icon: 'icon-holiday',
        path: 'vr-list',
        counter: 'vr-awaiting',
        counterColor: 'orange',
        sortByDefault: ['customer_state_priority', 'created_at'],
        sortOrderByDefault: [true],
        paginationNumber: 10,
        betaFlag: 'vacation_requests'
      },
    ]
  },
  { 
    section: 'Dokumente',
    isInternal: true,
    children: [
      {
        identifier: 'photo-doc',
        title: 'Fotodokument',
        dashboardTitle: "Fotodokument",
        icon: 'icon-picture',
        path: 'pd-list',
        sortByDefault: ['read_at', 'created_at'],
        sortOrderByDefault: [true],
        underline: true,
        counter: 'photo',
        counterColor: 'purple',
        counterLabel: () => 'Neue Fotodokument',
        paginationNumber: 10
      },
      {
        identifier: 'ebs-list',
        title: 'Mitarbeiter',
        dashboardTitle: 'Einsatzbegleitscheine',
        icon: 'icon-user',
        path: 'ebs-list',
        counter: 'ebs',
        counterColor: 'blue',
        counterLabel: (val) => val > 1 ? 'unbestätigte Einsatzbegleitscheine' : 'unbestätigter Einsatzbegleitschein',
        sortByDefault: ['ebs_id'],
        paginationNumber: 10
      }
    ]
  },
  { 
    section: 'Dokumente',
    isCustomer: true,
    children: [
      {
        identifier: 'ebs-list',
        title: 'Mitarbeiter',
        dashboardTitle: 'Mitarbeiter',
        icon: 'icon-user',
        counter: 'ebs',
        counterColor: 'blue',
        counterLabel: (val) => val === 1 ? 'Mitarbeiter Einsatz läuft aus' : 'Mitarbeiter Einsätze laufen aus',
        path: 'assignments-list',
        sortByDefault: ['ends_at'],
        sortOrderByDefault: [true],
        paginationNumber: 10
      }
    ]
  },
  // {
  //   identifier: 'ebs-list',
  //   dashboardTitle: 'Mitarbeiter',
  //   path: 'assignments-list',
  //   sortByDefault: ['ends_at'],
  //   banner: "/assets/images/customer_sidebar_banner.png",
  //   sortOrderByDefault: [true],
  //   paginationNumber: 10,
  //   isCustomer: true
  // },
  {
    section: 'Archiv',
    isInternal: true,
    children: [
      {
        identifier: 'archive',
        title: 'Archiv',
        dashboardTitle: 'Archiv',
        icon: 'icon-archive',
        path: 'search-archiv',
        sortByDefault: ['archived_at'],
        paginationNumber: 10, 
      }
    ]
  },
  {
    section: 'Archiv',
    isCustomer: true,
    children: [
      {
        identifier: 'archive',
        title: 'Archiv',
        dashboardTitle: 'Archiv',
        icon: 'icon-archive',
        path: 'search-archiv',
        sortByDefault: ['created_at'],
        paginationNumber: 10
      }
    ]
  },
  {
    section: 'Mein Konto',
    collapsed: true,
    children: [
      {
        identifier: 'logout',
        title: 'Logout',
        icon: 'icon-exit',
      }
    ]
  },
  {
    identifier: 'releases',
    title: 'Releases',
    dashboardTitle: 'Releases',
    icon: 'icon-releases',
    sortByDefault: ['released_at'],
    paginationNumber: 10,
    path: 'releases',
    bottom: true
  },
  {
    identifier: 'hilfe',
    icon: 'icon-help',
    title: 'Hilfe',
    externalPath: 'https://www.tempton.de/download/myTEMPTON_Zeiterfassung',
    isCustomer: true,
    bottom: true
  },
  {
    identifier: 'hilfe',
    icon: 'icon-help',
    title: 'Erste Hilfe & Anleitung',
    externalPath: 'https://akademie.tempton.net/sachbearbeiter-verwaltung',
    isInternal: true,
    bottom: true
  }
];
