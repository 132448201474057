import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, timer             } from 'rxjs';
import { map, switchMap, takeUntil, tap, repeat } from 'rxjs/operators';

import { SessionStorageService, QueryCollectorService          } from '@shared/services';
import { InvoicesExtendedModel, InvoicesModel, MetaPagingModel } from '@shared/models';
import { Invoice, InvoiceExtended                              } from '@shared/factories';

import { environment } from 'environments/environment';

interface InvoiceListResponseModel {
  customer_invoices: InvoicesModel[];
  meta:              MetaPagingModel;
}

interface InvoiceDetailsResponseModel {
  customer_invoice: InvoicesExtendedModel;
}

@Injectable({
  providedIn: 'root'
})

export class InvoicesService {
  private iType: string = 'dashboard';
  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  private get INVOICES_API():           string { return `${environment.apiUrl}api/portal/v3/customer_invoices` };
  private get INVOICES_DASHBOARD_API(): string { return `${this.INVOICES_API}/dashboard`                       };
  private get INVOICES_ARCHIVE_API():   string { return `${this.INVOICES_API}/archive`                         };

  constructor (
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(iType: string = null): void {
    this.iType = iType || this.iType;
    this.forceStop();
    this.start$.next();
  }

  forceStop(reset: boolean = null): void {
    if (reset) this.iType = 'dashboard';
    this.stop$.next();
  }

  get getInvoices(): Observable<Invoice[]> {
    return timer(0).pipe(
      switchMap(() => this.requestInvoices(this.iType)),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  private requestInvoices(iType: string): Observable<Invoice[]> {
    return this.http.get<InvoiceListResponseModel>(this.getInovicesUrl(iType)).pipe(
      tap(res => {
        this.sessionStorageService.changeTotalCount(res.meta.paging.total_count);
        this.sessionStorageService.changeTotalPages(res.meta.paging.total_pages);
      }),
      map(res => res.customer_invoices.map(item => new Invoice(item)))
    );
  }

  private getInovicesUrl(iType: string): string {
    if      (iType === 'dashboard') return this.INVOICES_DASHBOARD_API + this.queryCollectorService.getInvoicesQuery();
    else if (iType === 'archive')   return this.INVOICES_ARCHIVE_API   + this.queryCollectorService.getInvoicesArchiveQuery();
  }

  requestInvoiceById(invoiceId: number): Observable<InvoiceExtended> {
    return this.http.get<InvoiceDetailsResponseModel>(`${this.INVOICES_API}/${invoiceId}`).pipe(
      map(res => new InvoiceExtended(res.customer_invoice))
    );
  }

  archiveInvoice(invoiceId: number): Observable<InvoiceExtended> {
    return this.changeInvoiceArchiveStatus(invoiceId, true);
  }

  unArchiveInvoice(invoiceId: number): Observable<InvoiceExtended> {
    return this.changeInvoiceArchiveStatus(invoiceId, false);
  }

  private changeInvoiceArchiveStatus(invoiceId: number, archived): Observable<InvoiceExtended> {
    let body = { customer_invoice: { archived } };
    return this.http.put<InvoiceDetailsResponseModel>(`${this.INVOICES_API}/${invoiceId}`, body).pipe(
      map(res => new InvoiceExtended(res.customer_invoice))
    );
  }

  readInvoice(invoiceId: number): Observable<InvoiceExtended> {
    return this.http.put<InvoiceDetailsResponseModel>(`${this.INVOICES_API}/${invoiceId}/read`, {}).pipe(
      map(res => new InvoiceExtended(res.customer_invoice))
    );
  }

}
