import { DailyReportModel, ValidationError, TechnicalError, DailyError, DailyReportBasicModel, TimeError, StartTimeError, EndTimeError, LegalTimeError } from '@shared/models';
import { DailyReportPause, Holiday } from '@shared/factories';

export class DailyReport {
  id?:                     number;
  started_at:              Date;
  ended_at:                Date;
  activity:                string;

  original_time_frame_id?: number;
  activity_updated?:       string;
  edited?:                 boolean;
  deleted?:                boolean;
  destroyed?:              boolean;
  is_new?:                 boolean;

  started_at_updated?:     Date;
  ended_at_updated?:       Date;

  pauses:                  DailyReportPause[];
  errors:                  ValidationError[];
  holidays:                Holiday[];

  validMileageMoney:       boolean;
  placeholder?:            boolean;

  ocrPreselected?:         boolean;
  ocrStartInvalid?:        boolean;
  ocrEndInvalid?:          boolean;

  _destroy?:               string;
  constructor(daily: DailyReportBasicModel | DailyReportModel | DailyReport, update: DailyReportModel | DailyReport = null, isNew: boolean = false, remove: boolean = false, ocr: any = null) {
    this.id         = (daily as DailyReportModel | DailyReport).id || null;
    this.started_at = this.parceDate(daily.started_at);
    this.ended_at   = this.parceDate(daily.ended_at);
    this.activity   = (daily as DailyReportModel | DailyReport).activity !== undefined ? (daily as DailyReportModel | DailyReport).activity : null;

    this.original_time_frame_id = (daily as DailyReportModel | DailyReport).original_time_frame_id ? (daily as DailyReportModel | DailyReport).original_time_frame_id : null;

    this.started_at_updated = update && update.edited ? this.parceDate(update.started_at) : (daily as DailyReport).started_at_updated ? this.parceDate((daily as DailyReport).started_at_updated) : null;
    this.ended_at_updated   = update && update.edited ? this.parceDate(update.ended_at)   : (daily as DailyReport).ended_at_updated   ? this.parceDate((daily as DailyReport).ended_at_updated)   : null;
    this.activity_updated   = update && this.notNull(update.activity) ? update.activity :
                              this.notNull((daily as DailyReport).activity_updated) ? (daily as DailyReport).activity_updated :
                              this.notNull((daily as any).activity)                 ? (daily as any).activity                 : '';

    this.is_new    = isNew  || (daily as DailyReport).is_new || null;
    this.deleted   = remove || (update && (update as DailyReport).deleted) || (daily as DailyReport).deleted;
    this.destroyed = remove || (daily as DailyReport).destroyed;

    this.pauses = (daily as any).pauses?.length ? (daily as any).pauses.map(pause => {
      let updatePause, removePause;
      if (update) {
        updatePause = (update as any).pauses.find(item => item.id === pause.id);
        if (!updatePause) removePause = true;
      }
      return new DailyReportPause(pause, updatePause, null, removePause);
    }) : [];
    const newPauses: DailyReportPause[] = update ? (update as any).pauses.filter(u_p => !this.pauses.find(p => p.id === u_p.id)).map(u_p => new DailyReportPause(u_p, null, true)) : [];
    this.pauses = [...this.pauses, ...newPauses];

    this.holidays       = (daily as any).holidays?.length ? (daily as any).holidays.map(h => h instanceof Holiday ? h : new Holiday(h)) : [];
    this.placeholder    = daily.placeholder;

    this.ocrPreselected  = daily.ocrPreselected  || ocr?.preselected;
    this.ocrStartInvalid = daily.ocrStartInvalid || ocr?.invalid?.started_at;
    this.ocrEndInvalid   = daily.ocrEndInvalid   || ocr?.invalid?.ended_at;

    this.errors = [];
  }

  get startTime(): Date {
    return this.started_at_updated || this.started_at;
  }

  get endTime(): Date {
    return this.ended_at_updated || this.ended_at;
  }

  get totalDuration(): number {
    return +this.endTime - +this.startTime;
  }

  get pausesDuration(): number {
    return this.activePauses.reduce((sum: number, val: DailyReportPause) => +sum + +val.duration, 0);
  }

  get totalDurationExcludingPauses(): number {
    return +this.totalDuration - +this.pausesDuration;
  }

  get lastPauseId(): number {
    let counter = Math.max(...this.pauses.map(p => p.id));
    return counter > 0 ? counter : null;
  }

  get activePauses(): DailyReportPause[] {
    return this.pauses.filter(p => !p.deleted);
  }

  get errorType(): string {
    return this.errors && this.hoursErrors.length ? this.hoursErrors.find(err => err instanceof TechnicalError) ? 'tech-error': 'legal-error' : '';
  }

  get hoursErrors(): ValidationError[] {
    return this.errors.filter(e => (e instanceof TimeError) || (e instanceof LegalTimeError));
  }

  get startsAtErrors(): ValidationError[] {
    return this.errors.filter(e => e instanceof StartTimeError);
  }

  get endsAtErrors(): ValidationError[] {
    return this.errors.filter(e => e instanceof EndTimeError);
  }

  get dailyErrors(): ValidationError[] {
    return this.errors.filter(e => e instanceof DailyError);
  }

  singleErrorType(err): string {
    return err instanceof TechnicalError ? 'color-red': 'color-light-blue';
  }

  toJSON() {
    return {
      id:                     this.id                                     ? this.id                            : null,
      started_at:             this.parceDateISO(this.started_at),
      ended_at:               this.parceDateISO(this.ended_at),
      activity:               this.notNull(this.activity)                 ? this.activity                      : null,

      original_time_frame_id: this.original_time_frame_id                 ? this.original_time_frame_id        : null,
      started_at_updated:     this.parceDateISO(this.started_at_updated),
      ended_at_updated:       this.parceDateISO(this.ended_at_updated),
      activity_updated:       this.notNull(this.activity_updated)         ? this.activity_updated              : null,

      is_new:                 this.is_new                                 ? this.is_new                        : null,
      deleted:                this.notNull(this.deleted  )                ? this.deleted                       : null,
      destroyed:              this.notNull(this.destroyed)                ? this.destroyed                     : null,

      pauses:                 this.pauses?.length                         ? this.pauses.map(p => p.toJSON())   : [],
      holidays:               this.holidays?.length                       ? this.holidays.map(h => h.toJSON()) : [],

      ocrPreselected:         this.notNull(this.ocrPreselected)           ? this.ocrPreselected                : null,
      ocrStartInvalid:        this.notNull(this.ocrStartInvalid)          ? this.ocrStartInvalid               : null,
      ocrEndInvalid:          this.notNull(this.ocrEndInvalid)            ? this.ocrEndInvalid                 : null,
    }
  }

  toSubmitJSON() {
    return {
      activity:   this.notNull(this.activity_updated) ? this.activity_updated                    : this.notNull(this.activity) ? this.activity : null,
      started_at: this.started_at                     ? this.started_at.toISOString()            : null,
      ended_at:   this.ended_at                       ? this.ended_at.toISOString()              : null,
      pauses:     this.pauses?.length                 ? this.pauses.map((p) => p.toSubmitJSON()) : []
    };
  }

  toSubmitFullJSON() {
    return Object.assign(this.toSubmitJSON(), {
      id:                     this.id                     ? this.id                     : null,
      original_time_frame_id: this.original_time_frame_id ? this.original_time_frame_id : null,
      edited:                 this.notNull(this.edited)   ? this.edited                 : false,
      _destroy:               this.notNull(this._destroy) ? this._destroy               : false
    });
  }

  private parceDate(date): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  private parceDateISO(date): string {
    return date && date instanceof Date ? date.toISOString() : null;
  }

  private notNull(value: unknown): boolean {
    return value !== undefined && value !== null;
  }

}
