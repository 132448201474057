import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { forkJoin, of, Observable } from 'rxjs';
import { take, map, tap, catchError, filter, switchMap } from 'rxjs/operators';
import { AuthService } from '@tempton/ngx-msal';
import { User } from '@shared/factories';

import {
  BannersService,
  SessionStorageService,
  NotificationService,
  UserService,
  HolidaysService,
  DocumentTypesService,
  WebsocketService,
} from '@shared/services';
import { Weeks } from '@shared/factories';

import { IE_OPERATIONAL_TIME_NUMBER } from '../../shared/variables'
import { environment } from 'environments/environment';

@Component({
  templateUrl: './full.component.html',
  styleUrls:  ['./full.component.sass']
})
export class FullComponent implements OnInit {
  initialLoad: boolean;
  initialLoadApp: any = {};
  bannersIssue: any;
  constructor(
    private http:                  HttpClient,
    private route:                 ActivatedRoute,
    private router:                Router,
    private bannersService:        BannersService,
    private holidaysService:       HolidaysService,
    private documentTypesService:  DocumentTypesService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    private websocketService:      WebsocketService,
    private authService:           AuthService,
    private userService:           UserService
  ) { }

  ngOnInit(): void {
    this.notificationService.wait();
    this.loadBuildHash();
    if (environment?.systemHealth?.healthState && environment?.systemHealth?.healthState !== 'healthy') this.showMaintananceBlocker(environment.systemHealth.issue);
    else {
      this.userService.getMe.pipe(
        take(1),
        tap((user:User) => {
          if (user.isCustomer && (!user.customer_companies || !user.customer_companies.length)) {
            this.notificationService.alert('Hoppla, da ist ein Fehler aufgetreten.', () => this.authService.signOut$());
          } else {
            if (user.features.websocket_counters) this.websocketService.connect();
            else setTimeout(() => this.websocketService.closeWebSocket(true), 30000);
          }
        }),
        switchMap(() => this.route.queryParams.pipe(take(1)))
      ).subscribe(
        params => {
          if      (this.router.url.includes('/init/')) this.initHandler(params);
          else if (this.router.url.includes('/auth/')) this.router.navigateByUrl('/');
        },
        err => {
          this.notificationService.alert(err, () => this.authService.signOut$());
          setTimeout(() => this.authService.signOut$(), 5*1000);
        }
      );

      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        if (this.router.url.includes('/time-tracking/') && !this.sessionStorageService.isTimeTracking) this.sessionStorageService.changeActiveApp('time-tracking');
        if (this.router.url.includes('/invoices/')      && !this.sessionStorageService.isInvoices)     this.sessionStorageService.changeActiveApp('invoices');
        this.prepareInitLoad().pipe(take(1)).subscribe(
          (res: any) => this.initialLoad = true,
          err => this.notificationService.alert(err),
          () => { if (this.bannersIssue) console.error('ERROR: Banners Loading Failed! ', this.bannersIssue); }
        );
      });
    }
  }

  private showMaintananceBlocker(message: string): void {
    this.notificationService.alertBlocker({
      title: 'System Update',
      message
    });
  }

  private initHandler(params: Params): void {
    let url: any = this.router.url.split('/init/')[1].split('/');
    this.sessionStorageService.setActiveApp(url[0]);
    this.sessionStorageService.prepareTutorialsPassedData();

    if (this.userService.isCustomer) this.prepareActiveCompany(params);

    let { path, query }: any = this.mapRoute(url);
    if (query?.queryParams?.company_number) delete query.queryParams.company_number;

    this.router.navigate([path], query);
  }

  private prepareInitLoad(): Observable<any> {
    let calls: Observable<any>[] = [];
    if (this.initialLoadApp[this.sessionStorageService.activeAppValue]) calls.push(of({}));
    else if (this.sessionStorageService.isTimeTracking) calls.push(...this.prepareTimeTrackingInitLoad());
    else if (this.sessionStorageService.isInvoices)     calls.push(...this.prepareInvoicesInitLoad());
    return forkJoin(calls).pipe(tap(() => this.initialLoadApp[this.sessionStorageService.activeAppValue] = true));
  }

  private prepareTimeTrackingInitLoad(): Observable<any>[] {
    let calls = [];

    let startOfOperationTime = Weeks.getStartOfWeek(new Date().getTime() - IE_OPERATIONAL_TIME_NUMBER);
    calls.push(this.holidaysService.loadHolidays(startOfOperationTime, Weeks.getEndOfWeek(new Date())).pipe(
      take(1),
      tap(res => this.holidaysService.setHolidays(res))
    ));

    if (this.userService.isCustomer) calls.push(
      this.bannersService.banners.pipe(
        take(1),
        map(banners => this.sessionStorageService.changeBanners(banners)),
        catchError(err => {
          this.bannersIssue = err;
          return err;
        })
      )
    );

    return calls;
  }

  private prepareInvoicesInitLoad(): Observable<any>[] {
    let calls = [];
    calls.push(this.documentTypesService.documentTypes.pipe(take(1)));
    return calls;
  }

  private prepareActiveCompany(params: Params): void {
    let companyNumber, validCompany;
    let paramNumber     = parseInt(params['company_number']);
    let activeCompanies = this.sessionStorageService.activePills;
    let pills           = this.sessionStorageService.pillsValue;

    if (paramNumber)    validCompany  = pills.find(cc => cc.company_number === paramNumber);

    if (validCompany)   companyNumber = paramNumber;
    if (!companyNumber) companyNumber = activeCompanies.length ? activeCompanies[0]?.company_number : null;
    if (!companyNumber) companyNumber = pills[0].company_number;

    let pill = pills.find(cc => cc.company_number === companyNumber);
    this.sessionStorageService.setActiveCompany(pill);
  }

  private loadBuildHash(): void {
    this.http.get('./build-info.json').subscribe(
      (res: any) => this.sessionStorageService.buildHash = res.git_revision,
      err => console.log('No build hash found. ', err)
    );
  }

  private mapRoute(url) {
    let query = {};
    url = decodeURIComponent(url.join('/')).split(/[;\?]/); 
    if (url[1]) {
      let queryStrings = url[1].split('&');
      let params = {};
      for (let i = 0; i < queryStrings.length; i++) {
        let temp = queryStrings[i].split('=');
        params[temp[0]] = temp[1];
      }

      if (url.indexOf('?')) query = { queryParams: params };
      else if (url.indexOf(';')) query = params;
    }

    return { path: `/${url[0]}`, query };
  }

}
