// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 769px) {
  ::ng-deep .assignments-table .list-row {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
::ng-deep .assignments-table .list-row.collapsed .icon-arrow-down {
  transform: rotate(180deg);
}
::ng-deep .assignments-table .list-row.collapsed .assignment-details-box {
  height: 60px;
}
::ng-deep .assignments-table .assignment-details-box {
  height: 0;
  overflow: hidden;
  transition: height 200ms linear;
}
::ng-deep .assignments-table .arrow-cell {
  min-width: 30px;
  flex: 0 0 30px;
  transition: transform 200ms linear;
}
::ng-deep .assignments-table .name-cell {
  min-width: 200px;
  flex: 0 0 200px;
}
@media (max-width: 1280px) {
  ::ng-deep .assignments-table .name-cell {
    min-width: 150px;
    flex: 0 0 150px;
  }
}
::ng-deep .assignments-table .title-cell {
  min-width: 250px;
  flex: 1 1 250px;
}
@media (max-width: 1280px) {
  ::ng-deep .assignments-table .title-cell {
    min-width: 150px;
  }
}
::ng-deep .assignments-table .status-cell {
  min-width: 55px;
  flex: 0 0 55px;
}
::ng-deep .assignments-table .date-cell {
  min-width: 100px;
  flex: 0 0 100px;
}
@media (max-width: 1280px) {
  ::ng-deep .assignments-table .date-cell {
    min-width: 90px;
    flex: 0 0 90px;
  }
}
::ng-deep .assignments-table .date-cell .assignment-end-date-box {
  position: relative;
}
@media (min-width: 769px) {
  ::ng-deep .assignments-table .date-cell .assignment-end-date-box:hover .web-tooltip {
    display: block;
  }
}
::ng-deep .assignments-table .equal-pay-cell {
  min-width: 80px;
  flex: 0 0 80px;
}
::ng-deep .assignments-table .max-rental-period-cell {
  min-width: 110px;
  flex: 0 0 110px;
  display: flex;
  align-items: center;
}
::ng-deep .assignments-table .max-rental-period-cell .table-header-cell-title {
  word-break: break-word !important;
}
::ng-deep .assignments-table .action-cell {
  min-width: 100px;
  flex: 0 0 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
