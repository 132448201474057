import { Component } from '@angular/core';
import { Router    } from '@angular/router';

@Component({
  selector:  'app-blank-layout',
  templateUrl: './blank.component.html'
})
export class BlankComponent {
  constructor(private router: Router) {}

  ngOnInit() {
    if (this.router.url === '/') this.router.navigate(['/auth/login']);
  }

}
