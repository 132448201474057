import { Component, Input, EventEmitter, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import { Params     } from '@angular/router';
import { take, tap  } from 'rxjs/operators';

import {
  WorkingPeriodService,
  NotificationService,
  ActivityReportsCountersService,
  SessionStorageService,
  FailedErpService,
  HelperService,
  WebsocketService
} from '@shared/services';
import { TableHeaderEntryModel, TableBodyEntryModel, TableBodyCellNestedEntryModel, TableListIconModel } from '@shared/models';
import { ActivityReportSimplified } from '@shared/factories';

import { messages } from '@messages';

@Component({
  selector:     'activity-reports-table',
  templateUrl:  '../../table-entries.component.html',
  styleUrls: ['./activity-reports-table.component.sass'],
})
export class ActivityReportsTableComponent {
  @Input() rows:             ActivityReportSimplified[];
  @Input() paginationId:     string;

  @Input() dashboardOnly:    boolean;
  @Input() approvedOnly:     boolean;

  @Input() isInternal:       boolean;
  @Input() isCustomer:       boolean;

  @Input() extraInfoSort:    string;
  @Output() headerCallback = new EventEmitter<void>();
  constructor(
    private activityReportsCountersService: ActivityReportsCountersService,
    private workingPeriodService:           WorkingPeriodService,
    private failedErpService:               FailedErpService,
    private helperService:                  HelperService,
    private websocketService:               WebsocketService,
    private sessionStorageService:          SessionStorageService,
    private notificationService:            NotificationService
  ) { }

  headerCallbackHandler(res): void {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    return 'activity-reports-table';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Mitarbeiter', class: 'name-cell',          sort_by: ['external_name']                                                                                                              },
      { title: 'Pers. Nr.',   class: 'pers-num-cell',      sort_by: ['personal_number'],           internalOnly: true                                                                              },
      { title: 'Eingang',     class: 'date-cell',          sort_by: ['portal_review_first',
                                                                     'created_at'],                internalOnly: true, pageOnly: ['dashboard']                                                     },
      { title: 'Eingang',     class: 'date-cell',          sort_by: ['archived_at'],               internalOnly: true, pageOnly: ['failed-erp']                                                    },

      { title: 'Erstellung',  class: 'date-cell',          sort_by: ['created_at'],                internalOnly: true, pageOnly: ['archive']                                                       },
      { title: 'Überprüfung', class: 'realease-date-cell', sort_by: ['archived_at'],               internalOnly: true, pageOnly: ['archive']                                                       },

      { title: 'Eingang',     class: 'date-cell',          sort_by: ['created_at'],                customerOnly: true, pageOnly: ['dashboard', 'archive']                                          },
      { title: 'Tempton',     class: 'realease-date-cell', sort_by: ['archived_at'],               customerOnly: true, pageOnly: ['archive', this.dashboardOnly || this.approvedOnly ? 'dashboard' : 'skip'] },

      { title: 'Einsatz',     class: 'title-cell',         sort_by: ['assignment_title']                                                                                                           },
      { title: 'Zeitraum',    class: 'week-cell',          sort_by: ['start_date'],                                                                                                                },
      { title: 'Zusatzinfo',  class: 'am-flex-center icons-cell relative',                                             pageOnly: ['dashboard', 'archive'],  tooltipHtml: this.getExtraInfoTooltip(),
        sortOptions: [
          { class: 'am-flex-center font-small-icon icon-holiday', sort_by: ['holiday',              'created_at']                        },
          { class: 'am-flex-center font-small-icon icon-tick',    sort_by: ['checked_time',         'created_at'], skip: this.isCustomer },
          { class: 'am-flex-center font-small-icon icon-camera',  sort_by: ['attachment',           'created_at'], active: true          },
          { class: 'am-flex-center font-small-icon icon-car',     sort_by: ['mileage_money_report', 'created_at'], skip: this.isCustomer }
        ]
      },
      { title: 'Status',      class: 'status-cell',        sort_by: ['status'],                    internalOnly: true, pageOnly: [this.dashboardOnly ? 'dashboard' : 'skip']                       },
      { title: 'Status',      class: 'status-cell',        sort_by: ['export_state'],              internalOnly: true, pageOnly: ['failed-erp']                                                    },
      { title: 'Status',      class: 'status-cell',        sort_by: ['for_customer_approval_first',
                                                                     'end_date'],                  customerOnly: true, pageOnly: [this.dashboardOnly ? 'dashboard' : 'skip']                       },
      { title: 'Aktion',      class: 'action-cell',                                                                    pageOnly: ['dashboard', 'archive']                                          },
      { title: 'Aktion',      class: 'action-cell failed-erp',                                                         pageOnly: ['failed-erp']                                                    }
    ].filter(h => h.pageOnly ? h.pageOnly.find(p => p === this.paginationId) : true);
  }

  private getExtraInfoTooltip(): string {
    return `
      <div class="web-tooltip white medium bottom left-sm">
        <span class="tooltip-title">Folgende Parameter sind in dieser Spalte sichtbar:</span>
        <ul class="tooltip-list">
          ${this.isInternal ? '<li><span class="icon-tick"></span><span>Intern geprüft</span></li>' : ''}
          <li><span class="icon-camera"></span><span>Foto Tätigkeitsnachweis</span></li>
          ${this.isInternal ? '<li><span class="icon-car"></span><span>Kilometergeld</span></li>' : ''}
          <li><span class="icon-holiday"></span><span>Feiertag(e)</span></li>
        </ul>
      </div>
    `;
  }

  private collectSplitReports(list: ActivityReportSimplified[], wp: ActivityReportSimplified, index: number): string {
    let borders = '', sideBorders = 'bl-grey';
    let topBorder    = 'btl-radius  bt-grey';
    let bottomBorder = 'bbl-radius';

    if (list[index+1]) borders += this.connectedReport(wp, list[index+1]) ? `${sideBorders} ${topBorder} `    : '';
    if (list[index-1]) borders += this.connectedReport(wp, list[index-1]) ? `${sideBorders} ${bottomBorder} ` : '';
    if (list[index+2] && this.connectedReport(list[index+1], list[index+2])) borders += 'bb-none-important'
    return borders;
  }

  private connectedReport(a: ActivityReportSimplified, b: ActivityReportSimplified): boolean {
    return a.split_child_id  && b.id === a.split_child_id ||
           a.split_parent_id && b.id === a.split_parent_id;
  }

  prepareTableBody(): TableBodyEntryModel[] {
    return this.rows.map((wp: ActivityReportSimplified, index): TableBodyEntryModel => ({
      read_only: this.isInternal && !wp.belongsToInternalLocations,
      extra_styles: this.collectSplitReports(this.rows, wp, index),
      cells: [
        { xs_label: 'Mitarbeiter', class: 'name-cell',     value: wp.external_employee.nameReverse                                                                                                                },
        { xs_label: 'Pers. Nr.',   class: 'pers-num-cell', value: wp.external_employee.personal_number,                    internalOnly: true                                                                     },
        { xs_label: 'Eingang',     class: 'date-cell',     value: formatDate(this.editedByCustomer(wp) || wp.created_at , 'dd.MM.yyyy', 'de'), pageOnly: ['dashboard', this.isCustomer ? 'archive' : 'skip'],
          children: [{
            skip: !this.editedByCustomerClass(wp),
            class: `web-tooltip bottom short ${this.isCustomer ? this.editedByCustomerClass(wp) : 'white'}`,
            children: [{
              class: "tooltip-title",
              value: this.editedByCustomerText(wp)
            }]
          }],
          valueClass: `${this.isCustomer ? 'color-' : ''}${this.editedByCustomerClass(wp)}`,
        },
        { xs_label: 'Eingang',      class: `date-cell`,          value: formatDate(wp.approved_at, 'dd.MM.yyyy', 'de'),                         pageOnly: ['failed-erp']                                          },
        { xs_label: 'Erstellung',   class: 'date-cell',          value: formatDate(wp.created_at,  'dd.MM.yyyy', 'de'),     internalOnly: true, pageOnly: ['archive']                                             },
        { xs_label: 'Überprüfung',  class: 'realease-date-cell', value: this.closedByInternal(wp) || '-',                   internalOnly: true, pageOnly: ['archive']                                             },
        { xs_label: 'Tempton',      class: 'realease-date-cell', value: this.closedByInternal(wp) || '-',                   customerOnly: true, pageOnly: ['archive', this.dashboardOnly || this.approvedOnly ? 'dashboard' : 'skip'],
          children: [{
            skip: !this.closedByInternal(wp),
            class: `web-tooltip bottom short ${wp.approved_at ? 'green' : wp.archived_at ? 'red' : 'white'}`,
            children: [{
              class: "tooltip-title",
              value: `Von Tempton ${wp.approved_at ? 'überprüft' : wp.archived_at ? 'archiviert' : ''}`
            }]
          }],
          valueClass: this.closedByInternalClass(wp)
        },
        { xs_label: 'Einsatz',      class: 'title-cell',          value: wp.assignment.title                                                                                                                      },
        { xs_label: 'Zeitraum',     class: 'week-cell',           value: `${wp.calendar_week}\n${wp.date}`                                                                                                        },
        { xs_label: null,           class: 'icons-cell  xs-hide', children: [{ class: 'am-flex-center font-icon gap5', children: this.helperService.parceTableListIconsWeb(this.getIcons(wp)) }], pageOnly: ['dashboard', 'archive'] },
        { xs_label: null,           class: 'status-cell xs-hide', children: this.helperService.parceTableListIconsWeb(this.getStatus(wp)),      pageOnly: [this.showStatus() ? '' : 'skip'], skip: !this.showStatus()                },
        { xs_label: '',             class: 'action-cell', xs_icons: this.getIconsRowMobile(wp),                                                 pageOnly: ['dashboard', 'archive'],
          buttons: [{
            class: 'action-cell-button',
            label: this.getActionButtonName(wp),
            routerLink: ['/time-tracking/wp-details', wp.id],
            routerLinkState: this.getFastNavigationData()
          }]
        },
        { xs_label: '',              class: 'action-cell failed-erp', xs_icons: this.getIconsRowMobile(wp),                                     pageOnly: ['failed-erp'],
          buttons: [
            { class: 'blue-thin-border custom-action-cell-button',
              label: 'Ansehen',
              routerLink: ['/time-tracking/wp-details', wp.id],
              routerLinkState: this.getFastNavigationData()
            },
            { class: 'action-cell-button',
              skip: wp.export_state !== 'failed_export' || !wp.belongsToInternalLocations,
              label: 'Erledigen',
              click: () => this.manuallyResolve(wp)
            }
          ]
        }
      ].filter(c => c.pageOnly && c.pageOnly.filter(p => p).length ? c.pageOnly.filter(p => p).find(p => p === this.paginationId) : true)
    }));
  }

  private getIcons(ar: ActivityReportSimplified): TableListIconModel[] {
    return [
      { show: !!ar.checked_time_at,         icon: 'icon-tick',    color: 'white',         tooltipTexts: ['Intern geprüft'],        internalOnly: true },
      { show: !!ar.holidays?.length,        icon: 'icon-holiday', color: 'orange-border', tooltipTexts: ar.holidays                                   },
      { show: !!ar.with_attachment,         icon: 'icon-camera',  color: 'white',         tooltipTexts: ['Foto Tätigkeitsnachweis']                   },
      { show: !!ar.mileage_money_report_id, icon: 'icon-car',     color: 'white',         tooltipTexts: ['Kilometergeld'],         internalOnly: true,
        linkTree: ['/time-tracking/wp-details', ar.id, 'wp-km', ar.mileage_money_report_id],
        linkState: this.getFastNavigationData()
      }
    ];
  }

  private getIconsRowMobile(ar: ActivityReportSimplified): TableBodyCellNestedEntryModel[] {
    let icons: any = this.getIcons(ar);
    icons.splice(0, 0, { show: true, icon: `status-icon ${this.getStatusDotColor(ar)}` });
    return this.helperService.parceTableListIconsMobile(icons);
  }

  private showStatus(): boolean {
    if      (this.dashboardOnly)                                    return true;
    else if (this.isInternal && this.paginationId === 'failed-erp') return true;
    return false;
  }

  private getStatus(ar: ActivityReportSimplified): TableListIconModel[] {
    return [{ show: true, icon: `status-icon ${this.getStatusDotColor(ar)}`, color: this.getStatusDotColor(ar), tooltipTexts: [this.getStatusText(ar)].filter(t => t) }];
  }

  private getStatusDotColor(wp: ActivityReportSimplified): string {
    if      (this.isCustomer && this.dashboardOnly)                 return this.getCustomerStatusDotColor(wp);
    else if (this.isInternal && this.paginationId === 'failed-erp') return this.getFailedERPDotColor(wp);
    else if (this.isInternal && this.dashboardOnly)                 return this.getInternalStatusDotColor(wp);
    return 'grey';
  }

  private getCustomerStatusDotColor(wp: ActivityReportSimplified): string {
    if (+wp.status !== 2) return 'grey';
    const time: number = +new Date() - +wp.end_date;
    if (time <= 2 * 24 * 60 * 60 * 1000 ) return 'orange';
    else return 'red';
  }

  private getFailedERPDotColor(wp: ActivityReportSimplified): string {
    switch (wp.export_state) {
      case 'ready_to_export':
        return 'grey';
      case 'successful_export':
        return 'green';
      case 'failed_export':
        return 'red';
      case 'resolved_manually':
        return 'blue';
      default:
        return 'grey';
    }
  }

  private getInternalStatusDotColor(wp: ActivityReportSimplified): string {
    switch (+wp.status) {
      case 1:
        return 'green';
      case 2:
        return 'orange';
      case 3:
        return 'red';
      default:
       return 'grey';
    }
  }

  private getStatusText(wp: ActivityReportSimplified): string {
    if      (this.isInternal && this.paginationId === 'failed-erp') return this.getFailedERPDotText(wp);
    else if (this.isInternal && this.dashboardOnly)                 return this.getInternalStatusText(wp);
    // else if (this.isCustomer && this.dashboardOnly)                 return this.getCustomerStatusText(wp);
    return null;
  }

  private getCustomerStatusText(wp: ActivityReportSimplified): string {
    switch (+wp.status) {
      case 1:
        return 'Freigabe vorhanden';
      case 2:
        return 'Freigabe erwartet';
      case 3:
        return 'Freigabe abgelehnt';
      default:
       return null;
    }
  }

  private getInternalStatusText(wp: ActivityReportSimplified): string {
    switch (+wp.status) {
      case 1:
        return 'Tätigkeitsnachweise Kundenfreigabe vorhanden';
      case 2:
        return 'Warten auf TN Kundenfreigabe';
      case 3:
        return 'Tätigkeitsnachweise Klärungsbedarf';
      default:
       return null;
    }
  }

  private getFailedERPDotText(wp: ActivityReportSimplified): string {
    switch (wp.export_state) {
      case 'ready_to_export':
        return 'Übertragung ausstehend';
      case 'successful_export':
        return 'Übertragung erfolgreich';
      case 'failed_export':
        return wp.export_comment;
      case 'resolved_manually':
        return 'Manuell erledigt';
      default:
        return 'Übertragung ausstehend';
    }
  }

  private getFastNavigationData(): Params {
    return ({
      data: this.rows.map(r => ({
        id: r.id,
        page: this.sessionStorageService.pageNumberValue
      }))
    });
  }

  private editedByCustomer(wp: ActivityReportSimplified): Date {
    return wp.reviewed_by_customer_id && wp.customer_reviewed_at;
  }

  private editedByCustomerClass(wp: ActivityReportSimplified): string {
    if (this.editedByCustomer(wp)) {
      if      (this.isInternal)         return 'bold';
      else if (wp.customer_released_at) return 'green';
      else if (wp.customer_rejected_at) return 'red';
    } else if (this.isCustomer && (!wp.with_attachment && !wp.approved_at && !wp.archived_at)) {
      if (+wp.status === 1 && !wp.customer_rejected_at) return 'green';
      else if (wp.customer_rejected_at)                 return 'red';
    }
    return null;
  }

  private editedByCustomerText(wp: ActivityReportSimplified): string {
    let string = `Von ${this.isInternal ? 'Kunden' : 'Ihnen'}`;
    if (this.editedByCustomer(wp)) {
      if      (wp.customer_released_at) string += ' freigegeben';
      else if (wp.customer_rejected_at) string += ' abgelehnt';
    } else if (this.isCustomer && (!wp.with_attachment && !wp.approved_at && !wp.archived_at)) {
      if (+wp.status === 1 && !wp.customer_rejected_at) string += ' freigegeben';
      else if (wp.customer_rejected_at)                 string += ' abgelehnt';
    }
    return string;
  }

  private closedByInternal(wp: ActivityReportSimplified): string {
    return wp.approved_at || wp.archived_at ? formatDate(wp.approved_at || wp.archived_at, 'dd.MM.yyyy', 'de') : null;
  }

  private closedByInternalClass(wp: ActivityReportSimplified): string {
    if (this.isInternal || !this.closedByInternal(wp)) return null;
    else if (wp.approved_at) return 'color-green';
    else if (wp.archived_at) return 'color-red';
  }

  private getActionButtonName(wp: ActivityReportSimplified): string {
    return (this.isCustomer && +wp.status === 2 && !wp.archived_at && !wp.approved_at) ||
           (this.isInternal && wp.belongsToInternalLocations && +wp.status !== 2 && !wp.archived_at && !wp.approved_at) ? 'Bearbeiten' : 'Ansehen';
  }

  manuallyResolve(wp: ActivityReportSimplified): void {
    this.notificationService.wait();
    this.workingPeriodService.manuallyResolveWorkingPeriod(wp.id).pipe(
      take(1),
      tap(() => this.sessionStorageService.failedResolved = 'ar')
    ).subscribe(
      res => this.reloadCounters(),
      error => this.notificationService.alert(error.message || error)
    );
  }

  private reloadCounters(): void {
    if (!this.websocketService.wsOnline()) {
      this.activityReportsCountersService.reloadCounters();
      this.failedErpService.forceReload();
    }
  }

}
