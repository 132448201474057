import { formatDate     } from '@angular/common';
import { TimeFrameModel } from "@shared/models";
import { DailyReportPauseBasic, ExternalEmployee, Weeks } from '@shared/factories';

export class TimeFrame {
  id:                           number;
  created_at:                   Date;

  assignment_title:             string;

  started_at:                   Date;
  ended_at:                     Date;

  pauses:                       DailyReportPauseBasic[];

  working_period_id:            number;

  working_period_status:        number;
  working_period_export_status: number;

  working_period_start_date:    Date;
  working_period_end_date:      Date;

  working_period_approved_at:   Date;

  calendar_week:                string;
  date:                         string;

  external_employee:            ExternalEmployee;

  original:                     boolean;
  active?:                      boolean;
  constructor(data: TimeFrameModel) {
    this.id                           = data.id;
    this.created_at                   = this.parceDate(data.created_at);

    this.assignment_title             = data.assignment_title;

    this.started_at                   = this.parceDate(data.started_at);
    this.ended_at                     = this.parceDate(data.ended_at);

    this.pauses                       = data.pauses.map(p => new DailyReportPauseBasic(p));

    this.working_period_id            = data.working_period_id;

    this.working_period_status        = data.working_period_status;
    this.working_period_export_status = data.working_period_export_status;

    this.working_period_start_date    = this.parceDate(data.working_period_start_date);
    this.working_period_end_date      = this.parceDate(data.working_period_end_date);

    this.working_period_approved_at   = this.parceDate(data.working_period_approved_at);    

    this.calendar_week                = `KW ${Weeks.getWeekNumber(this.working_period_start_date) > 52 ? '01': Weeks.getWeekNumber(this.working_period_start_date)}`;
    this.date                         = `${formatDate(this.working_period_start_date, 'dd.MM.yyyy','de')} - ${formatDate(this.working_period_end_date, 'dd.MM.yyyy', 'de')}`;

    this.external_employee            = new ExternalEmployee(data.external_employee)
    this.original                     = data.original;
  }

  private parceDate(date: Date | string | number): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  get totalDuration(): number {
    return +this.ended_at - +this.started_at;
  }

  get pausesDuration(): number {
    return this.pauses.reduce((sum: number, val: DailyReportPauseBasic) => +sum + +val.duration, 0);
  }

  get totalDurationExcludingPauses(): number {
    return +this.totalDuration - +this.pausesDuration;
  }

}
