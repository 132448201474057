// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tutorial-wrapper {
  z-index: 50;
}
#tutorial-wrapper .tutorial-popup-wrapper {
  max-width: 800px;
  max-height: 90%;
  border-radius: 4px;
  background-color: white;
  width: calc(100% - 40px);
}
@media (max-height: 750px) {
  #tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup {
    gap: 10px;
  }
  #tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .button-wrapper {
    gap: 10px;
  }
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel {
  flex: 1 0 auto;
  left: 0;
  transition: left 250ms ease-in-out;
  max-height: calc(100% - 160px);
}
@media (max-width: 450px) {
  #tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel {
    max-height: calc(100% - 90px);
  }
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper {
  width: calc(100% - 60px);
  flex: 1 0 auto;
  list-style: none;
  margin: 0 30px;
}
@media (max-width: 450px) {
  #tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper {
    max-height: calc(100dvh - 240px);
  }
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper .button {
  height: 100px;
  width: 200px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 200px 10px rgba(0, 0, 0, 0.5), 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
  margin: 130px;
  position: relative;
}
@media (max-width: 768px) {
  #tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper .button {
    margin: 80px;
  }
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper .button.enter-button {
  z-index: -1;
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper .button.enter-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: white;
  right: 0;
  top: -90px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
  z-index: -1;
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper .button.enter-button::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 105px;
  height: 50px;
  background-color: #FFFFFF;
  z-index: -1;
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper img {
  max-height: 300px;
  max-width: 100%;
  flex: 1 0 auto;
}
@media (max-width: 450px) {
  #tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper img {
    max-height: 33vh;
  }
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper .button-desc {
  white-space: pre-line;
  flex: 1 0 auto;
  width: calc(100dvw - 40px);
  max-height: calc(100% - 240px);
  max-width: 760px;
}
@media (max-width: 450px) {
  #tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .buttons-carousel .button-wrapper .button-desc {
    max-width: 300px;
    max-height: 100%;
  }
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0099A8;
}
#tutorial-wrapper .tutorial-popup-wrapper .tutorial-popup .dot:not(.active) {
  opacity: 0.1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
