import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class ActivationService {
  private TN_ACTIVATION_API = `${environment.apiUrl}api/v3/users/activations`;
  constructor(private http: HttpClient) { }

  getActivationCode (email) {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Locale': 'de'
      })
    };
    return this.http.post<any>(`${this.TN_ACTIVATION_API}/send_code`, { user: { email: email.trim() } }, httpOptions);
  }

  activateAccount (email, activation_code) {
    return this.http.post<any>(`${this.TN_ACTIVATION_API}/activate`, {
      user: {
        email: email.trim(),
        activation_code: activation_code.trim()
      }
    });
  }

  setPassword (activation_code, email, password, password_confirmation) {
    return this.http.post<any>(`${this.TN_ACTIVATION_API}/set_password`, {
      user: {
        email: email.trim(),
        activation_code: activation_code.trim(),
        password: password.trim(),
        password_confirmation: password_confirmation.trim()
      }
    });
  }

}
