import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService, UserService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(
    private router:                Router,
    private userService:           UserService,
    private sessionStorageService: SessionStorageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    if ((route.data as any).customerOnly && this.userService.isInternal) return false;
    if ((route.data as any).internalOnly && this.userService.isCustomer) return false;
    if ((route.data as any).beta) {
      let features = this.userService.currentUserValue.features;
      if (!features || !features[route.data.beta] || !features[route.data.beta].length) {
        this.router.navigate(['/']);
        this.sessionStorageService.changeAndResetActiveTab('dashboard');
        return false;
      }
    }
    return true;
  }
}
