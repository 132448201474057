import { EBSListItemModel } from "@shared/models";

export class EBSListItem {
  id:           number;
  version:      number;
  confirmed?:   boolean;
  confirmed_at: Date;
  archived_at:  Date;
  created_at:   Date;
  filtelefon1:  number;
  constructor(data: EBSListItemModel) {
    this.id           = data.id;
    this.version      = data.version;
    this.confirmed_at = this.parceDate(data.confirmed_at);
    this.archived_at  = this.parceDate(data.archived_at);
    this.created_at   = this.parceDate(data.created_at);
    this.filtelefon1  = +data.filtelefon1;
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): EBSListItemModel {
    return {
      id:           this.id,
      version:      this.version,
      confirmed_at: this.confirmed_at ? this.confirmed_at.toISOString() : null,
      archived_at:  this.archived_at  ? this.archived_at.toISOString()  : null,
      created_at:   this.created_at   ? this.created_at.toISOString()   : null,
      filtelefon1:  this.filtelefon1 + ''
    }
  }

}
