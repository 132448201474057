import { Injectable } from '@angular/core';
import { CustomerCompanyRightsModel, PillModel } from '@shared/models';

import { UserService } from './auth/user.service';
import { SessionStorageService } from './session-storage.service';

@Injectable()
export class AppAccessService {
  constructor (
    private userService:           UserService,
    private sessionStorageService: SessionStorageService,
  ) { }

  private getActiveAppPermission(appName: string): string {
    let companies: any = this.getActiveCompanyRights();;
    if (!companies) return null;

    let rights;
    if (appName === 'time-tracking') rights = companies.find(r => r.resource_name === 'Default');
    else if (appName === 'invoices') rights = companies.find(r => r.resource_name === 'CustomerInvoice');
    return rights && rights.permission;
  }

  authorizedForRead(app: string, perm: string = null): boolean {
    let permission = this.getActiveAppPermission(app) || perm;
    return this.authorizedForManage(app, permission) || permission === 'read';
  }

  authorizedForManage(app: string, perm: string = null): boolean {
    let permission = this.getActiveAppPermission(app) || perm;
    return permission === 'manage';
  }

  private getActiveCompanyRights(): CustomerCompanyRightsModel[] {
    let user = this.userService.currentUserValue;

    if (user) {
      let company: PillModel = this.sessionStorageService.getActivePill;
      if (!company) company  = this.sessionStorageService.pillsValue[0];
      let rights = user.customer_companies.find(c => c.company_number === company.company_number) || user.customer_companies[0];
      return rights && rights.access_rights;
    }
    return null;
  }

  authorizedForInternal(app: string): boolean {
    return !!this.userService.currentUserValue.appLevelRoles.find(role => role === app);
  }

}
