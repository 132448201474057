import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, timer             } from 'rxjs';
import { map, switchMap, takeUntil, tap, repeat } from 'rxjs/operators';

import { QueryCollectorService, SessionStorageService          } from '@shared/services';
import { MileageReportSimplified, StandaloneMileageMoney       } from '@shared/factories';
import { MileageReportsArchiveResponce, MileageReportsResponce } from '@shared/models';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MileageMoneyListService {
  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  private get MM_DASHBOARD_API(): string { return `${environment.apiUrl}api/portal/v3/standalone_mileage_reports/dashboard` };
  private get MM_ARCHIVE_API():   string { return `${environment.apiUrl}api/portal/v3/combined_mileage_reports/archive`     };

  constructor (
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get mileageReports(): Observable<StandaloneMileageMoney[]> {
    return timer(0).pipe(
      switchMap(() => this.requestMileageReports('dashboard').pipe(
        map((res: MileageReportsResponce) => res?.standalone_mileage_reports?.map(item => new StandaloneMileageMoney(item)) || [])
      )),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  getMileageReports(vrType: string): Observable<StandaloneMileageMoney[] | MileageReportSimplified[]> {
    return this.requestMileageReports(vrType).pipe(
      map((res: MileageReportsResponce | MileageReportsArchiveResponce) => {
        if ((res as MileageReportsResponce       ).standalone_mileage_reports) return (res as MileageReportsResponce).standalone_mileage_reports?.map(item => new StandaloneMileageMoney(item)) || [];
        if ((res as MileageReportsArchiveResponce).mileage_reports)            return (res as MileageReportsArchiveResponce).mileage_reports?.map(item => new MileageReportSimplified(item))    || [];
        return [];  
      })
    );
  }

  getMileageReportsArchive(): Observable<MileageReportSimplified[]> {
    return this.requestMileageReports('archive').pipe(
      map((res: MileageReportsArchiveResponce) => res?.mileage_reports?.map(item => new MileageReportSimplified(item)) || [])
    );
  }

  private requestMileageReports(vrType: string): Observable<any> {
    return this.http.get<MileageReportsResponce | MileageReportsArchiveResponce>(this.getMileageReportsUrl(vrType))
    .pipe(
      tap((res: MileageReportsResponce | MileageReportsArchiveResponce) => {
        this.sessionStorageService.changeTotalCount(res?.meta?.paging?.total_count || 0);
        this.sessionStorageService.changeTotalPages(res?.meta?.paging?.total_pages || 0);
      })
    );
  }

  private getMileageReportsUrl(mmType: string): string {
    if      (mmType === 'dashboard') return this.MM_DASHBOARD_API + this.queryCollectorService.getMileageReportsQuery();
    else if (mmType === 'archive')   return this.MM_ARCHIVE_API   + this.queryCollectorService.getMileageReportsArchiveQuery();
  }

}
