// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .companies-table .table-header-wrapper .project-cell .table-header-cell-title {
  word-break: break-word;
  text-align: center;
}
::ng-deep .companies-table .name-cell {
  order: 1;
  min-width: 100px;
  flex: 1 1 150px;
}
::ng-deep .companies-table .company-num-cell {
  order: 2;
  min-width: 100px;
  flex: 0 0 100px;
}
@media (max-width: 1000px) {
  ::ng-deep .companies-table .company-num-cell {
    display: none;
  }
}
::ng-deep .companies-table .address-cell {
  order: 3;
  min-width: 100px;
  flex: 1 1 150px;
}
::ng-deep .companies-table .date-cell {
  order: 4;
  min-width: 80px;
  flex: 0 0 80px;
}
::ng-deep .companies-table .pdf-cell {
  order: 5;
  min-width: 210px;
  flex: 0 0 210px;
}
::ng-deep .companies-table .project-cell {
  order: 6;
  min-width: 115px;
  flex: 0 0 115px;
}
::ng-deep .companies-table .action-cell {
  order: 7;
  min-width: 85px;
  flex: 0 1 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
