import { Injectable } from '@angular/core';
import { Router     } from '@angular/router';
import { Observable } from 'rxjs';

import {
  ActivityReportsService,
  PhotoDocumentService,
  VacationRequestListService,
  SessionStorageService,
  MileageMoneyListService,
  NotificationService
} from '@shared/services';

import {
  ActivityReportSimplified,
  MileageReportSimplified,
  PhotoDocumentSimplified,
  StandaloneMileageMoney,
  VacationRequest
} from '@shared/factories';
import { FastNavigationModel } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class FastNavigationService {
  constructor(
    private router:                     Router,
    private activityReportsService:     ActivityReportsService,
    private photoDocumentService:       PhotoDocumentService,
    private vacationRequestListService: VacationRequestListService,
    private mileageMoneyListService:    MileageMoneyListService,
    private sessionStorageService:      SessionStorageService,
    private notificationService:        NotificationService
  ) { }

  loadPreloadedData(): Observable<ActivityReportSimplified[] | PhotoDocumentSimplified[] | VacationRequest[] | StandaloneMileageMoney[] | MileageReportSimplified[]> {
    switch (this.getUseCase()) {
      case 'wp-details':
        return this.activityReportsService.getWorkingPeriods(this.getFromLocation());
      case 'pd-details':
        return this.photoDocumentService.getPhotoDocuments(this.getFromLocation());
      case 'vr-details':
        return this.vacationRequestListService.getVacationRequests(this.getFromLocation());
      case 'mm-details':
        return this.mileageMoneyListService.getMileageReports(this.getFromLocation());
      default:
        break;
    }
  }

  goNext(entry: FastNavigationModel): void {
    this.notificationService.wait();
    let path: any = {
      route: [`/time-tracking/${this.getUseCaseUrl(entry)}/${entry.id}`],
      query: { state: { data: window.history.state.data } }
    };
    this.router.navigate(path.route, path.query);
    if (entry.page) this.sessionStorageService.changePageNumber(entry.page);
  }

  private getUseCaseUrl(entry): string {
    let useCase = this.getUseCase();
    if (useCase === 'mm-details') {
      if (entry.wp_id) return `wp-details/${entry.wp_id}/wp-km`;
      else             return 'mm-details';
    }
    return useCase;
  }

  private getUseCase(): string {
    if (this.router.url.includes('/wp-km/'))      return 'mm-details';
    if (this.router.url.includes('/wp-details/')) return 'wp-details';
    if (this.router.url.includes('/pd-details/')) return 'pd-details';
    if (this.router.url.includes('/vr-details/')) return 'vr-details';
    if (this.router.url.includes('/mm-details/')) return 'mm-details';
  }

  private getFromLocation(): string {
    let fromRoute = (this.sessionStorageService.redirectUrl || '').replace('/time-tracking/', '').split('?')[0];
    switch (fromRoute) {
      case 'search-archiv':
        return 'archive';
      case 'failed-erp':
        return 'failed-erp';
      case 'list':
      case 'pd-list':
      case 'vr-list':
      case 'mm-list':
      default:
        return 'dashboard';
    }
  }

}
