import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges, HostListener } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { SortingOptionModel, SortOption, TableHeaderEntryModel } from '@shared/models';
import { SessionStorageService, NotificationService } from '@shared/services';

@Component({
  selector: 'table-entries-header',
  templateUrl: 'table-entries-header.component.html',
  styleUrls: ['./table-entries-header.component.sass'],
})
export class TableEntriesHeaderComponent implements OnInit, OnDestroy {
  @Input() tabs: any[];
  @Output() headerCallback = new EventEmitter<any>();
  @Output() headerOptionalCallback = new EventEmitter<any>();
  subscriptions: SubscriptionLike[] = [];

  tableTabs: any[];

  sortingFlow: SortingOptionModel[];
  sortSelectorOpen: boolean;

  key:     string[];
  reverse: boolean[];

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.sortSelectorOpen && !event.target.closest('.sort-selector')) this.sortSelectorOpen = false;
  }

  constructor(
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.sessionStorageService.sortingFlow.subscribe((items) => {
      if (items) {
        this.sortingFlow = items;

        this.key     = this.sortingFlow[this.sortingFlow.length - 1].columns;
        this.reverse = this.sortingFlow[this.sortingFlow.length - 1].order;

        this.key.forEach((k, index) => { this[k+'_reverse'] = this.reverse[index]; });
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabs.firstChange || this.headerUpdated(changes)) this.tableTabs = changes.tabs.currentValue;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private headerUpdated(changes: SimpleChanges): boolean {
    return JSON.stringify(changes.tabs.currentValue.map(t => t.title)) !== JSON.stringify(changes.tabs.previousValue.map(t => t.title));
  }

  sort(keys: string[]): void {
    if (this.key[0] === keys[0]) keys.forEach(k => { this[k+'_reverse'] = !this[k+'_reverse']; });
    else this.key = keys;
    this.reverse = keys.map(k => this[k+'_reverse']);
  
    this.notificationService.wait();
    this.sessionStorageService.changeSortingFlow([...this.sortingFlow, {columns: keys, order: this.reverse} ]);
    this.headerCallback.emit();
  }

  headerOptionalCallbackHandler(event, cell, ...indexes) {
    this.headerOptionalCallback.emit([event, indexes]);
    cell.input.active = !cell.input.active;
  }

  getActiveSort(list: SortOption[]): SortOption {
    return list.find(l => l.active);
  }

  getSortSelectorHeight(list: SortOption[]): number {
    return this.sortSelectorOpen ? list.length * 25 - 5 + 10 + 10 + 2 : 0;
  }

  getSortOptions(sortOptions: SortOption[]): SortOption[] {
    return sortOptions.filter(s => !s.skip);
  }

  selectSortOption(cell: TableHeaderEntryModel, sub: SortOption): void {
    this.getActiveSort(cell.sortOptions).active = false;
    sub.active = true;
    if (sub.sort_by) this.sort(sub.sort_by);
  }

  reverseSortOptionDirection(list: SortOption[]): void {
    this.sort(this.getActiveSort(list).sort_by);
  }
}
