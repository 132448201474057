import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SidebarRouteExtended  } from '@shared/models';
import { NotificationService, SessionStorageService, SidebarStorageService } from '@shared/services';

@Component({
  selector:      'counters-cards',
  templateUrl: './counters-cards.component.html',
  styleUrls:  ['./counters-cards.component.sass']
})
export class CountersCardsComponent implements OnInit {
  countersCards:                 SidebarRouteExtended[];

  approvedReportsCount:              number;
  openActivityReportsCount:          number;
  clarificationReportsCount:         number;
  failedExportActivityReportsCount:  number;
  expiringSoonAssignmentsCount:      number;
  activePhotoDocumentsCount:         number;
  unconfirmedEBSCount:               number;
  approvedVacationRequestsCount:     number;
  awaitingVacationRequestsCount:     number;
  rejectedVacationRequestsCount:     number;
  failedExportVacationRequestsCount: number;
  awaitingMileageMoneyReportsCount:  number;

  @Input() activeTab: SidebarRouteExtended;
  @Output() cardCallback = new EventEmitter<any>();
  constructor(
    private router:                Router,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService,
    private sidebarStorageService: SidebarStorageService
  ) { }

  ngOnInit(): void {
    this.countersCards = this.sidebarStorageService.counters;

    this.sidebarStorageService.approvedReportsCount.subscribe(             items => this.approvedReportsCount              = items);
    this.sidebarStorageService.openActivityReportsCount.subscribe(         items => this.openActivityReportsCount          = items);
    this.sidebarStorageService.clarificationReportsCount.subscribe(        items => this.clarificationReportsCount         = items);
    this.sidebarStorageService.failedExportActivityReportsCount.subscribe( items => this.failedExportActivityReportsCount  = items);
    this.sidebarStorageService.activePhotoDocumentsCount.subscribe(        items => this.activePhotoDocumentsCount         = items);
    this.sidebarStorageService.unconfirmedEBSCount.subscribe(              items => this.unconfirmedEBSCount               = items);
    this.sidebarStorageService.expiringSoonAssignmentsCount.subscribe(     items => this.expiringSoonAssignmentsCount      = items);
    this.sidebarStorageService.approvedVacationRequestsCount.subscribe(    items => this.approvedVacationRequestsCount     = items);
    this.sidebarStorageService.awaitingVacationRequestsCount.subscribe(    items => this.awaitingVacationRequestsCount     = items);
    this.sidebarStorageService.rejectedVacationRequestsCount.subscribe(    items => this.rejectedVacationRequestsCount     = items);
    this.sidebarStorageService.failedExportVacationRequestsCount.subscribe(items => this.failedExportVacationRequestsCount = items);
    this.sidebarStorageService.awaitingMileageMoneyReportsCount.subscribe( items => this.awaitingMileageMoneyReportsCount  = items);
  }

  mapCardCount(counter: string): number | string {
    switch (counter) {
      case 'approved':
        return this.approvedReportsCount;
      case 'open':
        return this.openActivityReportsCount;
      case 'rejected':
        return this.clarificationReportsCount;
      case 'failed_export':
        return this.failedExportActivityReportsCount || this.failedExportVacationRequestsCount ? `${+this.failedExportActivityReportsCount}+${+this.failedExportVacationRequestsCount}` : null;
      case 'ebs':
        return this.expiringSoonAssignmentsCount || this.unconfirmedEBSCount || 0;
      case 'photo':
        return this.activePhotoDocumentsCount;
      case 'vr-awaiting':
      case 'vr-approved':
        return this.approvedVacationRequestsCount+this.awaitingVacationRequestsCount+this.rejectedVacationRequestsCount;
      case 'mm-open':
        return this.awaitingMileageMoneyReportsCount;
    };
  }

  changeDashboard(card: SidebarRouteExtended): void {
    this.notificationService.wait();
    this.sessionStorageService.changeAndResetActiveTab(card.identifier);
    if (card.filter) this.cardCallback.emit();
    else this.router.navigate([`/time-tracking/${card.path}`]);
  }

}
