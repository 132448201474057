// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 450px) {
  .dashboard-cards-wrapper {
    display: none;
  }
}
.dashboard-cards-wrapper .dashboard-card {
  display: flex;
  flex: 1 0 300px;
  height: 70px;
  border-radius: 6px;
  color: #FFFFFF;
  overflow: hidden;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .dashboard-cards-wrapper .dashboard-card {
    flex: 1 0 275px;
    height: 60px;
  }
}
@media (max-width: 768px) {
  .dashboard-cards-wrapper .dashboard-card {
    flex: 1 0 400px;
    border-radius: 4px;
  }
}
.dashboard-cards-wrapper .dashboard-card:hover {
  opacity: 0.75;
}
.dashboard-cards-wrapper .dashboard-card .card-icon {
  width: 70px;
  text-align: center;
  font-size: 33px;
}
@media (max-width: 1280px) {
  .dashboard-cards-wrapper .dashboard-card .card-icon {
    width: 60px;
  }
}
@media (max-width: 768px) {
  .dashboard-cards-wrapper .dashboard-card .card-icon {
    font-size: 28px;
  }
}
.dashboard-cards-wrapper .dashboard-card .card-text {
  padding: 0 20px;
  flex: 1;
}
@media (max-width: 1280px) {
  .dashboard-cards-wrapper .dashboard-card .card-text {
    padding: 0 10px;
  }
}
.dashboard-cards-wrapper .dashboard-card .card-text .number {
  font-size: 24px;
}
@media (max-width: 1280px) {
  .dashboard-cards-wrapper .dashboard-card .card-text .number {
    font-size: 20px;
  }
}
.dashboard-cards-wrapper .dashboard-card.red .card-icon {
  background-color: #B73809;
}
.dashboard-cards-wrapper .dashboard-card.red .card-text {
  background-color: #DE440B;
}
.dashboard-cards-wrapper .dashboard-card.purple .card-icon {
  background-color: #534F7B;
}
.dashboard-cards-wrapper .dashboard-card.purple .card-text {
  background-color: #6D69A0;
}
.dashboard-cards-wrapper .dashboard-card.orange .card-icon {
  background-color: #EA821C;
}
.dashboard-cards-wrapper .dashboard-card.orange .card-text {
  background-color: #FF9228;
}
.dashboard-cards-wrapper .dashboard-card.green .card-icon {
  background-color: #7DA21A;
}
.dashboard-cards-wrapper .dashboard-card.green .card-text {
  background-color: #95C11F;
}
.dashboard-cards-wrapper .dashboard-card.blue .card-icon {
  background-color: #1b729e;
}
.dashboard-cards-wrapper .dashboard-card.blue .card-text {
  background-color: #3797bb;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
