import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { NotificationService, SessionStorageService, ToasterService } from '@shared/services';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'pagination-search',
  templateUrl: 'pagination-search.component.html',
  styleUrls: ['./pagination-search.component.sass'],
})
export class PaginationSearchComponent implements OnInit {
  @Input() tooltipItems: string[];
  @Output() callback = new EventEmitter<any>();
  subscriptions: SubscriptionLike[] = [];
  searchValue: string;
  searchTimer: ReturnType<typeof setTimeout>;
  constructor(
    private notificationService: NotificationService,
    private sessionStorageService: SessionStorageService,
    public toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.sessionStorageService.searchValue.subscribe((query) => this.searchValue = query));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  changeSearchValue(query: string): void {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => this.updateWithSearch(query), 1000);
  }

  private updateWithSearch(query: string): void {
    this.notificationService.wait();
    this.sessionStorageService.changeSearchValue(query.trim());
    this.sessionStorageService.changePageNumber(1);
    this.callback.emit();
  }

  clearSearch(): void {
    this.searchValue = '';
    this.updateWithSearch(this.searchValue);
  }

}
