export class Weeks {
  start:Date;
  end:Date;
  startOfCurrentWeek: Date;

  constructor (startDate:Date|number, endDate:Date|number) {
    this.startOfCurrentWeek = Weeks.getStartOfWeek();
    this.start = Weeks.getStartOfWeek(startDate);
    this.end = Weeks.getEndOfWeek(endDate);
  }

  *[Symbol.iterator] (): IterableIterator<Date> {
    let current = +this.start;
    let end = +this.end;

    while (current <= end) {
      yield new Date(current);
      current += Weeks.getWeeksSize(current);
    }
  }

  getStartWeekByDate (date:Date) {
    for (let sWeek of this) {
      if (date >= sWeek && Number(date) <= +sWeek+Weeks.getWeeksSize(sWeek)) {
        return sWeek;
      }
    }

    return null;
  }

  static initByRelativeRange (start:number, end:number) {
    let currenr = this.getStartOfWeek();
    return new this(
      new Date(+currenr + this.getWeeksSize(currenr,start)),
      new Date(+currenr + this.getWeeksSize(currenr,end))
    )
  }

  static getStartOfWeek (date:Date|number = new Date) {
    date = new Date(date);
    date.setHours(0,0,0,0);
    let day = (date.getDay() + 6)%7;
    return new Date(Number(date) - day * 24*60*60*1000);
  }

  static getEndOfWeek (date:Date|number = new Date) {
    let start = this.getStartOfWeek(date);
    return new Date(+start + this.getWeeksSize(start) - 1);
  }

  static getWeeksSize(date:Date|number, nWeeks=1) {
    let d = new Date(date);
    d.setDate(d.getDate() + nWeeks * 7);
    return Number(d) - Number(date);
  }

  static getWeekNumber(d: Date): number {
    d = new Date(+d);
    d.setHours(0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    var yearStart = new Date(d.getFullYear(), 0, 1);
    var weekNo = Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);
    return weekNo;
  }

  static getWeekStartByWeekNumber(weekNum: number): Date {
    weekNum = isNaN(weekNum) || weekNum > 53 || weekNum < 1 ? 1 : weekNum;
    let year = new Date().getFullYear();
    let weekStart = this.getWeekStartByWeekYear(weekNum, year);
    if (weekStart > new Date()) weekStart = this.getWeekStartByWeekYear(weekNum, year - 1);
    return weekStart;
  }

  static getWeekStartByWeekYear(weekNum: number, year: number): Date  {
    let weekStart = new Date(year, 0, 1 + (weekNum - 1) * 7);
    let dow = weekStart.getDay();
    if (dow <= 4) weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);
    else weekStart.setDate(weekStart.getDate() + 8 - weekStart.getDay());
    return weekStart;
  }

}
