import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EBS } from '@shared/factories';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EBSService {
  private get TN_ASSIGNMENTS_API(): string { return `${environment.apiUrl}api/portal/v3/assignments` };
  private get TN_EBS_API():         string { return `${environment.apiUrl}api/portal/v3/ebs_data`    };
  constructor(private http: HttpClient) { }

  getEBSDetails(assignmentId: number, ebsId: number): Observable<any> {
    return this.http.get<any>(`${this.TN_ASSIGNMENTS_API}/${assignmentId}/ebs_data/${ebsId}`).pipe(
      map((res) => res.ebs_data),
      map(res => new EBS(res))
    );
  }

  archiveEBS(ebsId: number): Observable<any> {
    return this.manipulateEBS(ebsId, 'archive');
  }

  unArchiveEBS(ebsId: number): Observable<any> {
    return this.manipulateEBS(ebsId, 'unarchive');
  }

  private manipulateEBS(ebsId: number, action: string): Observable<any> {
    return this.http.put<any>(`${this.TN_EBS_API}/${ebsId}/${action}`, {});
  }

}
