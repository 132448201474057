import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'entries-nested-child',
  templateUrl: 'entries-nested-child.component.html'
})
export class EntriesNestedChildComponent {
  @Input() child:   any;
  @Input() indexes: any;
  @Output() callback = new EventEmitter<any>();
  entry: any;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.child   && (changes.child.firstChange   || JSON.stringify(changes.child.currentValue)   !== JSON.stringify(changes.child.previousValue)))   this.entry = changes.child.currentValue;
    if (changes.indexes && (changes.indexes.firstChange || JSON.stringify(changes.indexes.currentValue) !== JSON.stringify(changes.indexes.previousValue))) this.indexes = changes.indexes.currentValue;
  }

  callbackHandler(event, index) {
    if (this.entry.children && this.entry.children.length && this.entry.children[index] && this.entry.children[index].click) event.stopPropagation();
    let temp = Array.isArray(event) ? event : [event, [...this.indexes, index]];
    this.callback.emit(temp);
  }

  mergeArray(...args): any[] {
    return args.reduce((array, toArray) => array.concat(Array.isArray(toArray) ? this.mergeArray(toArray) : toArray ));
  }

}