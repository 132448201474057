import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { ContractExtension } from '@shared/factories';

@Injectable()
export class ContractExtensionsService {
  private get TN_ASSIGNMENTS_API():         string { return `${environment.apiUrl}api/portal/v3/assignments`         };
  private get TN_CONTRACT_EXTENSIONS_API(): string { return `${environment.apiUrl}api/portal/v3/contract_extensions` };
  constructor (private http: HttpClient) { }

  loadContractExtensionsHistory(assignmentId: number): Observable<ContractExtension[]>{
    return this.http.get<any>(`${this.TN_ASSIGNMENTS_API}/${assignmentId}/contract_extensions`).pipe(
      map(res => res.contract_extensions),
      map(res => res.map(c => new ContractExtension(c)))
    );
  }

  requestContractExtention(data): Observable<any> {
    return this.http.post<any>(this.TN_CONTRACT_EXTENSIONS_API, data);
  }

}
