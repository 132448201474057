import { HolidayModel } from "@shared/models";

export class Holiday {
  name: string;
  date: Date;
  country_iso: string;
  state_iso: string;
  constructor(data: HolidayModel) {
    this.name = data.name;
    this.date = this.parceDate(data.date);
    this.country_iso = data.country_iso;
    this.state_iso = data.state_iso;
  }

  toJSON(): HolidayModel{
    return ({
      country_iso: this.country_iso,
      state_iso: this.state_iso,
      date: this.parceDateISO(this.date),
      name: this.name
    });
  }

  private parceDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  private parceDateISO(date): string {
    return date && date instanceof Date ? date.toISOString() : null;
  }

}
