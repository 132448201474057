// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .invoices-table .doc-type-cell {
  order: 1;
  min-width: 100px;
  flex: 0 1 150px;
}
::ng-deep .invoices-table .subject-cell {
  order: 2;
  min-width: 50px;
  flex: 1 1 250px;
}
::ng-deep .invoices-table .invoice-date-cell {
  order: 3;
  min-width: 125px;
  flex: 0 0 125px;
}
::ng-deep .invoices-table .archived-at-cell {
  order: 4;
  min-width: 75px;
  flex: 0 1 100px;
}
::ng-deep .invoices-table .archived-by-cell {
  order: 5;
  min-width: 75px;
  flex: 0 1 100px;
}
::ng-deep .invoices-table .action-cell {
  order: 6;
  min-width: 180px;
  flex: 0 1 180px;
}
::ng-deep .invoices-table .action-cell .tooltip-hover:hover {
  opacity: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
