import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, take, tap } from 'rxjs';

import { ExternalEmployeesService, NotificationService, SessionStorageService } from '@shared/services';
import { ExternalsResponse        } from '@shared/models';
import { ExternalEmployeeExtended } from '@shared/factories';


@Component({
  selector: 'externals-select-list',
  template: ''
})
export class ExternalsSelectListComponent implements OnInit {
  @Input() params:          any;
  @Input() currentExternal: ExternalEmployeeExtended;

  @Input() externals:       ExternalEmployeeExtended[];
  @Input() entries:         string[];
  @Input() active:          string;

  @Input() checkbox:        boolean;
  @Input() checkboxValue:   string;

  @Input() page:            number = 1;
  @Input() totalPages:      number;
  @Input() searchValue:     string = '';
  @Input() searchQuery:     boolean;

  @Output() itemCallback = new EventEmitter<any>();
  private readonly COMPONENT_NAME: string = 'ExternalsSelectList';
  constructor(
    private notificationService:      NotificationService,
    private externalEmployeesService: ExternalEmployeesService,
    private sessionStorageService:    SessionStorageService
  ) { }

  ngOnInit(): void {
    this.requestExternals().subscribe(() => {
      this.openSelectList();
      this.notificationService.close()
    });
  }

  private openSelectList(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'SelectListPopup',
      props: {
        ...this.collectProperties(),
        itemCallback: (value: string) => this.itemCallbackHandler(value),
        paginationCallback: (res: any) => this.paginationCallback(res)
      }
    });
  }

  itemCallbackHandler(value: string): void {
    if (this.checkbox) this.itemCallback.emit(value);
    else {
      let external = this.externals.find(e => this.getLabelValue(e) === value);
      this.itemCallback.emit(external);
    }
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

  private requestExternals(page: number = 1, search: string = ''): Observable<ExternalsResponse> {
    this.notificationService.wait();
    return this.externalEmployeesService.loadExternalsPaginated(page, search, this.params).pipe(
      take(1),
      tap(({ externals, totalPages }) => {
        this.externals  = externals;
        this.totalPages = totalPages;

        this.entries    = this.collectLabels();
        if (this.checkbox) this.active = this.checkboxValue;
        else this.active = this.getLabelValue(this.currentExternal);
      })
    );
  }

  private collectProperties() {
    return {
      entries:     this.entries,
      popupClass: 'externals-select-popup',
      active:      this.active,
      page:        this.page,
      totalPages:  this.totalPages,
      searchValue: this.searchValue,
      searchQuery: this.searchQuery,
      checkbox:    this.checkbox
    };
  }

  private collectLabels(): any[] {
    if (this.checkbox) return this.collectCheckboxProperties(this.externals);
    else               return this.externals.map(e => this.getLabelValue(e));
  }

  private collectCheckboxProperties(values: ExternalEmployeeExtended[]): any[] {
    let ids = this.checkboxValue?.split(';').map(d => +d.split('_')[0]) || [];
    return values.map(e => ({
      id:     e.id,
      label:  this.getLabelValue(e),
      active: ids.find(id => id === e.id)
    }));
  }

  private getLabelValue(e: ExternalEmployeeExtended): string {
    return e ? `${e.name} (${e.personal_number})` : '';
  }

  paginationCallback({ page, search, searchQuery, active }): void {
    this.requestExternals(page, search).pipe(
      tap(() => {
        this.page        = page;
        this.searchValue = search;
        this.searchQuery = searchQuery;
        this.active      = active;
      })
    ).subscribe(() => {
      this.sessionStorageService.updateDynamicComponentValues({ component: 'SelectListPopup', props: this.collectProperties() });
      this.notificationService.close()});
  }

}
