import { Component, Input, EventEmitter, Output } from '@angular/core';
import { formatDate } from '@angular/common';

import { TableHeaderEntryModel, TableBodyEntryModel } from '@shared/models';
import { Release } from '@shared/factories';

@Component({
  selector:     'releases-table',
  templateUrl:  '../../table-entries.component.html',
  styleUrls: ['./releases-table.component.sass'],
})
export class ReleasesTableComponent {
  @Input() rows:         Release[];
  @Input() paginationId: string;

  @Output() headerCallback = new EventEmitter<void>();
  constructor( ) { }

  headerCallbackHandler(res): void {
    this.headerCallback.emit(res);
  }

  getTableClass(): string {
    return 'releases-table';
  }

  getOptionalValue(field: string): boolean {
    return this[field] || null;
  }

  prepareTableHeader(): TableHeaderEntryModel[] {
    return [
      { title: 'Einsatz',     class: 'title-cell',       sort_by: ['title']       },
      { title: 'Application', class: 'application-cell', sort_by: ['application'] },
      { title: 'Version',     class: 'version-cell',     sort_by: ['version']     },
      { title: 'Released at', class: 'released-cell',    sort_by: ['released_at'] },
      { title: 'Notes',       class: 'notes-cell'                                 },
    ];
  }

  prepareTableBody(): TableBodyEntryModel[] {
    return this.rows.map((r: Release): TableBodyEntryModel => ({
      cells: [
        { xs_label: 'Einsatz',     class: 'title-cell',       value: r.title                                                             },
        { xs_label: 'Application', class: 'application-cell', value: r.application                                                       },
        { xs_label: 'Version',     class: 'version-cell',     value: r.version                                                           },
        { xs_label: 'Released at', class: 'released-cell',    value: r.released_at ? formatDate(r.released_at, 'dd.MM.yyyy', 'de') : '-' },
        { xs_label: 'Notes',       class: 'notes-cell',       children: r.notes.map(n => ({ value: n }))                                 }
      ]
    }));
  }

}
