import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, tap } from 'rxjs';

import {
  AssignmentService,
  CustomersCompaniesService,
  DocumentTypesService,
  NotificationService,
  SessionStorageService,
  VacationRequestListService
} from '@shared/services';

interface LabelValueType {
  label: string;
  value: string;
}

@Component({
  selector: 'basic-select-list',
  template: ''
})
export class BasicSelectListComponent implements OnInit {
  @Input() useCase:       string;
  @Input() currentValue:  LabelValueType | string;
  @Input() requestProps:  any[] = [];
  @Input() betaFeature:   string;
  @Input() labelField:    string;
  @Input() valueField:    string;
  @Input() checkbox:      boolean;

  @Input() values:        LabelValueType[];
  @Input() entries:       string[];
  @Input() active:        string;

  @Input() page:          number = 1;
  @Input() totalPages:    number = 1;
  @Input() searchValue:   string = '';
  @Input() searchQuery:   boolean;

  @Output() itemCallback = new EventEmitter<any>();
  private readonly COMPONENT_NAME: string = 'BasicSelectList';
  constructor(
    private assignmentService:          AssignmentService,
    private customersCompaniesService:  CustomersCompaniesService,
    private documentTypesService:       DocumentTypesService,
    private vacationRequestListService: VacationRequestListService,
    private notificationService:        NotificationService,
    private sessionStorageService:      SessionStorageService
  ) { }

  ngOnInit(): void {
    this.requestValues().subscribe(() => {
      this.openSelectList();
      this.notificationService.close()
    });
  }

  private openSelectList(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'SelectListPopup',
      props: {
        ...this.collectProperties(),
        itemCallback: (value: string) => this.itemCallbackHandler(value),
        paginationCallback: (res: any) => this.paginationCallback(res),
        closeCallback: () => this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME)
      }
    });
  }

  itemCallbackHandler(value: string): void {
    if (this.checkbox) this.itemCallback.emit(value);
    else {      
      let entry = this.values.find(e => this.getLabelValue(e) === value);
      let temp  = { value:this.getFieldValue(entry), label: value };
      if (!this.currentValue || temp.value !== (this.currentValue as LabelValueType).value) this.itemCallback.emit(temp);
    }
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

  private requestValues(page: number = 1, search: string = ''): Observable<any> {
    this.notificationService.wait();
    return this.useCaseRequest(page, search).pipe(
      tap(res => {
        this.values     = res[this.useCase];
        this.totalPages = res.totalPages || 1;

        this.entries    = this.collectLabels();
        this.active     = (this.currentValue as LabelValueType)?.label ? (this.currentValue as LabelValueType).label : this.getLabelValue(this.currentValue);
      })
    );
  }

  private useCaseRequest(page: number = 1, search: string = ''): Observable<any> {
    switch (this.useCase) {
      case 'assignments':
        return this.assignmentService.getAssignmentsPaginated(page, search, this.betaFeature);
      case 'companies':
        return this.customersCompaniesService.requestCustomerCompaniesPaginated(page, search);
      case 'documents':
        return this.documentTypesService.requestDocumentTypesPagination(page, search);
      case 'vacation-types':
        return this.vacationRequestListService.getVacationRequestTypes(search, ...this.requestProps);
      case 'vacation-reasons':
        return this.vacationRequestListService.getVacationRequestReasons(...this.requestProps, search);
      default:
        break;
    }
  }

  private collectProperties() {
    return {
      entries:     this.entries,
      popupClass: `${this.useCase}-select-popup`,
      active:      this.active,
      page:        this.page,
      totalPages:  this.totalPages,
      searchValue: this.searchValue,
      searchQuery: this.searchQuery,
      checkbox:    this.checkbox
    };
  }

  private collectLabels(): any[] {
    if (this.checkbox) return this.collectCheckboxProperties(this.values);
    else               return this.values.map(e => this.getLabelValue(e));
  }

  private collectCheckboxProperties(values: any[]): any[] {
    let ids = (this.currentValue as string)?.split(';').map(d => +d.split('_')[0]) || [];
    return values.map(e => ({
      id:     e.id,
      label:  e[this.labelField],
      active: ids.find(id => id === e.id)
    }));
  }

  private getLabelValue(e: any): string {
    return e ? this.checkbox ? e : this.labelField ? e[this.labelField] : e.label : '';
  }

  private getFieldValue(e: any): any {
    return this.valueField ? e[this.valueField] : e.value;
  }

  paginationCallback({ page, search, searchQuery, active }): void {
    this.requestValues(page, search).pipe(
      tap(res => {
        this.page        = page;
        this.searchValue = search;
        this.searchQuery = searchQuery;
        this.active      = active;        
      })
    ).subscribe(() => {
      this.sessionStorageService.updateDynamicComponentValues({ component: 'SelectListPopup', props: this.collectProperties() });
      this.notificationService.close()
    });
  }

}
