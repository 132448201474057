import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { DYNAMIC_ENVIRONMENT, env } from 'ngx-http-env';
import { IEnvironment } from './environments/ienvironment';

env(environment, '/assets/environments/environment.json?ngsw-bypass=true').subscribe({
  next: (env: IEnvironment) => {
    if (env.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      {
        provide: DYNAMIC_ENVIRONMENT,
        useValue: env,
      },
    ])
      .bootstrapModule(AppModule)
      // eslint-disable-next-line no-console
      .catch((error: never) => console.error(error));
  },
  // eslint-disable-next-line no-console
  error: (error: never) => console.error('Failed to load environment configuration', error),
});
