import { InvoicesModel, InvoicesExtendedModel } from "@shared/models";
import { FormatDatesService } from "@shared/services";
import { Employee           } from "@shared/factories";

export class Invoice {
  id:             number;

  doc_type_name:  string;
  company_number: number;

  subject:        string;
  filename:       string;

  customerReadAt: Date;

  invoice_date:   Date;
  archived_at:    Date;
  archived_by:    Employee;
  constructor(data: InvoicesModel) {
    this.id             = data.id;

    this.doc_type_name  = data.doc_type.name;
    this.company_number = data.company_number;

    this.subject        = data.subject;
    this.filename       = data.filename;

    this.customerReadAt = FormatDatesService.parseDate(data.customer_read_at);

    this.invoice_date   = FormatDatesService.parseDate(data.invoice_date);
    this.archived_at    = FormatDatesService.parseDate(data.archived_at);
    this.archived_by    = data.archived_by ? new Employee(data.archived_by) : null;
  }

  toJSON(): InvoicesModel {
    return ({
      id:               this.id, 
      company_number:   this.company_number, 
      subject:          this.subject, 
      filename:         this.filename, 
      customer_read_at: this.customerReadAt ? FormatDatesService.parseDateISO(this.customerReadAt) : null,
      invoice_date:     this.invoice_date   ? FormatDatesService.parseDateISO(this.invoice_date)   : null,
      archived_at:      this.archived_at    ? FormatDatesService.parseDateISO(this.archived_at)    : null,
      archived_by:      this.archived_by    ? this.archived_by.toJSON()                            : null,
      doc_type: { name: this.doc_type_name }
    });
  }

}

export class InvoiceExtended extends Invoice {
  base64pdf: string;
  constructor(data: InvoicesExtendedModel) {
    super(data);
    this.base64pdf = data.base64pdf;
  }
}
