// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .table-header-wrapper {
  display: flex;
  align-items: center;
  min-height: 60px;
  background-color: #F0F0F0;
  border-bottom: 1px solid #CED4DA;
  color: #0099A8;
  margin-top: 10px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}
@media (max-width: 1280px) {
  ::ng-deep .table-header-wrapper {
    padding: 0;
    min-height: 50px;
  }
}
@media (max-width: 768px) {
  ::ng-deep .table-header-wrapper {
    display: none;
  }
}
::ng-deep .table-header-wrapper div[class\$=-cell],
::ng-deep .table-header-wrapper div[class*="-cell "] {
  margin: 0 5px;
  cursor: pointer;
}
::ng-deep .table-header-wrapper div[class\$=-cell]:hover > *:first-child,
::ng-deep .table-header-wrapper div[class*="-cell "]:hover > *:first-child {
  opacity: 0.75;
}
::ng-deep .table-header-wrapper div[class\$=-cell] .table-header-cell-title,
::ng-deep .table-header-wrapper div[class*="-cell "] .table-header-cell-title {
  word-break: break-all;
  white-space: break-spaces;
}
::ng-deep .table-header-wrapper div[class\$=-cell] .checkbox-wrapper,
::ng-deep .table-header-wrapper div[class*="-cell "] .checkbox-wrapper {
  display: flex;
}
::ng-deep .table-header-wrapper:after {
  content: "";
  min-height: inherit;
  font-size: 0;
}
::ng-deep .table-header-wrapper .icon-arrow-down {
  font-size: 10px;
  color: #7D8B92;
}
::ng-deep .table-header-wrapper .icon-arrow-down::before {
  display: inline-block;
}
::ng-deep .table-header-wrapper .icon-arrow-down.rotate-arrow::before {
  transform: rotate(180deg);
}
::ng-deep .table-header-wrapper .icon-arrow-down.active {
  color: #0099A8;
}
::ng-deep .table-header-wrapper .action-cell {
  cursor: default !important;
  text-align: center;
}
::ng-deep .table-header-wrapper .action-cell .table-header-cell-title {
  margin-left: auto;
}
@media (max-width: 1280px) {
  ::ng-deep .table-header-wrapper .action-cell {
    text-align: right;
    padding-right: 15px;
  }
}
::ng-deep .table-header-wrapper .action-cell:hover > *:first-child {
  opacity: 1 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
