import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Weeks      } from '@shared/factories';

@Injectable()
export class FormatDatesService {
  constructor () { }

  formatDateIndustrial(duration: number): string {
    if (duration < 0) return '0.0';
    let hours = (Math.round((duration / 60 / 60 / 1000 + Number.EPSILON) * 100) / 100).toFixed(2);
    return +hours.substr(-1) ? hours : Number(hours).toFixed(1);
  }

  formatDateRealTime(duration: number): number|string {
    if (duration < 0) return '00:00';
    const hours = Math.floor(duration / 60 / 60 / 1000);
    const minutes = Math.round((duration - (hours * 60 * 60 * 1000)) / 60 / 1000);
    return `${!hours ? '00' : hours < 10 ? '0' + hours : hours}:${!minutes ? '00' : minutes < 10 ? '0' + minutes : minutes}`;
  }

  static parseDate(date) {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  static parseDateISO(date): string {
    return date && date instanceof Date ? date.toISOString() : null;
  }

  static parseNumberToString(dateNumber: number): string {
    return this.parseNumberToDateString(dateNumber).replace(':', '');
  }

  static parseNumberToDateString(dateNumber: number): string {
    let fullMins = dateNumber / 1000 / 60;
    let hours = Math.floor(fullMins / 60) ;
    let mins  = fullMins - (hours * 60);
    return `${hours}: ${mins}`;
  }

  static getStringDuration(string: string): number {
    let mins, hours, date = string.split('');
    if (date.length === 4) hours = `${date[0]}${date[1]}`;
    else hours = `${date[0]}`;
    mins = `${date[date.length-2]}${date[date.length-1]}`;
    return this.getTimeStringDuration(`${hours}:${mins}`);
  }

  static getTimeStringDuration(timeString): number {
    let { hours, mins } = this.parseTimeString(timeString);
    return ((hours * 60) + mins) * 60 * 1000;
  }

  static parseTimeString(timeString: string): { hours: number, mins: number } {
    let time  = timeString.split(':');
    let hours = +time[0];
    let mins  = +time[1];

    return { hours, mins };
  }

  static parseTime(date: Date, timeString: string): Date {
    let { hours, mins } = this.parseTimeString(timeString);
    return date && new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, mins, 0);
  }

  static toTimeString(date: Date): string {
    return `${date.getHours()}:${date.getMinutes()}`;
  }

  static parseToPayFormat(val, to?): string {
    if (!val || !isNaN(val)) return this.parseNumberToComaString(+val, to);
    else if (typeof val === 'string') return this.parseNumberToComaString(parseFloat(val.split(",").join(".")), to);
  }

  static parseNumberToComaString(val, to: number = 2): string {
    return val.toFixed(to).replace('.', ',');
  }

  static calendarWeek(date: Date): string {
    return `KW ${Weeks.getWeekNumber(date) > 52 ? '01': Weeks.getWeekNumber(date)}`;
  }

  static period(start: Date, end: Date): string {
    return `${formatDate(start, 'dd.MM.yyyy','de')} - ${formatDate(end, 'dd.MM.yyyy', 'de')}`;
  }

  static isValidDate(date: Date): Date {
    if (!date)                              return null;
    if (date.toString() === 'Invalid Date') return null;
    if (isNaN(date.getTime()))              return null;
    if (date.getFullYear() === 1970)        return null;
    return date;
  }

  static notNull(value: unknown): boolean {
    return value !== undefined && value !== null;
  }

}
