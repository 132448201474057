import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, timer             } from 'rxjs';
import { map, switchMap, takeUntil, tap, repeat } from 'rxjs/operators';

import { CustomerCompany, CustomerCompanyExtended                 } from '@shared/factories';
import { CustomerCompanyResponseModel, MetaModel, MetaPagingModel } from '@shared/models';

import { QueryCollectorService } from '../query-collector.service';
import { SessionStorageService } from '../session-storage.service';

import { environment } from 'environments/environment';

@Injectable()
export class CustomersCompaniesService {
  private start$ = new Subject<void>();
  private stop$  = new Subject<void>();

  private get TN_CUSTOMERS_COMPANIES_API(): string { return `${environment.apiUrl}api/portal/v3/customers_companies` };
  constructor (
    private http:                  HttpClient,
    private sessionStorageService: SessionStorageService,
    private queryCollectorService: QueryCollectorService
  ) { }

  forceReload(): void {
    this.forceStop();
    this.start$.next();
  }

  forceStop(): void {
    this.stop$.next();
  }

  get customerCompanies(): Observable<CustomerCompany[]> {
    return timer(0).pipe(
      switchMap(() => this.requestCustomerCompanies()),
      takeUntil(this.stop$),
      repeat({ delay: () => this.start$ })
    );
  }

  private requestCustomerCompanies(): Observable<CustomerCompany[]>{
    return this.http.get<CustomerCompanyResponseModel>(`${this.TN_CUSTOMERS_COMPANIES_API}${this.queryCollectorService.getCustomerCompanies()}`).pipe(
      tap(res => {
        this.sessionStorageService.changeTotalCount((res.meta as MetaModel).total_count || (res.meta as MetaPagingModel).paging.total_count);
        this.sessionStorageService.changeTotalPages((res.meta as MetaModel).total_pages || (res.meta as MetaPagingModel).paging.total_pages);
      }),
      map(res => res.customers_companies.map(item => new CustomerCompanyExtended(item)))
    );
  }

  requestCustomerCompaniesPaginated(page: number = 1, search: string = ''): any {
    return this.http.get<CustomerCompanyResponseModel>(`${this.TN_CUSTOMERS_COMPANIES_API}${this.queryCollectorService.getCustomerCompaniesPaginated(page, search)}`).pipe(
      map(res => ({
        totalPages: (res.meta as MetaPagingModel).paging.total_pages,
        companies: res.customers_companies.map(c => new CustomerCompany(c))
      }))
    );
  }

  updateCompanyPDFFormat(companyId: number, format): Observable<any> {
    let body = { customer_company: { pdf_time_format: format } };
    return this.updateCompany(companyId, body);
  }

  updateCompanyProject(companyId: number, prefill: number): Observable<any> {
    let body = { customer_company: { prefill_activity_with_project: prefill } };
    return this.updateCompany(companyId, body);
  }

  private updateCompany(companyId: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.TN_CUSTOMERS_COMPANIES_API}/${companyId}`, data).pipe(map(res => res));
  }

}
