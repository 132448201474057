import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { take } from 'rxjs/operators';

import { SessionStorageService, NotificationService, ValidationService, CustomerService } from '@shared/services';
import { CustomerEmailListItem } from '@shared/factories';
import { messages } from '@messages';

@Component({
  selector:      'export-customer-popup',
  templateUrl: './export-customer-popup.component.html',
  styleUrls:  ['./export-customer-popup.component.sass']
})
export class ExportCustomerPopup implements OnInit {
  @Input() assignment_id: number;

  customersEmails: CustomerEmailListItem[] = [];
  customEmail: string;
  templateStage: boolean;
  emailTemplate: string;

  loaded: boolean;
  @Output() clickAccept: EventEmitter<any> = new EventEmitter<any>();
  private readonly COMPONENT_NAME: string = 'ExportCustomerPopup';
  constructor(
    private validationService: ValidationService,
    private customerService: CustomerService,
    private sessionStorageService: SessionStorageService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (this.assignment_id) {
      this.notificationService.wait();
      this.customerService.getCustomersEmeils(this.assignment_id).pipe(take(1)).subscribe(
        res => {
          this.customersEmails = res;
          this.notificationService.close();
        },
        err => this.notificationService.alert(err),
        ()  => this.loaded = true
      );
    } else this.loaded = true;
  }

  addCustomEmail() {
    if (this.customEmail) {
      if (!this.validationService.checkIfEmail(this.customEmail)) this.notificationService.alert(messages.errors.validation.emailNotValid);
      else if (this.customersEmails.find(c => c.email === this.customEmail)) this.notificationService.alert(messages.export_ar.error.emailAlreadyInList);
      else {
        this.customersEmails.push( new CustomerEmailListItem({
          id: null,
          first_name: null,
          last_name: null,
          personal_number: null,
          phone_number: null,
          email: this.customEmail,
          active: true
        }));
        this.customEmail = '';
      }
    }
  }

  selectTemplate(): void {
    let customers = this.customersEmails.filter(e => e.active);
    if (customers) {
      if (customers.length > 5) this.notificationService.alert(messages.export_ar.error.fiveEmailsLimit);
      else this.templateStage = true;
    } else this.notificationService.alert(messages.export_ar.error.noEmailSelected);
  }

  back(): void {
    this.templateStage = false;
  }

  sendEmail(): void {
    if (this.emailTemplate) {
      let customers = this.customersEmails.filter(e => e.active);
      this.clickAccept.emit({customers, email_template: this.emailTemplate});
      this.close();
    } else this.notificationService.alert(messages.export_ar.error.selectEmailSubjectType);
  }

  activeEmails(): CustomerEmailListItem[] {
    return this.customersEmails.filter(e => e.active);
  }

  close(e = null): void {
    if (!e || !e.target.closest('.dynamic-modal-content')) this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }
}
