import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject     } from 'rxjs';
import { tap, repeat, switchMap, map } from 'rxjs/operators';

import { QueryCollectorService } from '../query-collector.service';
import { SidebarStorageService } from '../sidebar-storage.service';
import { UserService           } from '../auth/user.service';
import { CountersService       } from './counters.service';

import { ActivityReportsCountersModel } from '@shared/models';

import { environment } from 'environments/environment';

@Injectable()
export class ActivityReportsCountersService {
  private get TN_COUNTERS_API(): string { return `${environment.apiUrl}api/portal/v3/working_periods_counters` };

  private reloadCounter$ = new Subject<void>();
  private skipNotification: boolean;
  constructor (
    private http:                  HttpClient,
    private countersService:       CountersService,
    private sidebarStorageService: SidebarStorageService,
    private userService:           UserService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  reloadCounters(skipNotification: boolean = false): void {
    this.skipNotification = skipNotification;
    this.reloadCounter$.next();
  }

  get workingPeriodsCounters(): Observable<ActivityReportsCountersModel> {
    return this.requestWorkingPeriodsCounters().pipe(
      repeat({ delay: () => this.reloadCounter$ })
    );
  }

  private requestWorkingPeriodsCounters(): Observable<ActivityReportsCountersModel> {
    return of(this.TN_COUNTERS_API).pipe(
      map(url => {
        let query = this.queryCollectorService.getCountersQuery();
        if (this.userService.isCustomer) query += `${query.length > 1 ? '&' : '' }q[assignments][active]=true`;
        return url + query;
      }),
      switchMap(url => this.http.get<ActivityReportsCountersModel>(url)),
      tap(res => {
        this.sidebarStorageService.changeApprovedReportsCount(res.approved.length);
        this.sidebarStorageService.changeOpenActivityReportsCount(res.open.length);
        this.sidebarStorageService.changeClarificationReportsCount(res.rejected.length);
        if (res.failed_export) this.sidebarStorageService.changeFailedExportActivityReportsCount(res.failed_export?.length);

        this.countersService.checkForUpdates(res, 'activityReports', this.skipNotification);
        this.skipNotification = false;
      })
    );
  }

}
