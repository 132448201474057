import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject, timer      } from 'rxjs';
import { repeat, shareReplay, take, takeUntil, tap } from 'rxjs/operators';

import { AssignmentService               } from './time-tracking/assignment.service';
import { ActivityReportsService          } from './time-tracking/activity-reports.service';
import { PhotoDocumentService            } from './time-tracking/photo-document.service';
import { InvoicesService                 } from './invoices/invoices.service';
import { AssignmentsCountersService      } from './counters/assignments-counters.service';
import { ActivityReportsCountersService  } from './counters/activity-reports-counters.service';
import { PhotoDocumentsCountersService   } from './counters/photo-documents-counters.service';
import { InvoicesCountersService         } from './counters/invoices-counters.service';
import { VacationRequestsCountersService } from './counters/vacation-requests-counters.service';
import { MileageMoneyCountersService     } from './counters/mileage-money-counters.service';
import { SessionStorageService           } from './session-storage.service';
import { UserService                     } from './auth/user.service';

const REFRESH_INTERVAL = 30000;
const CACHE_SIZE       = 1;

@Injectable()
export class AutoReloadService {
  timeTrackingInit: boolean = false;
  invoicesInit:     boolean = false;
  timerFallback:    boolean = false;

  private reloadTimer$ = new Subject<void>();
  private stopTimer$   = new Subject<void>();
  constructor (
    private activityReportsService:          ActivityReportsService,
    private activityReportsCountersService:  ActivityReportsCountersService,
    private assignmentService:               AssignmentService,
    private assignmentsCountersService:      AssignmentsCountersService,
    private photoDocumentService:            PhotoDocumentService,
    private photoDocumentsCountersService:   PhotoDocumentsCountersService,
    private vacationRequestsCountersService: VacationRequestsCountersService,
    private mileageMoneyCountersService:     MileageMoneyCountersService,
    private invoicesService:                 InvoicesService,
    private invoicesCountersService:         InvoicesCountersService,
    private sessionStorageService:           SessionStorageService,
    private userService:                     UserService
  ) { }

  resetAutoReload(appName: string = null): void {
    let app = appName || this.sessionStorageService.activeAppValue || null; 
    this.stopAutoReload(app, true);
    if (app && app === 'time-tracking') this.startTimeTrackkingAutoReload();
    else if (app && app === 'invoices') this.startInvoicesAutoReload();
  }

  fallbackToTimerReload(): Observable<any> {
    this.timerFallback = true;
    return timer(0, REFRESH_INTERVAL).pipe(
      tap(() => {
        let app = this.sessionStorageService.activeAppValue; 
        if (app === 'time-tracking') this.forceTimeTrackingAutoReload();
        else if (app === 'invoices') this.forceInvoicesAutoReload();
      }),
      repeat({ delay: () => this.reloadTimer$ }),
      takeUntil(this.stopTimer$),
      shareReplay(CACHE_SIZE)
    );
  }

  private startTimeTrackkingAutoReload(): void {
    if (!this.timeTrackingInit) {
      this.timeTrackingInit = true;
      let calls: any[] = [
        this.activityReportsCountersService.workingPeriodsCounters,
        this.vacationRequestsCountersService.vacationRequestsCounters
      ];
      if (this.userService.isInternal) calls.push(this.photoDocumentsCountersService.photoDocumentsCounters);
      if (this.userService.isInternal) calls.push(this.assignmentsCountersService.EBSCounter);
      if (this.userService.isCustomer) calls.push(this.assignmentsCountersService.assignmentsCounters)
      if (this.userService.isInternal) calls.push(this.mileageMoneyCountersService.MileageMoneyCounters);
      forkJoin(calls).pipe(take(1)).subscribe();
    } else this.forceAutoReload('time-tracking');
  }

  private startInvoicesAutoReload(): void {
    if (!this.invoicesInit) {
      this.invoicesInit = true;
      let calls: any[] = [this.invoicesCountersService.invoicesCounters];
      forkJoin(calls).pipe(take(1)).subscribe();
    } else this.forceAutoReload('invoices');
  }

  private stopAutoReload(appName: string = null, reset: boolean = null): void {
    let app = appName || this.sessionStorageService.activeAppValue || null; 
    if (app && app !== 'time-tracking') this.stopTimeTrackkingAutoReload();
    if (app && app !== 'invoices'     ) this.stopInvoicesAutoReload(reset);
    this.stopTimer$.next();
  }

  private stopTimeTrackkingAutoReload(): void {
    this.activityReportsService.forceStop();
    if (this.userService.isInternal) this.photoDocumentService.forceStop();
    if (this.userService.isCustomer) this.assignmentService.forceStop();
  }

  private stopInvoicesAutoReload(reset: boolean = null): void {
    this.invoicesService.forceStop(reset);
  }

  forceAutoReload(appName: string = null): void {
    let app = appName || this.sessionStorageService.activeAppValue || null; 
    this.stopAutoReload(app);
    if (app && app === 'time-tracking') this.forceTimeTrackingAutoReload(true);
    else if (app && app === 'invoices') this.forceInvoicesAutoReload();
    if (this.timerFallback) this.reloadTimer$.next();
  }

  private forceTimeTrackingAutoReload(skipDesktopNotification: boolean = null): void {
    this.activityReportsCountersService.reloadCounters(skipDesktopNotification);
    this.vacationRequestsCountersService.reloadCounters();
    if (this.userService.isInternal) this.photoDocumentsCountersService.reloadCounters();
    if (this.userService.isInternal) this.assignmentsCountersService.reloadEBSCounter();
    if (this.userService.isCustomer) this.assignmentsCountersService.reloadAssignmentsExpiredCounter();
    if (this.userService.isInternal) this.mileageMoneyCountersService.reloadCounters();
  }

  private forceInvoicesAutoReload(): void {
    this.invoicesCountersService.reloadCounters();
  }

}
