import { UserService } from '@shared/services';
import { Input, Component, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';

@Component({
  selector: 'basic-table',
  templateUrl: 'basic-table.component.html',
})
export class BasicTableComponent implements AfterViewInit {
  @Input() tabs:             any[];
  @Input() rows:             any[];

  @Input() tableClass:       string;
  @Input() tableHeaderClass: any[];
  @Input() tableBodyClass:   any[];

  @Input() paginationId:     string;

  @Input() isInternal:       boolean;
  @Input() isCustomer:       boolean;
  @Input() skipHeader:       boolean;
  @Output() headerCallback = new EventEmitter<any>();

  sidebarOpen: boolean = false;

  @HostListener('document:click', ['$event'])
  checkSidebarOpenClickHandler(event) {
    if (event.target.closest('.sidebartoggler')) this.checkSidebarOpen();
  }
  constructor(public userService: UserService) { }

  ngAfterViewInit(): void {
    $(window).ready(this.checkSidebarOpen.bind(this));
    $(window).on('resize', this.checkSidebarOpen.bind(this));
    $('body').trigger('resize');
  }

  private checkSidebarOpen(): void {
    setTimeout(() => {
      let sidebar = document.getElementById('portal-sidebar');
      if (sidebar.offsetWidth > 65) this.sidebarOpen = true;
      else this.sidebarOpen = false;
    });
  }

  filterByUserRole(items: any[]): any[] {
    return items.filter(item => item.internalOnly && this.isInternal ||
                                item.customerOnly && this.isCustomer ||
                               !item.internalOnly && !item.customerOnly);
  }

  getFilteredRows(entries: any[]): any[] {
    return this.filterByUserRole(entries).map(e => {
      if (e.cells) e.cells = this.getFilteredRows(e.cells.filter(e => !e.skip));
      if (e.children) e.children = e.children.filter(e => !e.skip);
      if (e.buttons)  e.buttons  = e.buttons.filter(e => !e.skip);
      if (e.children) e.children = this.getFilteredRows(e.children);
      if (e.xs_icons) e.xs_icons = this.getFilteredRows(e.xs_icons);
      return e;
    })
  }

  headerCallbackHandler(res): void {
    this.headerCallback.emit(res);
  }

  headerOptionalCallbackHandler(res): void {
    let event = res[0];
    let body  = this.getClickElementHeader(res[1]);
    if (body.click) body.click(event);
  }

  getClickElementHeader(indexes: number[]) {
    let body: any = [...this.tabs];
    for (let i=0; i < indexes.length; i++) {
      body = body[indexes[i]];
      if (body.input) body = body.input;
    }
    return body;
  }

  callbackHandler(res): void {
    let event = res[0];
    let body  = this.getClickElement(res[1]);
    if (body.click) body.click(event);
  }

  buttonCallbackHandler(res): void {
    let event = res[0];
    let el    = this.getClickElement(res[1]);
    if (el.click) el.click(event);
  }

  getClickElement(indexes: number[]) {
    let body: any = [...this.rows];
    for (let i=0; i < indexes.length; i++) {
      if (body.cells) body = body.cells;
      if (body.children) body = body.children;
      if (body.buttons) body = body.buttons;
      body = body[indexes[i]];
    }
    return body;
  }

}
