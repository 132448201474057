// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 29;
}
#custom-popup .custom-popup-overflow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}
#custom-popup .custom-popup-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-height: 90%;
  max-width: calc(100% - 40px);
  padding: 20px;
  background-color: white;
  border-radius: 6px;
}
@media (max-width: 1280px) {
  #custom-popup .custom-popup-wrapper {
    padding: 15px;
  }
}
@media (max-width: 768px) {
  #custom-popup .custom-popup-wrapper {
    width: calc(100% - 20px);
    padding: 10px;
  }
}
@media (max-width: 450px) {
  #custom-popup .custom-popup-wrapper {
    width: calc(100% - 15px);
    padding: 7.5px;
  }
}
#custom-popup .custom-popup-wrapper .custom-popup-scrollable {
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
}
#custom-popup .popup-btn-group {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
@media (max-width: 1280px) {
  #custom-popup .popup-btn-group {
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  #custom-popup .popup-btn-group {
    margin-top: 15px;
  }
}
@media (max-width: 450px) {
  #custom-popup .popup-btn-group {
    margin-top: 10px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
