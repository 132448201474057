import { Component } from '@angular/core';
import { NotificationService } from "@shared/services";

@Component({
  selector: 'notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['./notification.component.sass'],
})
export class NotificationComponent {
  message: any;
  constructor( private notificationService: NotificationService ) { }
  
  ngOnInit() {
    this.notificationService.getMessage().subscribe(message => {
      this.message = message;
    });
  }

  overflowClick(e): void {
    if (this.message && this.message.overflowClose) this.closePopup();
  }

  closePopup() {
    this.notificationService.close();
  }
}
