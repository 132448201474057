import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserService } from '@shared/services';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
  constructor( private userService: UserService ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!request.url.includes('logstash')) {
      if (this.userService.currentUserValue) request = this.addDefaultHeaders(request)
    }
    return next.handle(request);
  }

  private addDefaultHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let req = request.clone({ setHeaders: {
      'X-AppName': this.userService.isInternal ? 'TemptonApp Internal' : 'myTempton Kundenportal',
      'X-Locale': 'de'
    }});
    if (request.url.includes('/v3/')) req = req.clone({ setHeaders: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    }});
    return req;
  }
}
