export class ValidationError {
  message: string;
  tf_id: number;
  p_id: number;
  constructor(message: string, tf_id: number = null, p_id: number = null) {
    this.message = message;
    this.tf_id = tf_id;
    this.p_id = p_id;
  }
}

export class TechnicalError extends ValidationError { }
export class LegalError     extends ValidationError { }
export class LegalTimeError extends LegalError     { }
export class DailyError     extends TechnicalError { }
export class TimeError      extends TechnicalError { }
export class StartTimeError extends TechnicalError { }
export class EndTimeError   extends TechnicalError { }
