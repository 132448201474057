// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-wrapper {
  display: flex;
  margin: 20px 0 0 0;
  height: 36px;
}
.pagination-wrapper .pagination {
  margin-right: 20px;
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination {
  padding-left: 0 !important;
  margin: 0 !important;
  border: 1px solid #CED4DA !important;
  border-radius: 4px;
  color: #7D8B92 !important;
  display: flex !important;
  align-items: center;
  height: 36px !important;
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li {
  height: 100% !important;
  display: flex !important;
  align-items: center;
  margin: 0 !important;
  font-size: 12px !important;
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li.small-screen {
  display: none !important;
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li:not(:last-child) {
  border-right: 1px solid #CED4DA;
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li::after, .pagination-wrapper .pagination ::ng-deep .ngx-pagination li::before {
  margin: 0 !important;
}
@media (max-width: 601px) {
  .pagination-wrapper .pagination ::ng-deep .ngx-pagination li:not(.pagination-previous):not(.pagination-next):not(.small-screen) {
    display: none !important;
  }
  .pagination-wrapper .pagination ::ng-deep .ngx-pagination li.small-screen {
    display: flex !important;
    width: 36px !important;
    align-items: center;
    justify-content: center;
  }
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li a,
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li span {
  display: flex !important;
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li a::after, .pagination-wrapper .pagination ::ng-deep .ngx-pagination li a::before,
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li span::after,
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li span::before {
  margin: 0 !important;
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination li a {
  color: #7D8B92 !important;
  height: 100%;
  align-items: center;
}
.pagination-wrapper .pagination ::ng-deep .ngx-pagination .current {
  background-color: #0099A8 !important;
  color: #FFFFFF !important;
}
.pagination-wrapper .pagination .pagination-control {
  width: 140px;
  background-color: #FFFFFF;
  color: #7D8B92;
  border-color: #CED4DA;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
