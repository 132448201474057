import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, repeat, switchMap, tap } from 'rxjs/operators';

import { SidebarStorageService } from '../sidebar-storage.service';
import { QueryCollectorService } from '../query-collector.service';
import { CountersService       } from './counters.service';

import { VacationRequestsCountersModel } from '@shared/models';

import { environment } from 'environments/environment';

@Injectable()
export class VacationRequestsCountersService {
  private get VACATION_REQUESTS_COUNTERS_API(): string { return `${environment.apiUrl}api/portal/v3/vacation_requests_counters` };

  private reloadCounter$ = new Subject<void>();
  constructor (
    private http:                  HttpClient,
    private sidebarStorageService: SidebarStorageService,
    private countersService:       CountersService,
    private queryCollectorService: QueryCollectorService,
  ) { }

  reloadCounters(): void {
    this.reloadCounter$.next();
  }

  get vacationRequestsCounters(): Observable<any> {
    return this.requestVacationRequestsCounters().pipe(
      repeat({ delay: () => this.reloadCounter$ })
    );
  }

  private requestVacationRequestsCounters(): Observable<VacationRequestsCountersModel> {
    return of(this.VACATION_REQUESTS_COUNTERS_API).pipe(
      map(url => url + this.queryCollectorService.getCountersQuery('vacation_requests')),
      switchMap(url => this.http.get<VacationRequestsCountersModel>(url)),
      tap(res => {
        this.sidebarStorageService.changeApprovedVacationRequestsCount(res.approved.length);
        this.sidebarStorageService.changeAwaitingVacationRequestsCount(res.open.length);
        this.sidebarStorageService.changeRejectedVacationRequestsCount(res.rejected.length);
        if (res.failed_export) this.sidebarStorageService.changeFailedExportVacationRequestsCount(res.failed_export.length);

        this.countersService.checkForUpdates(res, 'vacationRequests');
      })
    );
  }

}
