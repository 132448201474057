import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router     } from "@angular/router";

import { Observable } from "rxjs";
import { map        } from "rxjs/operators";

import { TechnicalError } from "@shared/models";
import { LoginService   } from "./auth/login.service";
import { UserService    } from "./auth/user.service";
import { ApiErrorLog, Log, ValidationErrorLog } from "app/factories/logs.factory";

const LOGSTASH_PATH  = "https://logstash.my-appsolute-mobility.com:51403";
const LOGSTASH_CREDS = "545ss6NmXLKXGHuGVPLM";

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor(
    private http:         HttpClient,
    private router:       Router,
    private userService:  UserService,
    private loginService: LoginService
  ) { }

  logApiError(err) {
    let log = new ApiErrorLog({
      severity: 'INFO',
      token: this.loginService.accessTokenValue || null,
      user_id: this.userService.currentUserValue && this.userService.currentUserValue.id || null,
      path: err.url,
      response_code: err.status,
      message: err.message || err.error.errors || err.statusText
    });
    this.addLogToCollection(log, 'apiErrors');
  }

  logAlert(alert) {
    let log = new Log({
      severity: 'INFO',
      token: this.loginService.accessTokenValue || null,
      user_id: this.userService.currentUserValue && this.userService.currentUserValue.id || null,
      message: alert,
      additional_data: {
        path: this.router.url
      }
    });
    this.addLogToCollection(log, 'notificationAlerts');
  }

  logValidationError(err, ar_id) {
    let log = new ValidationErrorLog({
      severity: 'INFO',
      token: this.loginService.accessTokenValue || null,
      user_id: this.userService.currentUserValue && this.userService.currentUserValue.id || null,
      message: err.message,
      additional_data: {
        error_type: err instanceof TechnicalError ? 'technical' : 'legal',
        activity_report_id: ar_id,
        time_frame_id: err.tf_id,
        pause_id: err.p_id
      }
    });
    this.addLogToCollection(log, 'validationErrors');
  }

  handleWindowErrors(event) {
    return this.processErrors([event]);
  }
  
  handlePromiseErrors(event) {
    return this.processErrors([{
      message: `Unhandled promise rejection: ${event.reason && event.reason.message || ''}`
    }]);
  }

  private processErrors(a?) {
    var log: any = {
      severity: 'ERROR',
      token: this.loginService.accessTokenValue || null,
      user_id: this.userService.currentUserValue && this.userService.currentUserValue.id || null,
      additional_data: [{}]
    };
    var args = arguments[0];
    for (var i = 0; i < args.length; i++) {
      var arg = args[i];
      if ( arg instanceof Error || Object.prototype.toString.call(arg) === '[object Object]' ) {
        if (!log.additional_data[0].name) log.additional_data[0].name = arg.name ? arg.name : (arg.error && arg.error.name) ? arg.error.name : '';
        if (!log.message) log.message = arg.message ? arg.message : (arg.error && arg.error.message) ? arg.error.message : '';
        if (!log.trace) log.trace = arg.stack ? arg.stack : (arg.error && arg.error.stack) ? arg.error.stack : '';
      } else {
        if (!log.message) log.message = arg;
      }
    }
    if (log.message) {
      if (typeof(log.message) !== 'string') log.message = log.message.toString();
      let logEntry = new Log(log);
      this.addLogToCollection(logEntry, 'consoleErrors');
    }
  }

  private addLogToCollection(log, collection) {
    let local: string = window.localStorage.getItem(collection);
    if (local) {
      let logs = JSON.parse(local);
      logs.push(log);
      if (logs.length > 50) logs.splice(0, logs.length - 50);
      window.localStorage.setItem(collection, JSON.stringify(logs));
    } else window.localStorage.setItem(collection, JSON.stringify([log]));
  }


  sendErrorLogs(collection): Observable<any> {
    let logs = JSON.parse(localStorage.getItem(collection));
    let jsonl = logs.map(l => JSON.stringify(l)).join('\n');
    var currentDate = new Date();
    var month = currentDate.getMonth() + 1;
    var monthString = `${month < 10 ? '0' : ''}${month}`;
    var indexName = `applications_${currentDate.getFullYear()}_${monthString}`;
    let options: Object = {
      headers: {
        'Content-Type': 'application/json',
        'auth_token': LOGSTASH_CREDS
      },
      responseType: 'text'
    };
    // return this.http.post<any>(`${LOGSTASH_PATH}/${indexName}/properties`,logs, { 
    return this.http.post<any>(`${LOGSTASH_PATH}`,jsonl, options).pipe(
      map(res => {
        console.log(res)
        return res;
      })
    );

  }
}
