// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mm-list-wrapper .name-cell {
  order: 1;
  min-width: 85px;
  flex: 0 1 200px;
}
::ng-deep .mm-list-wrapper .pers-num-cell {
  order: 2;
  min-width: 85px;
  flex: 0 1 90px;
}
::ng-deep .mm-list-wrapper .date-cell {
  order: 3;
  flex: 0 0 80px;
}
@media (max-width: 1280px) {
  ::ng-deep .mm-list-wrapper .date-cell {
    flex: 0 0 75px;
  }
}
::ng-deep .mm-list-wrapper .reviewed-cell {
  order: 4;
  flex: 0 0 95px;
}
::ng-deep .mm-list-wrapper .title-cell {
  order: 5;
  min-width: 125px;
  flex: 5 1 225px;
}
::ng-deep .mm-list-wrapper .period-cell {
  order: 6;
  min-width: 90px;
  flex: 0 0 90px;
}
::ng-deep .mm-list-wrapper .type-cell {
  order: 7;
  min-width: 120px;
  flex: 0 0 120px;
}
::ng-deep .mm-list-wrapper .action-cell {
  order: 8;
  min-width: 80px;
  flex: 0 1 100px;
}
@media (max-width: 1000px) {
  ::ng-deep .mm-list-wrapper.sidebar-open .table-header-wrapper {
    display: none;
  }
}
@media (max-width: 1000px) {
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .xs-hide {
    display: none !important;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row {
    min-height: 0;
    padding: 0;
    margin: 10px 0;
    flex-direction: column;
    border: 1px solid #CED4DA;
    border-radius: 4px;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell],
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] {
    display: flex;
    flex-direction: row !important;
    flex: 0;
    width: 100%;
    margin: 0;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell]:not(.icons-cell):not(:last-child), ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell]:not(:last-child),
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "]:not(.icons-cell):not(:last-child),
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "]:not(:last-child) {
    border-bottom: 1px solid #CED4DA;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-label,
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-label {
    display: flex;
    min-height: 40px;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-label + span,
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-label + span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 130px);
    line-height: 20px;
    padding: 10px 0;
    word-break: break-all;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-icon,
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell] .xs-button,
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-icon,
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "] .xs-button {
    display: flex !important;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell].checkbox-cell,
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "].checkbox-cell {
    display: none;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class\$=-cell].action-cell.failed-erp,
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row div[class*="-cell "].action-cell.failed-erp {
    flex: auto;
  }
  ::ng-deep .mm-list-wrapper.sidebar-open .table-body-wrapper .list-row .buttons-group {
    margin: 10px 10px 10px 0;
  }
}

::ng-deep .mm-details-wrapper .list-row.deleted {
  color: #BEBEBE !important;
}
@media (min-width: 769px) {
  ::ng-deep .mm-details-wrapper .list-row.deleted:after {
    content: "";
    border-bottom: 1px solid #BEBEBE;
    position: absolute;
    width: 100%;
    min-height: 0;
    left: 0;
    transform: translate(0, -50%);
    z-index: 1;
  }
}
::ng-deep .mm-details-wrapper .holiday-cell {
  order: 1;
  flex: 0 0 30px;
}
::ng-deep .mm-details-wrapper .day-cell {
  order: 2;
  flex: 1 0 80px;
}
::ng-deep .mm-details-wrapper .from-cell {
  min-width: 125px;
  order: 3;
  flex: 2 1 200px;
}
::ng-deep .mm-details-wrapper .to-cell {
  min-width: 160px;
  order: 4;
  flex: 2 1 200px;
}
::ng-deep .mm-details-wrapper .total-km-cell {
  min-width: 60px;
  order: 5;
  flex: 1 1 100px;
}
::ng-deep .mm-details-wrapper .action-cell {
  order: 6;
  min-width: 60px;
  flex: 0 0 60px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
