// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  justify-content: center;
  align-items: center;
}
#modal-container.show {
  display: flex;
}
#modal-container.hidden {
  display: none;
}

#overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
#overlay.show {
  display: block;
}
#overlay.hidden {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
